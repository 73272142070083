import TextareaAutosize from "react-textarea-autosize";
import { useCallback, useContext, useEffect, useState } from "react";
import "../../assets/css/createmodule.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
    baseUrl,
    getTableName,
    toastError,
    toastInfo,
    toastPromise,
    toastSuccess,
    uploadFileToBackblaze,
} from "../../GlobalFunctions";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../App";
import imageCompression from "browser-image-compression";
import { FaTrash } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabase";
import Popup from "reactjs-popup";

export default function CreateModule({ sidetabvisible }) {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [module, setModule] = useState({
        slides: [
            {
                title: "",
                content: [],
            },
        ],
    });
    const [deletingSlide, setDeletingSlide] = useState(false);

    useEffect(() => {
        const saved_data = localStorage.getItem("module");
        if (saved_data) {
            setModule(JSON.parse(saved_data));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("module", JSON.stringify(module));
    }, [module]);

    const [currentslide, setCurrentSlide] = useState(0);

    const onDragEnd = useCallback(
        async (result) => {
            // console.log("result:", result);
            // the only one that is required
            const { destination, source } = result;
            // if there is no destination, return
            if (!destination) {
                return;
            }
            // if the destination is the same as the source, return
            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            if (destination.droppableId === "trash") {
                const updatedSlides = [...module.slides];
                const updatedSlide = { ...updatedSlides[currentslide] };
                const updatedContent = [...updatedSlide.content];
                // if any images are in the content, delete them from the backend
                if (
                    updatedContent[source.index].type === "image" &&
                    updatedContent[source.index].content?.includes("https")
                ) {
                    const fileName = updatedContent[source.index].content.split("acewisefiles/")[1];
                    const listData = await fetch(`${baseUrl}/listfilenames?fileName=${fileName}`);
                    if (listData.ok) {
                        let listDataJson = await listData.json();
                        // console.log("listDataJson:", listDataJson);
                        const deleteResponse = await fetch(`${baseUrl}/deletefile`, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                fileName: fileName,
                                fileId: listDataJson.files[0].fileId,
                            }),
                        });

                        if (!deleteResponse.ok) {
                            console.error("Failed to delete file:", deleteResponse);
                            throw new Error("Failed to delete file");
                        }
                    } else {
                        console.error("Failed to get file id:", listData.status);
                        toastError("Error deleting file, please try again later");
                    }
                }
                updatedContent.splice(source.index, 1);
                updatedSlides[currentslide] = { ...updatedSlide, content: updatedContent };
                setModule({ ...module, slides: updatedSlides });
            } else {
                const updatedSlides = [...module.slides];
                const updatedSlide = { ...updatedSlides[currentslide] };
                const content = Array.from(updatedSlide.content);

                // remove the item from the source index
                const [reorderedItem] = content.splice(source.index, 1);
                // insert the item into the destination index
                content.splice(destination.index, 0, reorderedItem);

                // update the slide's content with the new content order
                updatedSlide.content = content;
                updatedSlides[currentslide] = updatedSlide;

                // update the module with the new slides
                setModule({ ...module, slides: updatedSlides });
            }
        },
        [currentslide, module]
    );

    const getListStyle = () => ({
        marginBlock: "0.5rem",
    });

    const grid = 8;

    const getItemStyle = (draggableStyle, type) => {
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",
            margin: type === "image" ? "0 auto 8px auto" : `0 0 ${grid}px 0`,
            borderRadius: "3px",
            backgroundColor: type === "text" ? "none" : "white",
            width: type === "image" ? "35vw" : "100%",
            ...draggableStyle,
        };
    };

    // useEffect(() => {
    //     console.log("module now:", module);
    // }, [module]);

    /**
     * Updates the slides in the module.
     * @param  {String} valuetoupdate Possible values: "title", "text", "image", "free-response", "multiple-choice", "content", "options", "correct-answer".
     * @return {Any}
     */
    async function updateSlides({ valuetoupdate, newval, index, optionindex = null, deleteoption }) {
        const updatedSlides = [...module.slides];
        const updatedSlide = {
            ...updatedSlides[currentslide],
            title: valuetoupdate === "title" ? newval : updatedSlides[currentslide].title,
        };
        let updatedContent = [...updatedSlide.content];
        if (
            valuetoupdate === "text" ||
            valuetoupdate === "image" ||
            valuetoupdate === "free-response" ||
            valuetoupdate === "multiple-choice"
        ) {
            updatedContent = [
                ...updatedContent,
                {
                    type: valuetoupdate,
                    content: valuetoupdate === "image" ? newval : "",
                    ...(valuetoupdate === "multiple-choice" && { options: [], "correct-answer": null }),
                },
            ];
        } else if (valuetoupdate === "content" || valuetoupdate === "options" || valuetoupdate === "correct-answer") {
            updatedContent[index] = {
                ...updatedContent[index],
                content: valuetoupdate === "content" ? newval : updatedContent[index].content,
                ...(valuetoupdate === "options" && {
                    options:
                        optionindex !== null
                            ? deleteoption
                                ? updatedContent[index].options.filter((val, i) => i !== optionindex)
                                : updatedContent[index].options.map((val, i) => (i === optionindex ? newval : val))
                            : [...updatedContent[index].options, newval],
                }),
                ...(valuetoupdate === "correct-answer" && { "correct-answer": newval }),
            };
        }
        updatedSlide.content = updatedContent;
        updatedSlides[currentslide] = updatedSlide;
        setModule({ ...module, slides: updatedSlides });
    }

    return (
        <div className="w-full" id="modulecreation">
            <h1 className="font-semibold text-3xl self-start mb-[2vh]">Create Module</h1>
            <TextareaAutosize
                value={module.title}
                onChange={(e) => setModule({ ...module, title: e.target.value })}
                placeholder="Enter Module Title"
                className="!font-normal text-xl self-start !mb-[2vh] w-full"
                style={{ border: "none", outline: "none", resize: "none" }}
            />
            <div className="!flex-row items-start w-full gap-[4vw]">
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="!flex-col items-start w-4/5" id="modulednd">
                        <div
                            className="min-h-[70vh]"
                            id="groupdropdiv"
                            style={{
                                border: "16px solid #AEBFFC",
                                borderRadius: "14px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                padding: "1vh",
                                paddingInline: "1.5vw",
                            }}
                        >
                            <TextareaAutosize
                                value={module.slides[currentslide]?.title}
                                onChange={(e) => updateSlides({ valuetoupdate: "title", newval: e.target.value })}
                                placeholder="Enter Title"
                                className="text-center !mb-0 !font-normal text-xl"
                                style={{ border: "none", outline: "none", resize: "none" }}
                            />
                            <Droppable droppableId="droppable-1">
                                {(provided1) => {
                                    return (
                                        <div
                                            {...provided1.droppableProps}
                                            ref={provided1.innerRef}
                                            style={getListStyle()}
                                        >
                                            {module.slides[currentslide].content.map((item, index) => (
                                                <Draggable
                                                    draggableId={`draggable-group-1-${index}`}
                                                    index={index}
                                                    key={index}
                                                >
                                                    {(provided2) => (
                                                        <div
                                                            ref={provided2.innerRef}
                                                            {...provided2.draggableProps}
                                                            {...provided2.dragHandleProps}
                                                            style={getItemStyle(
                                                                provided2.draggableProps.style,
                                                                item.type
                                                            )}
                                                        >
                                                            {item.type === "text" ? (
                                                                <TextareaAutosize
                                                                    value={item?.content}
                                                                    onChange={(e) =>
                                                                        updateSlides({
                                                                            valuetoupdate: "content",
                                                                            newval: e.target.value,
                                                                            index,
                                                                        })
                                                                    }
                                                                    className="w-full resize-none"
                                                                    placeholder="Enter Text"
                                                                />
                                                            ) : item.type === "image" ? (
                                                                <img
                                                                    src={item.content}
                                                                    alt="content"
                                                                    style={{
                                                                        width: "35vw",
                                                                        height: "auto",
                                                                    }}
                                                                    className="rounded-lg"
                                                                />
                                                            ) : item.type === "free-response" ? (
                                                                <div
                                                                    className="flex rounded-[20px] px-[1vw] py-[1vh] items-center justify-center"
                                                                    style={{ border: "1px solid #D1D5DB" }}
                                                                >
                                                                    <TextareaAutosize
                                                                        value={item?.content}
                                                                        className="modulefreeresponse w-full h-full resize-none"
                                                                        onChange={(e) =>
                                                                            updateSlides({
                                                                                valuetoupdate: "content",
                                                                                newval: e.target.value,
                                                                                index,
                                                                            })
                                                                        }
                                                                        placeholder="Enter Free Response"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="flex flex-col rounded-[20px] px-[1vw] py-[1vh] items-start justify-center gap-[2vh]"
                                                                    style={{ border: "1px solid #D1D5DB" }}
                                                                >
                                                                    <TextareaAutosize
                                                                        value={item?.content}
                                                                        className="modulefreeresponse w-full h-full resize-none"
                                                                        onChange={(e) =>
                                                                            updateSlides({
                                                                                valuetoupdate: "content",
                                                                                newval: e.target.value,
                                                                                index,
                                                                            })
                                                                        }
                                                                        placeholder="Enter Multiple Choice Question"
                                                                    />
                                                                    {item.options.map((option, optionindex) => (
                                                                        <div
                                                                            className="flex flex-row items-center gap-[1vw]"
                                                                            key={optionindex}
                                                                        >
                                                                            <TextareaAutosize
                                                                                value={option}
                                                                                key={optionindex}
                                                                                className="resize-none !outline-none !border-none"
                                                                                onChange={(e) =>
                                                                                    updateSlides({
                                                                                        valuetoupdate: "options",
                                                                                        newval: e.target.value,
                                                                                        index,
                                                                                        optionindex,
                                                                                    })
                                                                                }
                                                                                placeholder={`Option ${
                                                                                    optionindex + 1
                                                                                }`}
                                                                            />
                                                                            <FaTrash
                                                                                color="red"
                                                                                className="cursor-pointer"
                                                                                onClick={() =>
                                                                                    updateSlides({
                                                                                        valuetoupdate: "options",
                                                                                        index,
                                                                                        optionindex,
                                                                                        deleteoption: true,
                                                                                    })
                                                                                }
                                                                            />
                                                                            {/* add a single checkbox so instructors can select the correct answer */}
                                                                            <input
                                                                                type="radio"
                                                                                name={`correctanswer${index}`}
                                                                                checked={
                                                                                    item["correct-answer"] ===
                                                                                    optionindex
                                                                                }
                                                                                onChange={() =>
                                                                                    updateSlides({
                                                                                        valuetoupdate: "correct-answer",
                                                                                        newval: optionindex,
                                                                                        index,
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                    <button
                                                                        onClick={() =>
                                                                            updateSlides({
                                                                                valuetoupdate: "options",
                                                                                index,
                                                                            })
                                                                        }
                                                                    >
                                                                        Add Option
                                                                    </button>
                                                                    {item["correct-answer"] !== null && (
                                                                        <button
                                                                            className="!bg-[red] text-white"
                                                                            onClick={() =>
                                                                                updateSlides({
                                                                                    valuetoupdate: "correct-answer",
                                                                                    newval: null,
                                                                                    index,
                                                                                })
                                                                            }
                                                                        >
                                                                            Clear Correct Answer
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided1.placeholder}
                                        </div>
                                    );
                                }}
                            </Droppable>
                        </div>
                        <div className="w-full !flex-row justify-between mt-[2vh]">
                            <p>
                                Slide {currentslide + 1} of {module.slides.length}
                            </p>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={
                                    <button className="flex items-center justify-center !p-0">
                                        <IoIosArrowRoundForward color="black" size={26} />
                                    </button>
                                }
                                onPageChange={({ selected }) => setCurrentSlide(selected)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                previousLabel={
                                    <button className="flex items-center justify-center !p-0">
                                        <IoIosArrowRoundBack color="black" size={26} />
                                    </button>
                                }
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                pageCount={module.slides.length}
                                forcePage={currentslide}
                            />
                            <div className="!flex-row gap-[1vw]">
                                <button
                                    onClick={() => {
                                        setCurrentSlide(currentslide + 1);
                                        setModule({
                                            ...module,
                                            slides: [
                                                ...module.slides,
                                                {
                                                    title: "",
                                                    content: [],
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    New Slide
                                </button>
                                <button className="!bg-red-500" onClick={() => setDeletingSlide(true)}>
                                    Delete Slide
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/5 flex-col items-center self-start gap-[2vh] justify-start sticky top-[18vh]">
                        <button onClick={() => updateSlides({ valuetoupdate: "text" })}>Add Text</button>
                        {/* image uploads will also trigger backend image storing since localstorage can't store all images.
                        Moreover, if images are deleted on local, they will also be deleted on backend
                    */}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={async (e) => {
                                // console.log("image file uploaded:");
                                toastPromise(
                                    new Promise(async (resolve, reject) => {
                                        const file = e.target.files[0];
                                        // console.log("file is:", file);
                                        if (file) {
                                            const timestamp = Date.now();
                                            const randomString = uuidv4();
                                            const extension = file.name.split(".").pop();
                                            const compressedFile = await imageCompression(file, {
                                                maxSizeMB: 1,
                                                maxWidthOrHeight: 1920,
                                            });
                                            // console.log("compressedFile:", compressedFile, "\n\nfilename:", user.id, "\n\n", timestamp, "\n\n", randomString, "\n\n", extension);
                                            // this line will make a call to backend to store the image, and an image url will be returned
                                            const { link } = await uploadFileToBackblaze(
                                                compressedFile,
                                                `modules/${user.uuid}/${timestamp}-${randomString}.${extension}`,
                                                "modules"
                                            );
                                            // console.log("link:", link);
                                            if (link) {
                                                updateSlides({ valuetoupdate: "image", newval: link });
                                                resolve();
                                            } else {
                                                reject("Error uploading image, please try again later");
                                            }
                                        }
                                    }),
                                    "Image uploading...",
                                    "Image uploaded successfully"
                                );
                                e.target.value = null;
                            }}
                            style={{ display: "none" }}
                            id="imageupload"
                        />
                        <label
                            htmlFor="imageupload"
                            className="cursor-pointer w-full bg-[#5d87ff] px-[0.75vw] py-[0.75vh] text-white rounded-md"
                        >
                            Add Image
                        </label>
                        <button onClick={() => updateSlides({ valuetoupdate: "free-response" })}>
                            Add Free Response
                        </button>
                        <button
                            onClick={() => {
                                updateSlides({ valuetoupdate: "multiple-choice" });
                                toastInfo(
                                    "In order to select the correct answer, click the circle next to the option."
                                );
                            }}
                        >
                            Add Multiple Choice
                        </button>
                        <button
                            className="!bg-[green]"
                            onClick={async () => {
                                try {
                                    const { data, error } = await supabase
                                        .from('modules')
                                        .insert({
                                            title: module.title,
                                            slides: module.slides,
                                        })
                                        .select()
                                        .single();

                                    if (error) {
                                        console.error("Error creating module:", error);
                                        toastError("Error creating module, please try again later");
                                        return;
                                    }

                                    toastSuccess("Module created successfully");
                                    // wipe the module from localstorage and state
                                    localStorage.removeItem("module");
                                    setModule({
                                        slides: [
                                            {
                                                title: "",
                                                content: [],
                                            },
                                        ],
                                    });
                                    navigate("/modules");
                                } catch (error) {
                                    console.error("Error creating module:", error.message);
                                    toastError("Error creating module, please try again later");
                                }
                            }}
                        >
                            Create Module
                        </button>
                        <Droppable droppableId="trash">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                        backgroundColor: "lightgrey",
                                        border: snapshot.isDraggingOver ? "2px solid #5d87ff" : "1px solid black",
                                        padding: "1vh",
                                        width: "100%",
                                        height: "10vh",
                                    }}
                                    className="flex justify-center items-center"
                                >
                                    Trash
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
            </div>
            <Popup
                open={deletingSlide}
                closeOnDocumentClick
                onClose={() => setDeletingSlide(false)}
                className="flex flex-col items-center justify-center"
                overlayStyle={{
                    background: "rgba(0,0,0,0.5)",
                    marginLeft: sidetabvisible ? "25vw" : 0,
                    width: sidetabvisible ? "75vw" : "100vw",
                }}
            >
                <p className="mb-2 text-lg">Are you sure you want to delete this slide?</p>
                <button
                    onClick={async () => {
                        const updatedSlides = [...module.slides];
                        updatedSlides.splice(currentslide, 1);
                        setModule({ ...module, slides: updatedSlides });
                        setCurrentSlide(currentslide - 1);
                        setDeletingSlide(false);
                        toastSuccess("Slide deleted!");
                    }}
                    className="bg-red-600 my-2 text-[1rem] rounded-lg shadow-2xl shadow-cyan-500/50 !px-[1vw] !py-1 text-white"
                >
                    Delete
                </button>
            </Popup>
        </div>
    );
}

import { ReactComponent as WelcomeIcon } from "../../icons/welcome.svg";
import { ReactComponent as CommentIcon } from "../../icons/comment.svg";
import { ReactComponent as CommentArrow } from "../../icons/commentarrow.svg";
import { useEffect, useState } from "react";
import { toastError, toastSuccess, uploadFileToBackblaze } from "../../GlobalFunctions";
import { useNavigate, Link } from "react-router-dom";
import "../../App.css";
import "../../assets/css/home.css";
import { useContext } from "react";
import { AuthContext } from "../../App";
import StudentList from "../global/StudentList";
import { supabase } from "../../supabase";

export default function InstructorHome({ sidetabvisible }) {
  const [files, setFiles] = useState([]);
  const [students, setStudents] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async function getStudents() {
      console.log("email is:", user?.email);
      const { data: studentdata, error: studenterror } = await supabase.from("students").select(
        `email, id, name, mentor: mentors(id, name),
                studentgroups(worksite: worksites (name), instructor: instructors!studentgroups_instructor_fkey(email)), hours, linked_modules
            `
      );
      if (studenterror) console.error("error", studenterror);
      else {
        const filtered_student_data = studentdata.filter(
          (student) => student.studentgroups?.instructor?.email === user?.email
        );
        console.log("student data for instructor:", filtered_student_data);

        // also need to get all mentors
        const { data: mentordata, error: mentorerror } = await supabase
          .from("mentors")
          .select(`id, name, students(studentgroups(worksite: worksites (name))), hours, email`)
          .in(
            "id",
            filtered_student_data.map((student) => student.mentor?.id).filter((val) => typeof val === "number")
          );

        if (mentorerror) console.error("error", mentorerror);
        else console.log("mentor data for admin:", mentordata);

        const { data: surveydata, error: surveyerror } = await supabase.from("surveys").select("title, completed_by");
        // .eq('instructor', user?.email)

        if (surveyerror) console.error("error", surveyerror);
        else console.log("survey data for admin:", surveydata);

        setStudents(
          [...filtered_student_data, ...mentordata].map((val) => {
            const completedsurveys = surveydata.filter((survey) => survey.completed_by.includes(val.email));
            const allsurveys = surveydata;
            const surveypercent = Math.round((completedsurveys.length / allsurveys.length) * 100);
            // console.log(
            //   "completed surveys:",
            //   completedsurveys,
            //   "all surveys:",
            //   allsurveys,
            //   "survey percent:",
            //   surveypercent
            // );

            return {
              ID: val.id,
              Name: val.name || "N/A",
              Email: val.email,
              Role: Object.keys(val).includes("mentor") ? "Student" : "Mentor",
              Mentor: Object.keys(val).includes("mentor") ? (val.mentor ? val.mentor.name : "N/A") : "N/A",
              Worksite: Object.keys(val).includes("mentor")
                ? val.studentgroups?.worksite.name
                : val.students[0].studentgroups?.worksite.name,
              Hours: val.hours,
              "% of Surveys Completed": surveypercent,
              "% of Modules Completed": Object.keys(val).includes("mentor")
                ? val.linked_modules
                  ? val.linked_modules.filter((module) => module.completed).length / val.linked_modules.length
                  : "0"
                : "N/A",
            };
          })
        );
      }
    })();
  }, []);

  return (
    <div className="App flex !flex-row w-full">
      {/* Menu Bar */}

      {/* Main Content Section */}
      <div id="" className="flex gap-[1.88rem] maincontent" style={{ width: sidetabvisible ? "72vw" : "100vw" }}>
        <StudentList sidetabvisible={sidetabvisible} students={students} title={"Students"} page={"InstructorHome"} />
      </div>
    </div>
  );
}

import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function ModuleAnswers() {
  const location = useLocation();
  const { module, student } = location.state || {};

  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (student && student.Answers) {
      const studentAnswers = Object.values(student.Answers).flat();
      setAnswers(studentAnswers);
    }
  }, [student]);

  return (
    <div className="!self-start ml-[2vw]">
      <div className="!items-start gap-[2vh]">
        <h1 className="text-3xl font-bold" style={{ color: "#686868" }}>
          {module.title}
        </h1>
        <div style={{ backgroundColor: "#F1F1F1" }} className="p-4 rounded-xl !items-start">
          <p className="font-semibold">Answers:</p>
          {Array.isArray(answers) && answers.length > 0 ? (
            answers.map((answer, index) => (
              <div key={index} className="!items-start gap-[1vh]">
                <p className="font-bold">Answer {index + 1}:</p>
                <p>{answer}</p>
              </div>
            ))
          ) : (
            <p>No answers available.</p>
          )}
        </div>
      </div>
    </div>
  );
}
import { GlobalStyles } from "../GlobalStyles";
import ProductGoal from "../components/global/ProductGoal";
import RecentTasks from "../components/student/RecentTasks";
import { ReactComponent as ModuleIcon } from "../assets/icons/moduleicon.svg";
import { useContext, useEffect, useState } from "react";
import { supabase } from "../supabase";
import { AuthContext } from "../App";
import ModuleCard from "../components/student/ModuleCard";
import opportunities from '../icons/opportunities.png';

export default function Opportunities() {
    return (
        <div id="opportunitiesdiv" className="gap-x-[2rem] !flex-row !items-start w-full atleast769:px-[2vw]">
            <div id="opportunitiesleftdiv" className="w-[90%] ">
                <img src={opportunities} alt="opportunities" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
            </div>
            <div id="opportunitiesrightdiv" className="w-[50%] gap-y-[3vh]">
                <ProductGoal />
                <RecentTasks />
            </div>
        </div>  
    );
}
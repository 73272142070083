import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useTransition, animated } from "react-spring";
import { supabase } from "../supabase";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { checkOnboarding, getTableName, toastError, toastSuccess } from "../GlobalFunctions";
import "../assets/css/onboarding.css";

export default function Onboarding({ setOnboardingDone, passedrole, setUser }) {
    // console.log("passedrole is:", passedrole);
    // Access the state from the navigation
    // const { state: { givenrole } = {} } = useLocation() || {};
    const location = useLocation();
    const givenrole = location.state?.givenrole;

    //     const { state } = useLocation();
    //     const isStudent = (state && state.isStudent) || role === "Student";
    //     const isMentor = (state && state.isMentor) || role === "Mentor";
    //     //const isStudent = true;
    //     console.log("isStudent:", isStudent)
    //     console.log("isMentor:", isMentor)
    const navigate = useNavigate();
    const [finalobj, setfinalobj] = useState({
        name: "",
        role: givenrole || passedrole,
        group: "",
    });
    const [transitionDirection, setTransitionDirection] = useState("next");
    // const [allquestions, setAllQuestions] = useState(questions)
    // if student, concat studentQuestions, if admin or instructor, concat instructorandadminQuestions

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const studentQuestions = [
        {
            question: "Which of the following best describes your educational background?",
            qterm: "educationalBackground",
        },
        {
            question: "What is your preferred learning style?",
            qterm: "learningStyle",
        },
        {
            question: "Which of the following best describes your expectations from this program/course/position?",
            qterm: "expectations",
        },
        {
            question: "What's your preferred method of communication for academic or work-related matters?",
            qterm: "preferredCommunication",
        },
        {
            question: "How do you usually respond to feedback and criticism?",
            qterm: "responseToFeedback",
        },
        { question: "Which work style suits you the best?", qterm: "workStyle" },
        {
            question: "What resources or support do you think will most benefit you here?",
            qterm: "neededResources",
        },
        {
            question: "Which of the following challenges have you faced in previous educational or work environments?",
            qterm: "previousChallenges",
        },
        {
            question: "Which sector are you most interested in working in?",
            qterm: "interestedSector",
        },
        {
            question: "Which skill do you believe is most vital for your desired career path?",
            qterm: "vitalSkill",
        },
        {
            question: "How important is networking in your career development?",
            qterm: "importanceOfNetworking",
        },
        {
            question: "How do you prefer to develop professionally?",
            qterm: "professionalDevelopmentMethod",
        },
    ];

    const worksiteQuestions = [
        {
            question: "Please enter your worksite's street address",
            qterm: "coords",
        },
        { question: "What industry are you in?", qterm: "industry" },
        {
            question: "How do you handle conflicts within your team?",
            qterm: "conflictResolution",
        },
        {
            question: "What strategies do you use to motivate your team members?",
            qterm: "motivationStrategies",
        },
        {
            question: "How familiar are you with our company's products or services?",
            qterm: "companyKnowledge",
        },
        {
            question: "How do you prioritize tasks during a busy period?",
            qterm: "taskPrioritization",
        },
        {
            question: "What's your leadership style like?",
            qterm: "leadershipStyle",
        },
        {
            question: "How do you ensure compliance with company policies and regulations?",
            qterm: "complianceStrategies",
        },
        {
            question: "What do you believe is the most challenging aspect of being an administrator?",
            qterm: "adminChallenges",
        },
        {
            question: "How do you adapt to changes in company direction or strategy?",
            qterm: "adaptability",
        },
        {
            question: "How do you foster teamwork and collaboration within your department?",
            qterm: "teamworkFostering",
        },
        {
            question: "Describe a time when you had to make a difficult decision and how you went about it.",
            qterm: "difficultDecision",
            shortanswer: true,
        },
        {
            question: "How do you keep up with industry trends and updates?",
            qterm: "industryUpdates",
            shortanswer: true,
        },
        {
            question: "What strategies do you employ for effective time management?",
            qterm: "timeManagementStrategies",
            shortanswer: true,
        },
    ];
    // Potential Industries: Technology, Healthcare, Finance, Arts, Manufacturing, Non-profit
    const mentorQuestions = [{ question: "What is your expertise area?", qterm: "industry" }];

    const studentQuestionOptions = {
        role: [{ value: "student", label: "Student" }],
        educationalBackground: [
            { value: "highschoolDiploma", label: "High school diploma" },
            { value: "bachelorsDegree", label: "Bachelor's degree" },
            { value: "mastersDegree", label: "Master's degree" },
            {
                value: "doctorateProfessional",
                label: "Doctorate or professional degree",
            },
            { value: "other", label: "Other" },
        ],
        learningStyle: [
            { value: "visual", label: "Visual (graphs, charts, videos)" },
            //
            { value: "auditory", label: "Auditory (lectures, podcasts)" },
            // Arts
            {
                value: "kinesthetic",
                label: "Kinesthetic (hands-on, practical experience)",
            },
            // Healthcare,
            {
                value: "readingWriting",
                label: "Reading/writing (books, articles, notes)",
            },
            // Healthcare
            { value: "mixedStyle", label: "A mix of the above" },
            //
        ],
        expectations: [
            {
                value: "gainSkillsKnowledge",
                label: "Gain specific skills or knowledge",
            },
            { value: "buildNetwork", label: "Build a professional network" },
            {
                value: "obtainCertificationDegree",
                label: "Obtain a certification or degree",
            },
            {
                value: "personalFulfillment",
                label: "Personal fulfillment or passion",
            },
            { value: "unsure", label: "Not sure yet" },
        ],
        preferredCommunication: [
            { value: "email", label: "Email" },
            { value: "phoneCall", label: "Phone call" },
            { value: "inPerson", label: "In-person meetings" },
            { value: "instantMessaging", label: "Instant messaging or chat apps" },
            { value: "mixedCommunication", label: "A combination of the above" },
        ],
        responseToFeedback: [
            {
                value: "welcomeGrowth",
                label: "I welcome it and see it as a growth opportunity.",
            },
            {
                value: "defensiveConsiderLater",
                label: "I get defensive but later consider the feedback.",
            },
            {
                value: "preferGentle",
                label: "I prefer gentle feedback over direct criticism.",
            },
            { value: "rarelyHelpful", label: "I rarely find feedback helpful." },
            {
                value: "dependsOnSituation",
                label: "It depends on the situation and who's giving the feedback.",
            },
        ],
        workStyle: [
            { value: "independently", label: "Independently" },
            { value: "inTeams", label: "In teams" },
            { value: "mixOfBoth", label: "A mix of both independent and team work" },
            { value: "directSupervision", label: "Under direct supervision" },
            { value: "variesByProject", label: "It varies based on the project" },
        ],
        neededResources: [
            { value: "mentorshipGuidance", label: "Mentorship or guidance" },
            {
                value: "onlineResources",
                label: "Access to online resources and materials",
            },
            { value: "feedbackSessions", label: "Regular feedback sessions" },
            { value: "peerSupport", label: "Peer support or group study sessions" },
            { value: "flexibleSchedule", label: "Flexible schedule or pacing" },
        ],
        previousChallenges: [
            { value: "communicationBarriers", label: "Communication barriers" },
            {
                value: "difficultyUnderstanding",
                label: "Difficulty understanding certain topics or skills",
            },
            { value: "teamworkIssues", label: "Group dynamics or teamwork issues" },
            {
                value: "workLifeBalance",
                label: "Balancing work, studies, and personal life",
            },
            {
                value: "lackOfSupport",
                label: "Lack of necessary resources or support",
            },
        ],
        interestedSector: [
            { value: "technologyInnovation", label: "Technology and innovation" },
            { value: "healthcareMedicine", label: "Healthcare and medicine" },
            { value: "financeBanking", label: "Finance and banking" },
            { value: "artsEntertainment", label: "Arts and entertainment" },
            { value: "nonprofitSocialWork", label: "Non-profit and social work" },
        ],
        vitalSkill: [
            {
                value: "technicalProficiency",
                label: "Technical proficiency in my field",
            },
            {
                value: "communicationSkills",
                label: "Communication and interpersonal skills",
            },
            {
                value: "leadershipManagement",
                label: "Leadership and management capabilities",
            },
            {
                value: "creativityProblemSolving",
                label: "Creativity and problem-solving",
            },
            {
                value: "adaptabilityLearning",
                label: "Adaptability and continuous learning",
            },
        ],
        importanceOfNetworking: [
            { value: "essential", label: "Essential - It's all about who you know." },
            {
                value: "important",
                label: "Important, but not more than my skills and experience.",
            },
            {
                value: "occasionalNetworking",
                label: "I network occasionally when opportunities arise.",
            },
            {
                value: "rarelyEngage",
                label: "I rarely engage in networking events or activities.",
            },
            { value: "workSpeaks", label: "I believe my work speaks for itself." },
        ],
        professionalDevelopmentMethod: [
            {
                value: "formalEducation",
                label: "Through formal education and certifications",
            },
            { value: "onJobTraining", label: "On-the-job training and experiences" },
            {
                value: "workshopsConferences",
                label: "Attending workshops and conferences",
            },
            {
                value: "selfDirectedLearning",
                label: "Self-directed learning and online courses",
            },
            { value: "mixedMethods", label: "A mix of the above methods" },
        ],
    };

    const worksiteQuestionOptions = {
        role: [{ value: "mentor", label: "Mentor" }],
        industry: [
            { value: "techSoftware", label: "Technology and Software Development" },
            { value: "healthcareMedical", label: "Healthcare and Medical Services" },
            { value: "financeBanking", label: "Finance and Banking" },
            {
                value: "artsMediaEntertainment",
                label: "Arts, Media, and Entertainment",
            },
            {
                value: "manufacturingProduction",
                label: "Manufacturing and Production",
            },
            { value: "nonProfitSocial", label: "Non-profit and Social Services" },
            { value: "othersIndustry", label: "Others (please specify)" },
        ],
        conflictResolution: [
            { value: "researchDevelopment", label: "Research and Development" },
            { value: "salesMarketing", label: "Sales and Marketing" },
            { value: "operationsLogistics", label: "Operations and Logistics" },
            { value: "designCreativity", label: "Design and Creativity" },
            { value: "dataAnalysisReporting", label: "Data Analysis and Reporting" },
            { value: "adminClerical", label: "Administration and Clerical Tasks" },
            { value: "othersFunction", label: "Others (please specify)" },
        ],
        motivationStrategies: [
            {
                value: "techProficiency",
                label: "Technical proficiency in specific software/tools",
            },
            {
                value: "communicationSkills",
                label: "Communication and interpersonal skills",
            },
            {
                value: "analyticalAbilities",
                label: "Analytical and problem-solving abilities",
            },
            { value: "creativityInnovation", label: "Creativity and innovation" },
            {
                value: "orgTimeManagement",
                label: "Organizational and time-management skills",
            },
            {
                value: "leadershipManagement",
                label: "Leadership or project management",
            },
            {
                value: "industryKnowledge",
                label: "Knowledge of industry trends and news",
            },
        ],
        companyKnowledge: [
            { value: "lessThan3Months", label: "Less than 3 months" },
            { value: "threeToSixMonths", label: "3-6 months" },
            { value: "sixToTwelveMonths", label: "6-12 months" },
            { value: "flexibleProjectBased", label: "Flexible or project-based" },
            { value: "othersDuration", label: "Others (please specify)" },
        ],
        taskPrioritization: [
            { value: "fastPacedDynamic", label: "Fast-paced and dynamic" },
            { value: "structuredCorporate", label: "Structured and corporate" },
            {
                value: "collaborativeTeamOriented",
                label: "Collaborative and team-oriented",
            },
            { value: "startupEntrepreneurial", label: "Startup or entrepreneurial" },
            {
                value: "sociallyConsciousMissionDriven",
                label: "Socially-conscious or mission-driven",
            },
        ],
        leadershipStyle: [
            {
                value: "highAutonomyIndividualProjects",
                label: "High autonomy with individual projects",
            },
            {
                value: "collaborativeTeamSharedResponsibility",
                label: "Collaborative team projects with shared responsibility",
            },
            {
                value: "guidedTasksRegularSupervision",
                label: "Guided tasks with regular supervision",
            },
            {
                value: "mixDependingOnProjectPhase",
                label: "A mix depending on the project or phase",
            },
            { value: "othersAutonomy", label: "Others (please specify)" },
        ],
        complianceStrategies: [
            { value: "engineeringCompSci", label: "Engineering or Computer Science" },
            { value: "businessEconomics", label: "Business or Economics" },
            { value: "artsHumanities", label: "Arts or Humanities" },
            { value: "lifeSciencesMedicine", label: "Life Sciences or Medicine" },
            {
                value: "anyBackgroundRelevantSkills",
                label: "Any background with relevant skills",
            },
        ],
        adminChallenges: [
            {
                value: "fullTimeOffer",
                label: "Yes, we often offer full-time positions to our interns",
            },
            {
                value: "possiblePerformanceBased",
                label: "It's possible, depending on performance and company needs",
            },
            {
                value: "fixedDurationOnly",
                label: "No, the internship is only for a fixed duration",
            },
            { value: "unsureAtThisMoment", label: "Unsure at this moment" },
        ],
        adaptability: [
            { value: "onSiteLocation", label: "On-site at the company location" },
            { value: "remoteWorkFromHome", label: "Remote or work-from-home" },
            { value: "hybridMix", label: "Hybrid (a mix of on-site and remote)" },
            { value: "fieldworkTravel", label: "Fieldwork or traveling involved" },
            {
                value: "flexibleBasedOnPreference",
                label: "Flexible, based on the intern's preference and project needs",
            },
        ],
        teamworkFostering: [
            {
                value: "freshIdeasPerspectives",
                label: "Bringing fresh ideas and perspectives",
            },
            {
                value: "supportOngoingProjects",
                label: "Supporting ongoing projects and teams",
            },
            {
                value: "spearheadNewProjects",
                label: "Spearheading new projects or initiatives",
            },
            {
                value: "optimizeCurrentProcesses",
                label: "Analyzing and optimizing current processes",
            },
            {
                value: "clientCustomerInteractions",
                label: "Engaging in client or customer interactions",
            },
        ],
    };

    const instructorandadminQuestions = [

    ];

    const questions = [
        { question: "What's your full name?", qterm: "name" },
        ...(finalobj.role === "Student"
            ? [{ question: "What is your group?", qterm: "group" }, ...studentQuestions]
            : instructorandadminQuestions),
    ];

    const transitions = useTransition(currentQuestion, {
        from: {
            opacity: 0,
            transform: transitionDirection === "next" ? "translate3d(200%,0,0)" : "translate3d(-200%,0,0)",
        },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: {
            opacity: 0,
            transform: transitionDirection === "next" ? "translate3d(-200%,0,0)" : "translate3d(200%,0,0)",
        },
        // config: { duration: 5000 },
    });

    const options = [
        { value: "Student", label: "Student" },
        { value: "Mentor", label: "Mentor" },
        { value: "Instructor", label: "Instructor" },
        // student will join from link that tells that they're already a signing up student
    ];

    async function savetoSupabase() {
        // store new row with name and email in corresponding table
        const user = await supabase.auth.getUser();
        // console.log("user in savetoSupabase:", user)
        // transforms the 'finalobj' object. For each property, if the value is an object and has a 'value' property,
        // it replaces the original value with the value of the 'value' property. Otherwise, it keeps the original value.
        const updatedObj = Object.fromEntries(
            Object.entries(finalobj).map(([key, value]) => [
                key,
                value && typeof value === "object" && value.hasOwnProperty("value") ? value.value : value,
            ])
        );
        // console.log("updatedobj before:", updatedObj)
        delete updatedObj.role;
        delete updatedObj.name;

        // console.log("updatedobj after:", updatedObj)

        const tableName = getTableName(finalobj.role);
        


        if (finalobj.role === "Student") {
            const { data: groupData, error: groupError } = await supabase
                .from("studentgroups")
                .select("uuid")
                .eq("name", finalobj.group)
                .single();

            if (groupError) {
                console.error("Group Error:", groupError);
                toastError("Error fetching group information. Please try again!");
                return;
            }

            updatedObj.group = groupData.uuid;
        }

        console.log("tablename:", tableName);
        if (finalobj.role === "Student") {
            console.log("updatedObj:", updatedObj, "\nemail:", user.data.user.email);
            const { error: studenterror } = await supabase
                .from("students")
                .update({
                    onboardinganswers: updatedObj,
                    name: finalobj.name,
                    mentor: 1,
                    group: updatedObj.group,
                    linked_modules: [
                        { id: 1, completed: false },
                        { id: 2, completed: false },
                        { id: 3, completed: false },
                        { id: 4, completed: false },
                        { id: 5, completed: false },
                    ],
                })
                .eq("email", user.data.user.email);
            if (studenterror) {
                console.error("Student Error:", studenterror);
                toastError("Please try again!");
            }
        } else {
            console.log(
                "updatedObj:",
                updatedObj,
                "\nemail:",
                user.data.user.email,
                "\nfinalobj:",
                finalobj,
                "\ntablename:",
                tableName
            );
            const { error: tableerror } = await supabase
                .from(tableName)
                .update({ onboardinganswers: updatedObj, name: finalobj.name })
                .eq("email", user.data.user.email);
            if (tableerror) {
                console.error("Table Error:", tableerror);
                toastError("Please try again!");
            }
        }

        // add role to user data

        // const { error } = await supabase.auth.updateUser({ data: { ...finalobj, role: finalobj.role.value, } });

        // if (error) {
        //     console.error(error);
        //     toastError("Error saving role!");
        // } else {
        // toastSuccess("Successfully updated your role!");
        // store name in user metadata
        // const { error } = await supabase.auth.updateUser({ data: { name: finalobj.name } })
        const { error } = await supabase.auth.updateUser({
            data: { ...finalobj, role: finalobj.role, name: finalobj.name, group: finalobj.group },
        });
        if (error) {
            console.error("error saving metadata:", error);
            toastError("Error saving metadata!");
        } else {
            setUser((prev) => ({ ...prev, role: finalobj.role }));
            console.log("finalobj.role:", finalobj.role, "user.data.user.email:", user.data.user.email);
            // const onboardinganswers = await checkOnboarding(finalobj.role.value, user.data.user.email);
            const onboardinganswers = await checkOnboarding(finalobj.role, user.data.user.email);
            if (onboardinganswers) {
                toastSuccess("Successfully updated your onboarding answers!");
                setOnboardingDone(true);
                navigate("/");
            } else {
                toastError("Please try again!");
            }
        }
        // }
    }

    useEffect(() => {
        // console.log("finalobj:", finalobj)
    }, [finalobj]);

    // useEffect(() => {

    // let updatedQuestions = [...questions];
    // if (finalobj.role?.value === "Student") {
    // Here, ensure studentQuestions are added only if the role is Student.
    // updatedQuestions = updatedQuestions.concat(studentQuestions);
    // } // Add conditions for other roles as necessary
    // setAllQuestions(updatedQuestions);
    // }, [finalobj.role]);

    return (
        <div
            id="questform"
            className="flex flex-row items-center justify-center text-black absolute top-1/2 left-1/2 w-screen"
        >
            {transitions((style, item) => {
                // console.log("item:", item, "allquestions item:", allquestions[item], allquestions)
                // console.log("item:", item, "\nquestions item:", questions[item], "\nquestions:", questions);
                return (
                    <animated.div style={style} className="absolute flex flex-col items-center">
                        {/* <p>{item+1}.&nbsp;{allquestions[item].question}</p> */}
                        <p>
                            {item + 1}.&nbsp;{questions[item].question}
                        </p>
                        {item === 0 && (
                            <input
                                placeholder="Name"
                                className="onboardinginput shadows !mt-4 min-w-fit !w-auto rounded-md mr-[5px]"
                                onChange={(e) => setfinalobj({ ...finalobj, name: e.target.value })}
                                value={finalobj.name || ""}
                            />
                        )}
                        {/* if Worksite or Admin */}
                        {/* {(item === 1 && finalobj.role?.value !== 'Student') && (
                            <Select
                                name={allquestions[item].question}
                                options={options}
                                value={role}
                                onChange={(val) => setfinalobj({...finalobj, role: val})}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: '50%',
                                        margin: 'auto',
                                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        fontSize: '1rem',
                                    }),
                                }}
                            />
                        )} */}
                        {questions[item].qterm === "group" && (
                            <input
                                placeholder="Group"
                                className="onboardinginput shadows !mt-4 min-w-fit !w-auto rounded-md mr-[5px]"
                                onChange={(e) => setfinalobj({ ...finalobj, group: e.target.value })}
                                value={finalobj.group || ""}
                            />
                        )}
                        {item > 1 && (
                            <div className="w-full">
                                {/* if asked to put in coords, show Autocomplete */}
                                {/* {allquestions[item].qterm === 'coords' && */}
                                {questions[item].qterm === "coords" && (
                                    <ReactGoogleAutocomplete
                                        apiKey={process.env.REACT_APP_MAPS_KEY}
                                        options={{ types: ["address"] }}
                                        onPlaceSelected={(place) => {
                                            console.log("place pressed is:", place);
                                            setfinalobj({
                                                ...finalobj,
                                                coords: {
                                                    lat: place.geometry.location.lat(),
                                                    lng: place.geometry.location.lng(),
                                                },
                                            });
                                        }}
                                        className="searchbar"
                                    />
                                )}
                                {/* if question has short answer prop, don't show Select */}
                                {/* {allquestions[item].qterm !== 'coords' && !allquestions[item].shortanswer && */}
                                {questions[item].qterm !== "coords" &&
                                    !questions[item].shortanswer &&
                                    questions[item].qterm !== "organization" && (
                                        <Select
                                            // name={allquestions[item].question}
                                            name={questions[item].question}
                                            // options={finalobj.role?.value === "Worksite" ? (worksiteQuestionOptions[allquestions[item].qterm] || []) :
                                            // options={finalobj.role === "Worksite" ? (worksiteQuestionOptions[questions[item].qterm] || []) :
                                            // finalobj.role?.value === "Student" ? (studentQuestionOptions[allquestions[item].qterm] || []) : []}
                                            options={
                                                finalobj.role === "Student"
                                                    ? studentQuestionOptions[questions[item].qterm] || []
                                                    : []
                                            }
                                            // value={finalobj[allquestions[item].qterm]}
                                            value={finalobj[questions[item].qterm]}
                                            // onChange={(val) => setfinalobj({...finalobj, [allquestions[item].qterm]: val})}
                                            onChange={(val) =>
                                                setfinalobj({
                                                    ...finalobj,
                                                    [questions[item].qterm]: val,
                                                })
                                            }
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "50%",
                                                    margin: "auto",
                                                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                                                }),
                                                input: (provided) => ({
                                                    ...provided,
                                                    color: "black",
                                                    fontSize: "1rem",
                                                }),
                                            }}
                                        />
                                    )}
                                {/* if question title has "describe" or if "Please Specify" option of question is selected, provide textarea */}
                                {/* {allquestions[item].qterm !== 'coords' && */}
                                {questions[item].qterm !== "coords" &&
                                    // ((finalobj[allquestions[item].qterm] && (finalobj[allquestions[item].qterm].label.includes("specify"))) ||
                                    ((finalobj[questions[item].qterm] &&
                                        finalobj[questions[item].qterm].label?.includes("specify")) ||
                                        // allquestions[item].shortanswer)
                                        questions[item].shortanswer) && (
                                        // <textarea id={finalobj[allquestions[item].qterm + "specific"]}
                                        <textarea
                                            id={finalobj[questions[item].qterm + "specific"]}
                                            // value={finalobj[allquestions[item].qterm + "specific"]}
                                            value={finalobj[questions[item].qterm + "specific"]}
                                            // onChange={e => setfinalobj({ ...finalobj, [allquestions[item].qterm + "specific"]: e.target.value })}
                                            onChange={(e) =>
                                                setfinalobj({
                                                    ...finalobj,
                                                    [questions[item].qterm + "specific"]: e.target.value,
                                                })
                                            }
                                            className="textareaspecify"
                                        ></textarea>
                                    )}
                                {questions[item].qterm === "organization" && (
                                    <input
                                        className="shadows !mt-4 min-w-fit !w-auto rounded-md mr-[5px]"
                                        onChange={(e) => setfinalobj({ ...finalobj, organization: e.target.value })}
                                        value={finalobj.organization || ""}
                                    />
                                )}
                            </div>
                        )}
                        {/* if last item, display Last Question and Submit buttons */}
                        {/* {item === (allquestions.length - 1) ? ( */}
                        {item === questions.length - 1 ? (
                            <div className="flex flex-col items-center justify-center">
                                {/* {allquestions.length > 1 && */}
                                {questions.length > 1 && (
                                    <button
                                        id="prevq"
                                        className="mb-4 mt-8 text-[1.25rem] shadow-2xl shadow-cyan-500/50 px-4 py-1 text-white"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTransitionDirection("prev");
                                            setCurrentQuestion(currentQuestion - 1);
                                        }}
                                    >
                                        Previous Question
                                    </button>
                                )}
                                <button
                                    id="questsubmit"
                                    className="mt-[1vh] text-[1.25rem] shadow-2xl shadow-cyan-500/50 px-4 py-1 text-white"
                                    onClick={async () => {
                                        // if all questions aren't answered, don't allow submission
                                        // if (Object.keys(finalobj).length < allquestions.length) {
                                        if (Object.keys(finalobj).length - 1 < questions.length) {
                                            // console.log("fol:", Object.keys(finalobj).length, "al:", allquestions.length)
                                            // console.log("submit finalobj:", finalobj, "submit al:", allquestions)
                                            toastError("Make sure all questions are completed before submitting!");
                                        } else {
                                            await savetoSupabase();
                                        }
                                    }}
                                >
                                    Submit
                                </button>
                                {/* } */}
                            </div>
                        ) : // if first item and there's more than one question, add Next Question button
                        // ) : item === 0 && allquestions.length > 1 ? (
                        item === 0 && questions.length > 1 ? (
                            <button
                                id="nextq"
                                className="mb-4 mt-8 text-[1.25rem] shadow-2xl shadow-cyan-500/50 px-4 py-1 text-white"
                                onClick={() => {
                                    setTransitionDirection("next");
                                    setCurrentQuestion(currentQuestion + 1);
                                }}
                            >
                                Next Question
                            </button>
                        ) : (
                            // if not first nor last question, show last and next questions
                            <div className="flex flex-col items-center justify-center">
                                {currentQuestion !== 0 && (
                                    <button
                                        id="prevq"
                                        className="mb-4 mt-8 text-[1.25rem] shadow-2xl shadow-cyan-500/50 px-4 py-1 text-white"
                                        onClick={() => {
                                            setTransitionDirection("prev");
                                            setCurrentQuestion(currentQuestion - 1);
                                        }}
                                    >
                                        Previous Question
                                    </button>
                                )}
                                <button
                                    id="nextq"
                                    className="mb-4 mt-[2vh] text-[1.25rem] shadow-2xl shadow-cyan-500/50 px-4 py-1 text-white"
                                    onClick={() => {
                                        setTransitionDirection("next");
                                        setCurrentQuestion(currentQuestion + 1);
                                    }}
                                >
                                    Next Question
                                </button>
                            </div>
                        )}
                    </animated.div>
                );
            })}
        </div>
    );
}

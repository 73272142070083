import { GlobalStyles } from "../GlobalStyles";
import ProductGoal from "../components/global/ProductGoal";
import RecentTasks from "../components/student/RecentTasks";
import { useLocation, useNavigate } from "react-router-dom";
import UploadCard from "../components/student/UploadCard";
import DownloadCard from "../components/student/DownloadCard";
import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { TextareaAutosize } from "@mui/material";
import PuffLoader from "react-spinners/PuffLoader";
import { supabase } from "../supabase";
import { toastPromise } from "../GlobalFunctions";
import { AuthContext } from "../App";
import "../assets/css/module.css";

export default function Module() {
    const navigate = useNavigate();
    const {
        state: { module },
    } = useLocation();
    const [modulecopy, setModuleCopy] = useState({ ...module });
    const [answers, setAnswers] = useState({});
    const [currentslide, setCurrentSlide] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useContext(AuthContext);
    const [answermissing, setAnswerMissing] = useState({});

    useEffect(() => {
        if (!module.slides) {
            return;
        }

        const savedModuleId = localStorage.getItem("module_id");
        if (savedModuleId !== module.id) {
            localStorage.removeItem("module_answers");
            localStorage.setItem("module_id", module.id);
        }

        console.log("Initial module slides:", module.slides);
        const saved_data = localStorage.getItem("module_answers");
        console.log("Saved data from localStorage:", saved_data);
        const updatedSlides = module.slides.map((slide, index) => {
            let question_count = slide.content.reduce((count, content, currentIndex) => {
                if (content.type === "multiple-choice" || content.type === "free-response") {
                    const updatedContent = [...slide.content];
                    console.log("Counting content:", content);
                    updatedContent[currentIndex] = { ...updatedContent[currentIndex], index: count };
                    slide.content = updatedContent;
                    count++;
                }
                return count;
            }, 0);

            console.log(`Slide ${index} has ${question_count} questions.`);

            if (saved_data) {
                const parsedData = JSON.parse(saved_data);
                console.log("Parsed saved data:", parsedData);
                setAnswers(parsedData);
            } else if (question_count > 0) {
                let answers = Array(question_count).fill("");
                console.log(`Initializing answers for slide ${index}:`, answers);
                setAnswers((prev) => ({ ...prev, [index]: answers }));
            }

            return { ...slide, content: slide.content };
        });

        console.log("Updated slides:", updatedSlides);

        setModuleCopy((prevModuleCopy) => ({
            ...prevModuleCopy,
            slides: updatedSlides,
        }));
    }, []);

    useEffect(() => {
        if (Object.keys(answers).length !== 0) {
            console.log("answers:", answers);
            localStorage.setItem("module_answers", JSON.stringify(answers));
        }
    }, [answers]);

    useEffect(() => {
        console.log("modulecopy:", modulecopy);
        if (isLoading) {
            setIsLoading(false);
        }
    }, [modulecopy]);

    useEffect(() => {
        if (Object.keys(answermissing).length !== 0) {
            // Find the index of the unanswered question in the filtered content array
            let currentIndex = -1;
            let correctIndex = -1;
            for (let i = 0; i < modulecopy.slides[answermissing.slideindex].content.length; i++) {
                if (
                    modulecopy.slides[answermissing.slideindex].content[i].type === "free-response" ||
                    modulecopy.slides[answermissing.slideindex].content[i].type === "multiple-choice"
                ) {
                    currentIndex++;
                }
                if (currentIndex === answermissing.index) {
                    correctIndex = i;
                    break;
                }
            }

            if (correctIndex !== -1) {
                // console.log("correctIndex:", correctIndex, "\n\nslideindex:", answermissing.slideindex);
                // get the nth child of the div with key of slideindex, and add a class to it
                const unanswered_element = document.getElementById(`slide${answermissing.slideindex}`).children[
                    correctIndex
                ].firstChild;
                // console.log("unanswered_element:", unanswered_element);
                // smoothly scroll to the unanswered question
                unanswered_element.scrollIntoView({ behavior: "smooth", block: "center" });
                // add a class to the unanswered question for 5 seconds
                unanswered_element.classList.add("unanswered");
                // console.log("classes of unanswered_element:", unanswered_element.classList);
                setTimeout(() => {
                    unanswered_element.classList.remove("unanswered");
                    // setAnswerMissing({});
                }, 6000);
            }
        }
    }, [answermissing]);

    // useEffect(() => {
    //     // Reset answermissing if the slide changes from the initial slide
    //     if (currentslide !== initialslide) {
    //         setAnswerMissing({});
    //     }
    // }, [currentslide, initialslide]);

    async function updateAnswers({ slideindex, index, answer }) {
        setAnswers((prev) => {
            const newAnswers = prev[slideindex] ? [...prev[slideindex]] : [];
            newAnswers[index] = answer;
            console.log("Updated answers:", { ...prev, [slideindex]: newAnswers });
            return { ...prev, [slideindex]: newAnswers };
        });
    }

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    return modulecopy.slides ? (
        <div className="w-full pb-[5vh]" id="modulecreation">
            <h1 className="font-semibold text-3xl self-start mb-[2vh]">{modulecopy.title}</h1>
            <div className="!flex-col items-start w-full" id="modulednd">
                <div
                    className="min-h-[70vh] p-[3vh] gap-[2vh]"
                    id="groupdropdiv"
                    style={{
                        border: "16px solid #AEBFFC",
                        borderRadius: "14px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        paddingInline: "1.5vw",
                    }}
                >
                    <h1 className="text-center !mb-0 text-xl font-bold underline">
                        {modulecopy.slides[currentslide]?.title}
                    </h1>
                    <div className="flex flex-col text-left gap-[2vh]" id={`slide${currentslide}`}>
                        {modulecopy.slides[currentslide].content.map((item, index) => {
                            // console.log("current item:", item);
                            return (
                                <div key={index}>
                                    {item.type === "text" ? (
                                        <p className="w-full">{item.content}</p>
                                    ) : item.type === "image" ? (
                                        <img
                                            src={item.content}
                                            alt="content"
                                            style={{
                                                width: "35vw",
                                                height: "auto",
                                                margin: "0 auto",
                                            }}
                                            className="rounded-lg"
                                        />
                                    ) : item.type === "free-response" ? (
                                        <div
                                            className="flex flex-col rounded-[20px] px-[1vw] py-[1vh] items-center justify-center gap-[2vh] w-fit min-w-[50vw]"
                                            style={{
                                                border: "2px solid #D1D5DB",
                                                margin: "0 auto",
                                                transition: "all 0.3s",
                                            }}
                                        >
                                            {item.content && <p className="w-full">{item?.content}</p>}
                                            <TextareaAutosize
                                                placeholder="Type your answer here"
                                                style={{
                                                    width: "100%",
                                                    resize: "none",
                                                    outline: "none",
                                                    border: "none",
                                                }}
                                                value={answers[currentslide][item.index] || ""}
                                                onChange={(e) =>
                                                    updateAnswers({
                                                        slideindex: currentslide,
                                                        index: item.index,
                                                        answer: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="flex flex-col rounded-[20px] px-[1vw] py-[2vh] items-center justify-center gap-[2vh] w-fit min-w-[50vw]"
                                            style={{
                                                border: "2px solid #D1D5DB",
                                                margin: "0 auto",
                                                transition: "all 0.3s",
                                            }}
                                        >
                                            <p className="w-full text-center">{item?.content}</p>
                                            {item.options.map((option, optionindex) => (
                                                <div
                                                    className="flex flex-row items-center gap-[1vw] cursor-pointer p-[1vh] w-[35%] rounded-lg"
                                                    key={optionindex}
                                                    style={{
                                                        transition: "all 0.3s",
                                                        border:
                                                            answers[currentslide][item.index] === option
                                                                ? "2px solid #5D87FF"
                                                                : "2px solid #D1D5DB",
                                                    }}
                                                    onClick={() =>
                                                        updateAnswers({
                                                            slideindex: currentslide,
                                                            index: item.index,
                                                            answer: option,
                                                        })
                                                    }
                                                >
                                                    {option}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="w-full !flex-row justify-between mt-[2vh]">
                    <p>
                        Slide {currentslide + 1} of {modulecopy.slides.length}
                    </p>
                    <button
                        className="!bg-[green]"
                        onClick={async () => {
                            toastPromise(
                                new Promise(async (resolve, reject) => {
                                    // first check to see if all questions have been answered, and if not, set current slide
                                    // to the first unanswered question, and play an animation on the unanswered question
                                    // using a red border from a css class
                                    let unanswered = { slideindex: -1, index: -1 };
                                    outerLoop: for (const slideindex of Object.keys(answers)) {
                                        for (const [index, answer] of answers[slideindex].entries()) {
                                            if (answer === "") {
                                                unanswered = { slideindex, index };
                                                break outerLoop;
                                            }
                                        }
                                    }

                                    if (unanswered.slideindex !== -1) {
                                        console.log("Unanswered question found:", unanswered);
                                        setCurrentSlide(parseInt(unanswered.slideindex));
                                        setAnswerMissing({
                                            slideindex: unanswered.slideindex,
                                            index: unanswered.index,
                                        });
                                        reject("Please answer all questions before submitting.");
                                        return;
                                    }

                                    const { error } = await supabase
                                        .from("modules")
                                        .update({
                                            answers: [
                                                ...(modulecopy.answers || []),
                                                {
                                                    email: user.email,
                                                    answers: answers,
                                                    completed_at: new Date(),
                                                },
                                            ],
                                        })
                                        .eq("id", modulecopy.id);

                                    if (error) {
                                        console.error("Error updating module:", error);
                                        reject("Error submitting module, please try again later.");
                                        return;
                                    }
                                    navigate("/learn");
                                    resolve();
                                }),
                                "Submitting module...",
                                "Module submitted successfully!"
                            );
                        }}
                    >
                        Submit Module
                    </button>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <button className="flex items-center justify-center !p-0">
                                <IoIosArrowRoundForward color="black" size={26} />
                            </button>
                        }
                        onPageChange={({ selected }) => setCurrentSlide(selected)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        previousLabel={
                            <button className="flex items-center justify-center !p-0">
                                <IoIosArrowRoundBack color="black" size={26} />
                            </button>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        pageCount={modulecopy.slides.length}
                        forcePage={currentslide}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div
            id="moduleparentdiv"
            className="px-[2.5%] !flex-row !items-start w-full justify-between atleast769:mb-[5vh]"
        >
            <div id="modulediv" className="w-full atleast769:w-[65%] gap-y-[2vh] under769:mb-[2vh]">
                <div
                    id="moduletitlediv"
                    style={{ backgroundColor: GlobalStyles.lightblue }}
                    className="w-full p-[2vh] atleast769:p-[2vh] rounded-2xl gap-y-4"
                >
                    <p className="text-4xl font-semibold">{module.title}</p>
                    <iframe
                        title="moduledisplay"
                        src={module.link}
                        width="100%"
                        allowFullScreen={true}
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"
                        style={{ height: "45vh" }}
                    ></iframe>
                </div>
                {/* Reduced the vertical margin around Download and Upload cards */}
                <div className="gap-[1vw] !flex-row w-full">
                    {<DownloadCard module={module.title} moduleId={module.id} />}
                    <button
                        className="bg-[green] text-white rounded-2xl w-1/2 py-2 px-4"
                        onClick={() => navigate("/assignment", { state: { module: module } })}
                    >
                        Start Assignment
                    </button>
                </div>
                {/* <button className="bg-[green] text-white rounded-full py-2 px-4 w-full"
                    onClick={() => navigate('/assignment', { state: { module: state.module } })}
                >
                    Start Assignment
                </button> */}
                <div className="gap-y-4 w-full">{<UploadCard module={module.title} moduleId={module.id} />}</div>
            </div>
            <div id="rightmodulediv" className="w-[30%] gap-y-[5vh] under769:gap-[2vh] under769:w-full">
                <ProductGoal />
                <RecentTasks />
            </div>
        </div>
    );
}
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { supabase } from "../../supabase";
import { GlobalStyles } from "../../GlobalStyles";
import '../../assets/css/connect.css';
import RecentTasks from "./RecentTasks";
import Forum from "./Forum";
import { toastSuccess } from "../../GlobalFunctions";
import SyncLoader from "react-spinners/SyncLoader";

export default function Connect() {
    const { user } = useContext(AuthContext)
    const [posttitle, setPostTitle] = useState('');
    const [posttext, setPostText] = useState('');
    const [mentor, setMentor] = useState({})
    const [othermentors, setOtherMentors] = useState([{}])
    const [careerpath, setCareerPath] = useState('')
    const [uploading, setUploading] = useState(false)

    // console.log("Role is:", user?.role)

    useEffect(() => {
        (async function getData() {
            console.log("email is:", user?.email)
            // get worksite name from student.group (uuid) === studentgroups.uuid && studentgroups.worksite === worksites.id
            // worksites is referenced by studentgroups.worksite
            // studentgroups is referenced by students.group
            // admin is referenced by studentgroups.admin

            // need to get all students who have a mentor, and get their id, name, worksite name, mentor id, mentor name
            // const { data, error } = await supabase.from('students').select("id, name, studentgroups(worksite: worksites (name)), mentor: mentors(id, name)")
                // .neq("mentor", 0)
                // .eq("studentgroups.admin", email)

            if (user?.role === 'Student') {
                const { data, error } = await supabase.from('students')
                    .select("mentors(*)")
                    .eq("email", user?.email)

                if (error) console.error("error", error)
                else console.log("data for mentor:", data)

                setMentor(data[0].mentors)  

                // get career path and mentor id of current student
                const { data: studentdata, error: studenterror } = await supabase
                    .from('students')
                    .select("career_path, mentor")
                    .eq("email", user?.email)

                if (studenterror) console.log("error", studenterror)
                else {
                    console.log("data for student:", studentdata)
                    setCareerPath(studentdata[0].career_path)
                }
                

                console.log("career path is:", studentdata[0].career_path)
                // get up to 4 potential mentors whose mentors.experienced_areas includes student.career_path, and exclude mentor.id === student.mentor
                const { data: othermentordata, error: othermentorerror } = await supabase
                    .from('mentors')
                    .select("name, work_area, experienced_areas, experienced_skills, attributes, email")
                    .contains('experienced_areas', '{' + studentdata[0].career_path + '}')
                    .neq('id', studentdata[0].mentor)
                    .limit(4)

                if (othermentorerror) console.log("error", othermentorerror)
                else console.log("data for other mentors:", othermentordata)

                setOtherMentors(othermentordata)
            }
        })()
    }, [])

    return (
        <div id="connectdiv" className="!flex-row justify-start !items-start gap-x-7 mb-[5vh]">
            <div id="connectinnerdiv" style={{ width: user?.role === 'Administrator' || user?.role === 'Instructor' ? '100%' : '70%' }}
                className=""
            >
                <div id="connectinnerdiv2" className="w-full p-4 mb-[2vh] customshadow rounded-lg gap-[1vh]">
                    <input className="w-full text-base p-2" placeholder="|   Title" value={posttitle}
                        onChange={(e) => setPostTitle(e.target.value)}
                    />
                    <textarea className="w-full text-base p-2" placeholder="|    Compose new post" value={posttext}
                        onChange={(e) => setPostText(e.target.value)}>
                    </textarea>
                    <button className="btn btn-primary !px-8 font-medium self-end mt-6" style={{ backgroundColor: GlobalStyles.blue }}
                        disabled={uploading}
                        onClick={async() => {
                            setUploading(true)
                            const { error } = await supabase.from("forum")
                                .insert({ user_pfp: user.imgurl, user_name: user?.name, title: posttitle, body: posttext, studentgroup: user.group })
                            if (error) console.error(error)
                            else {
                                toastSuccess("Post created!")
                            }
                            setUploading(false)
                            // reset post title and text
                            setPostTitle('')
                            setPostText('')
                        }}
                    >
                        {uploading ?
                            <SyncLoader color="white" loading={true} size={10} />
                        :
                            "Post"
                        }
                    </button>
                </div>
                {(user?.role !== 'Administrator' && user?.role !== 'Instructor') &&
                    <div id="mentordetails" className="!flex-row gap-[2vw] text-start">
                        <div className="gap-y-6 my-[3vh]">
                            {/* Left Side of Mentor Div */}
                            {/* Fully Capitalized Name */}
                            <h1 className="text-3xl font-extrabold self-center text-center" style={{ textTransform: 'uppercase' }}>
                                {mentor.name && mentor.name.split(' ').map((word, index) => (
                                    <span key={index}>{word} {index === 0 && <br />}</span>
                                ))}
                            </h1>
                            {/* Job Role */}
                            <p className="text-xl font-semibold self-center" style={{ textTransform: 'uppercase' }}>{mentor.jobrole}</p>
                            {/* Mentor Pic */}
                            <img src={mentor.imgurl} alt="Mentor Pic" className="w-2/4 rounded-[50%] box-border self-center max-w-[60vw]"/>
                            {/* Connect Button */}
                            <button className="btn btn-primary self-center !px-6 font-bold" style={{ backgroundColor: GlobalStyles.blue }}>Connect</button>
                        </div>
                
                        <div className="bg-[#9bb4ea] rounded-lg">
                            {/* Right Side of Mentor Div */}
                            <div id="mentorgrid" className="!flex-row flex-wrap p-5 gap-y-8">
                                {/* Mentor Bio */}
                                <div className="w-1/2">
                                    <p className="text-xl text-left" style={{ textTransform: 'uppercase' }}>BIO</p>
                                    <p className="text-left">{mentor.bio}</p>
                                </div>
                                {/* Mentor Skills */}
                                {mentor.skills &&
                                    <div className="w-1/2">
                                        <p className="text-xl">SKILLS</p>
                                        {Object.keys(mentor.skills).map((key, index) => (
                                            <div className="w-full py-2 gap-1" key={index}>
                                                <p className="self-start font-semibold">{key}</p>
                                                <div className="bg-black h-3 w-full rounded-full !items-start">
                                                    <div style={{ width: `${mentor.skills[key]}%` }} className={`h-3 bg-white rounded-full`}></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                {/* Mentor Motivations */}
                                <div className="w-1/2">
                                    <p className="text-xl font-semibold text-left">MOTIVATIONS</p>
                                    <p className="text-left">{mentor.motivations}</p>
                                </div>
                                {/* Mentor Goals */}
                                <div className="w-1/2">
                                    <p className="text-xl font-semibold">GOALS</p>
                                    <p>{mentor.goals}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Forum />
            </div>
            {(user?.role !== 'Administrator' && user?.role !== 'Instructor') && 
                <div id="connectrightside" className="!flex-col !items-start !justify-between !w-[30%]">
                    <RecentTasks width={"100%"} />
                    {/* div that will list other mentors */}
                    <div id="othermentors" className="!flex-col gap-y-14 flex-wrap my-12 !justify-between">
                        {othermentors && othermentors.map((mentor, index) => {
                            return (
                                <div key={index} className="gap-y-7 p-7 w-full customshadow rounded-lg">
                                    <div className="!self-start">
                                        {/* Name */}
                                        <p className="text-lg font-semibold !self-start">{mentor.name}</p>
                                        {/* Relation */}
                                        <p className="text-sm font-normal self-start text-start" style={{ color: GlobalStyles.lightgray }}>
                                            Works in {mentor.work_area}, experienced with {careerpath} 
                                        </p>
                                    </div>
                                    {/* Mentor Experienced Skills */}
                                    <div className="gap-y-8">
                                        {mentor.experienced_skills && mentor.experienced_skills[0] && (
                                            <div className="" key={index}>
                                                <p className="text-base !self-start font-semibold text-left">{mentor.experienced_skills[0]}</p>
                                                <p className="text-sm !self-start font-normal text-left" style={{ color: GlobalStyles.lightgray }}>
                                                    {/* add two things from experienced_skills */}
                                                    This mentor has experience in {mentor.experienced_skills[0]} {mentor.experienced_skills[1] && `and ${mentor.experienced_skills[1]}`}
                                                </p>
                                            </div>
                                        )}
                                        <div className="!self-start">
                                            {mentor.attributes && mentor.attributes[0] && (
                                                <div key={index}>
                                                    <p className="text-base !self-start font-semibold text-left">{mentor.attributes[0]}</p>
                                                    <p className="text-sm !self-start font-normal text-left" style={{ color: GlobalStyles.lightgray }}>
                                                        {mentor.attributes[0] === "Community Oriented" && "Works in your community"}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* Connect Button */}
                                    <a className={`font-semibold w-full connectbutton text-center`}
                                        href={`mailto:${mentor.email}`}
                                    >
                                        Connect
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}
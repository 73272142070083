import { useContext, useEffect, useState } from "react";
import "../../assets/css/header.css";
import UploadImagePopup from "./UploadImagePopup";
import { Popover } from "@mui/material";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";
import { Link } from "react-router-dom";

export default function Header({ sidetabvisible, setTabVisible }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { todo, setTodo, user, setUser } = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openpopover = Boolean(anchorEl);
    const id = openpopover ? "simple-popover" : undefined;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        (async () => {
            // add undone surveys, connected to studentgroup that student is currently in to "To Do"
            if (user?.role === "Student") {
                const { data, error } = await supabase
                    .from("students")
                    .select(`group(admin (surveys(*)), instructor(surveys(*)))`)
                    .eq("email", user?.email)
                    .single();

                if (error) {
                    console.error(error.message);
                } else {
                    // console.log("header data:", data);
                    const allSurveys = [
                        ...(data.group?.admin?.surveys || []),
                        ...(data.group?.instructor?.surveys || []),
                    ];
                    const uniqueTodo = allSurveys?.reduce(
                        (acc, survey) => {
                            if (!survey.completed_by?.includes(user?.email) && !acc.ids.has(survey.id)) {
                                acc.ids.add(survey.id);
                                acc.surveys.push(survey);
                            }
                            return acc;
                        },
                        { ids: new Set(), surveys: [] }
                    ).surveys;

                    // console.log("todoq", uniqueTodo);
                    setTodo(uniqueTodo);
                }
            }
        })();
    }, []);

    return (
        <header className="mb-4 w-full">
            <div className="frame-11">
                <div className="frame-13">
                    <div className="frame-12">
                        <button id="sidebarbutton" className="" onClick={() => setTabVisible(!sidetabvisible)}>
                            <svg
                                preserveAspectRatio="xMidYMid meet"
                                className="menu-fill0-wght300-grad0-opsz24-2 icon-instance-node"
                                fill="none"
                                viewBox="0 0 24 24"
                                style={{ width: "100%" }}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    className="path"
                                    d="M3.25 17.625V16.125H20.75V17.625H3.25ZM3.25 12.75V11.25H20.75V12.75H3.25ZM3.25 7.875V6.375H20.75V7.875H3.25Z"
                                    fill="#5A6A85"
                                ></path>
                            </svg>
                        </button>
                        <div className="!flex-col items-center justify-center">
                            <button id="notibutton" className="p-0" onClick={handleClick}>
                                <svg
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    style={{ width: "100%" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4.25 18.875V17.375H6.25V10.125C6.25 8.75838 6.671 7.55405 7.513 6.51205C8.35433 5.47072 9.43333 4.80005 10.75 4.50005V3.80005C10.75 3.45005 10.871 3.15405 11.113 2.91205C11.3543 2.67072 11.65 2.55005 12 2.55005C12.35 2.55005 12.6457 2.67072 12.887 2.91205C13.129 3.15405 13.25 3.45005 13.25 3.80005V4.50005C14.5667 4.80005 15.646 5.47072 16.488 6.51205C17.3293 7.55405 17.75 8.75838 17.75 10.125V17.375H19.75V18.875H4.25ZM12 21.8C11.5 21.8 11.075 21.625 10.725 21.275C10.375 20.925 10.2 20.5 10.2 20H13.8C13.8 20.5 13.625 20.925 13.275 21.275C12.925 21.625 12.5 21.8 12 21.8ZM7.75 17.375H16.25V10.125C16.25 8.94172 15.8373 7.93738 15.012 7.11205C14.1873 6.28738 13.1833 5.87505 12 5.87505C10.8167 5.87505 9.81267 6.28738 8.988 7.11205C8.16267 7.93738 7.75 8.94172 7.75 10.125V17.375Z"
                                        fill="#5A6A85"
                                    />
                                    {todo.length > 0 && <circle cx="20" cy="4" r="3.5" fill="#5D87FF" stroke="white" />}
                                </svg>
                            </button>
                            <Popover
                                className=""
                                id={id}
                                open={openpopover}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                classes={{ paper: "popoverPaper" }} // Add this line to target the popover with a custom class
                            >
                                <div className="todo-header">To Do</div> {/* Changed className for custom styling */}
                                <div className="todo-list gap-[2vh]">
                                    {" "}
                                    {/* Added wrapping div for styling purposes */}
                                    {todo.map((survey) => (
                                        <Link
                                            to={`/survey/${survey.id}`}
                                            key={survey.id}
                                            className="todo-item"
                                            onClick={handleClose}
                                        >
                                            {survey.title}
                                        </Link>
                                    ))}
                                </div>
                            </Popover>
                        </div>
                        {/* <button>
                            <svg className="search icon-instance-node" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path className="path" d="M19.55 20.575L13.25 14.3C12.75 14.7167 12.175 15.0417 11.525 15.275C10.875 15.5083 10.2083 15.625 9.525 15.625C7.80833 15.625 6.35833 15.0333 5.175 13.85C3.99167 12.6667 3.4 11.2167 3.4 9.5C3.4 7.8 3.99167 6.354 5.175 5.162C6.35833 3.97067 7.80833 3.375 9.525 3.375C11.225 3.375 12.6667 3.96667 13.85 5.15C15.0333 6.33333 15.625 7.78333 15.625 9.5C15.625 10.2167 15.5083 10.9 15.275 11.55C15.0417 12.2 14.725 12.7667 14.325 13.25L20.6 19.525L19.55 20.575ZM9.525 14.125C10.8083 14.125 11.896 13.675 12.788 12.775C13.6793 11.875 14.125 10.7833 14.125 9.5C14.125 8.21667 13.6793 7.125 12.788 6.225C11.896 5.325 10.8083 4.875 9.525 4.875C8.225 4.875 7.12933 5.325 6.238 6.225C5.346 7.125 4.9 8.21667 4.9 9.5C4.9 10.7833 5.346 11.875 6.238 12.775C7.12933 13.675 8.225 14.125 9.525 14.125Z" fill="#5A6A85"></path></svg>
                        </button> */}
                    </div>
                    <button id="headerimage" onClick={() => setOpen(true)}>
                        <img
                            src={`${user?.imgurl}?${new Date().getTime()}`}
                            alt="profile"
                            className="profile-icon profile-icon-instance customshadow rounded-full"
                        />
                    </button>
                </div>
            </div>
            <UploadImagePopup
                open={open}
                setOpen={setOpen}
                user={user}
                setUser={setUser}
                sidetabvisible={sidetabvisible}
            />
        </header>
    );
}

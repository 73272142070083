import { useMediaQuery } from "react-responsive";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { ReactComponent as SmallArrowIcon } from "../../assets/icons/smallarrow.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../App";
import { supabase } from "../../supabase";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import Select from "react-select";
import "../../assets/css/surveytable.css";

export default function ModuleTable() {
  const { moduleid } = useParams();
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(
    JSON.parse(sessionStorage.getItem("students_for_assignment")) || []
  );
  const [isStudentRotated, setStudentRotated] = useState(false);
  const [isMentorRotated, setMentorRotated] = useState(false);
  const [isWorksiteRotated, setWorksiteRotated] = useState(false);
  const [searchquery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  const isover640 = useMediaQuery({ query: "(min-width: 641px)" });
  const [assignmentOptions, setAssignmentOptions] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(
    JSON.parse(sessionStorage.getItem("selectedAssignment")) || null
  );
  const [module, setModule] = useState(null);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;
  const pageCount = Math.ceil(filteredStudents.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredStudents.slice(itemOffset, endOffset);

  const handleSortClick = (type) => {
    const isRotated = type === "Name" ? isStudentRotated : type === "Mentor" ? isMentorRotated : isWorksiteRotated;
    const setRotated = type === "Name" ? setStudentRotated : type === "Mentor" ? setMentorRotated : setWorksiteRotated;

    setRotated((prevState) => !prevState);
    if (isRotated) {
      setStudents(
        [...students].sort((a, b) => {
          if (a[type] === "N/A" || !a[type]) return 1;
          if (b[type] === "N/A" || !b[type]) return -1;
          return a[type].localeCompare(b[type]);
        })
      );
    } else {
      setStudents(
        [...students].sort((a, b) => {
          if (a[type] === "N/A" || !a[type]) return 1;
          if (b[type] === "N/A" || !b[type]) return -1;
          return b[type].localeCompare(a[type]);
        })
      );
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredStudents.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  useEffect(() => {
    (async () => {
      const { data: studentdata, error: studenterror } = await supabase.from("students").select(
        `id, name, mentor: mentors(id, name), studentgroups(worksite: worksites (name), admin: administrators(*),
        instructor: instructors!studentgroups_instructor_fkey(*)), linked_modules, email`
      );

      if (studenterror) console.log("error", studenterror);
      else console.log("student data for admin:", studentdata);

      const { data: moduledata, error: moduleerror } = await supabase
        .from("modules")
        .select("*")
        .eq("id", moduleid)
        .single();

      if (moduleerror) console.error("error", moduleerror);
      else {
        console.log("module data for admin:", moduledata);
        setModule(moduledata);

        if (
          JSON.parse(sessionStorage.getItem("selectedAssignment")) &&
          !moduledata.assignments.find(
            (val) => val.title === JSON.parse(sessionStorage.getItem("selectedAssignment")).label
          )
        ) {
          setSelectedAssignment(null);
          sessionStorage.removeItem("selectedAssignment");
        }
        setAssignmentOptions(moduledata.assignments || []);
        if (user.role === "Instructor") {
          setStudents(studentdata.filter((val) => val.studentgroups?.instructor?.email === user.email));
        } else if (user.role === "Administator") {
          setStudents(studentdata.filter((val) => val.studentgroups?.admin?.email === user.email));
        }
      }

      if (filteredStudents.length !== 0) {
        const assignment_answers = moduledata.assignments[0]?.answers || {};
        const students_for_assignment = Object.keys(moduledata.assignments[0]?.answers || {}).map((email, idx) => {
          const student = studentdata.find((val) => val.email === email);
          return {
            Name: student?.name || email,
            ID: student?.id,
            Results: assignment_answers[email],
            Completed: assignment_answers[email].date,
          };
        });
        setFilteredStudents(students_for_assignment);
        sessionStorage.setItem("students_for_assignment", JSON.stringify(students_for_assignment));
      }
    })();
  }, []);

  useEffect(() => {
    const assignment_answers = module?.assignments[selectedAssignment?.value]?.answers || {};
    console.log("assignment answers:", assignment_answers);
    const students_for_assignment = Object.keys(module?.assignments[selectedAssignment?.value]?.answers || {}).map(
      (email, idx) => {
        const student = students.find((val) => val.email === email);
        return {
          Name: student?.name || email,
          ID: student?.id,
          Results: assignment_answers[email],
          Completed: assignment_answers[email].date,
        };
      }
    );
    setFilteredStudents(students_for_assignment);
    sessionStorage.setItem("students_for_assignment", JSON.stringify(students_for_assignment));
  }, [selectedAssignment]);

  useEffect(() => {
    console.log("filtered students:", filteredStudents);
  }, [filteredStudents]);

  return (
    <div id="moduletablediv" className="w-full">
      <div className="studentlisttitle !flex-row !justify-between items-center w-full flex under769:!flex-col under769:gap-[2vh]">
        <p id="matchestitle">Response List</p>
        {/* Search Bar */}
        <div id="matchsearch" className="flex !flex-row gap-2 items-center w-[20vw] under769:w-full">
          <SearchIcon />
          <input
            type="text"
            placeholder="Quick Search..."
            className="w-full"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="w-full bg-[#D5D9DD] h-[1.5px] !my-5"></div>
      <div className="overflow-x-auto w-full gap-3">
        <Select
          id="assignmentselect"
          name="assignmentselect"
          options={assignmentOptions.map((val, i) => ({ value: i, label: val.title })) || []}
          value={selectedAssignment}
          onChange={(val) => {
            setSelectedAssignment(val);
            sessionStorage.setItem("selectedAssignment", JSON.stringify(val));
          }}
          placeholder="Select Assignment"
          styles={{
            container: (provided) => ({
              ...provided,
              width: "35%",
              alignSelf: "flex-start",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              marginTop: "0px",
              marginLeft: "0.5vw",
            }),
            control: (provided) => ({
              ...provided,
              boxShadow: "none",
              width: "100%",
            }),
            input: (provided) => ({
              ...provided,
              color: "black",
              fontSize: "1rem",
            }),
            menuList: (provided) => ({
              ...provided,
              paddingInline: "0.25vw",
            }),
          }}
        />
        <Table className="w-full">
          <Thead>
            <Tr id="matchhead" className="">
              <Th className="">
                <div className="flex justify-start">
                  <p>Name</p>
                  {isover640 && (
                    <button className="ml-4">
                      <SmallArrowIcon
                        width={11}
                        height={11}
                        className={isStudentRotated ? "rotate" : ""}
                        onClick={() => handleSortClick("Name")}
                      />
                    </button>
                  )}
                </div>
              </Th>
              <Th>Results</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentItems &&
            currentItems
              // Filter students by search query only, regardless of date
              .filter((val) => searchquery === "" || val["Name"].toLowerCase().includes(searchquery.toLowerCase()))
              .length > 0 ? (
              currentItems
                .filter((val) => searchquery === "" || val["Name"].toLowerCase().includes(searchquery.toLowerCase()))
                .map((val, idx) => {
                  return (
                    <Tr key={idx}>
                      <Td>{val["Name"]}</Td>
                      <Td>
                        <Link
                          to={`/viewmoduleassignment/${moduleid}/${selectedAssignment?.value}/${val.ID}`}
                          state={{ module, student: val, assignmentid: selectedAssignment?.value }}
                          className=" text-blue-700 underline viewresults"
                        >
                          View Results
                        </Link>
                      </Td>
                    </Tr>
                  );
                })
            ) : (
              <Tr>
                <Td colSpan={8} className="text-center w-full text-xl font-semibold">
                  No students found
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <button className="flex items-center justify-center">
            <IoIosArrowRoundForward color="black" size={26} />
          </button>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        previousLabel={
          <button className="flex items-center justify-center">
            <IoIosArrowRoundBack color="black" size={26} />
          </button>
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination !mt-5"
        activeClassName="active"
        renderOnZeroPageCount={null}
        pageCount={pageCount}
      />
    </div>
  );
}
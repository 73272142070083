import { useContext, useEffect, useState } from "react"
import { GlobalStyles } from "../../GlobalStyles"
import { supabase } from "../../supabase"
import { AuthContext } from "../../App"

export default function RecentTasks({ width }) {
    const { user } = useContext(AuthContext);
    const [tasks, setTasks] = useState([{}])

    useEffect(() => {
        (async () => {
            const { data: taskdata, error: taskerror } = await supabase.from('tasks').select("name, points, type")
            .eq("user", user?.email)
            .order("created_at", { ascending: false })
            .limit(5)

            if (taskerror) console.log("error", taskerror)
            else console.log("data for tasks:", taskdata)

            setTasks(taskdata)
        })()
    }, [])
    
    return (
        <div id="recenttasks" className="p-7 customshadow rounded-2xl text-start gap-[1vh]" style={{ width: width }}>
            <p className="font-semibold text-lg self-start">Tasks</p>
            <p className="font-normal text-sm self-start">Tasks that you will complete</p>
            {tasks && tasks.map((task, index) => {
                return (
                    <div className="!flex-row justify-between !items-center py-4 w-full" key={index}>
                        <div className="self-start">
                            <p className="font-semibold text-sm self-start">{task.name}</p>
                            <p className="text-sm font-normal self-start">{task.type}</p>
                        </div>
                        <p className="font-semibold text-sm">+{task.points && task.points.toLocaleString()}</p>
                    </div>
                )
            })}
            <button style={{ border: `1px solid ${GlobalStyles.blue}`, color: GlobalStyles.blue }} className={`bg-white font-semibold w-full`}>
                View all tasks
            </button>
        </div>
    )
}
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { supabase } from "../../supabase";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";

export default function StudentPortfolio() {
  const { studentId, studentEmail } = useParams();
  console.log(`Student ID: ${studentId}, Student Email: ${studentEmail}`);
  
  const [modules, setModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;
  const pageCount = Math.ceil(filteredModules.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredModules.slice(itemOffset, endOffset);

  useEffect(() => {
    const fetchModules = async () => {
      const { data: moduleAnswers, error: moduleError } = await supabase
        .from("modules")
        .select("id, title, assignments, answers");

      if (moduleError) {
        console.error("Error fetching module answers:", moduleError);
        return;
      }

      const studentModules = moduleAnswers.filter((module) =>
        (module.assignments && Object.keys(module.assignments).some((assignment) =>
          module.assignments[assignment]?.answers && Object.keys(module.assignments[assignment].answers).includes(studentEmail)
        )) || (module.answers && module.answers.some(answer => answer.email === studentEmail && module.answers))
      );

      setModules(studentModules);
      setFilteredModules(studentModules);
    };

    const fetchSurveys = async () => {
      const { data: surveyData, error: surveyError } = await supabase
        .from("surveys")
        .select("*");

      if (surveyError) {
        console.error("Error fetching survey data:", surveyError);
        return;
      }

      const studentSurveys = surveyData.filter((survey) =>
        survey.responses.some((response) => response.studentemail === studentEmail)
      );

      setSurveys(studentSurveys);
    };

    fetchModules();
    fetchSurveys();
  }, [studentEmail]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredModules.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="w-full">
      <h2 className="text-xl font-bold">Modules for {studentEmail}</h2>
      <Table className="w-full mt-4">
        <Thead>
          <Tr>
            <Th>Module Title</Th>
            <Th>View Results</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((module, idx) => (
            <Tr key={idx}>
              <Td>{module.title}</Td>
              <Td>
                {module.assignments && Object.keys(module.assignments).map((assignmentid) => {
                  const assignment = module.assignments[assignmentid];
                  const answers = assignment?.answers?.[studentEmail];
                  if (answers) {
                    console.log("View Module Assignment Link Details:", {
                      moduleId: module.id,
                      assignmentId: assignmentid,
                      studentId,
                      studentEmail,
                      results: answers,
                      completed: answers.date,
                    });
                    return (
                      <Link
                        key={assignmentid}
                        to={`/viewmoduleassignment/${module.id}/${assignmentid}/${studentId}`}
                        state={{
                          module,
                          student: {
                            Name: studentEmail,
                            ID: studentId,
                            Results: answers,
                            Completed: answers.date,
                          },
                          assignmentid,
                        }}
                        className="text-blue-700 underline viewresults"
                      >
                        View Results
                      </Link>
                    );
                  }
                  return null;
                })}
                {module.answers && module.answers.some(answer => answer.email === studentEmail) ? (
                  <Link
                    to={`/moduleanswers/${module.id}/${studentId}`}
                    state={{ module, student: { 
                      Name: studentEmail, 
                      ID: studentId, 
                      Answers: module.answers.find(answer => answer.email === studentEmail).answers 
                    }}}
                    className="text-blue-700 underline viewresults"
                  >
                    View Answers
                  </Link>
                ) : null}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <h2 className="text-xl font-bold mt-8">Surveys for {studentEmail}</h2>
      <Table className="w-full mt-4">
        <Thead>
          <Tr>
            <Th>Survey Title</Th>
            <Th>View Answers</Th>
          </Tr>
        </Thead>
        <Tbody>
          {surveys.map((survey, idx) => (
            <Tr key={idx}>
              <Td>{survey.title}</Td>
              <Td>
                <Link
                  to={`/viewsurvey/${survey.id}/${studentId}`}
                  state={{ survey, student: { 
                    Name: studentEmail, 
                    ID: studentId, 
                    Answers: survey.responses.find(response => response.studentemail === studentEmail)?.answers 
                  }}}
                  className="text-blue-700 underline viewresults"
                >
                  View Answers
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <button className="flex items-center justify-center">
            <IoIosArrowRoundForward color="black" size={26} />
          </button>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        previousLabel={
          <button className="flex items-center justify-center">
            <IoIosArrowRoundBack color="black" size={26} />
          </button>
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination !mt-5"
        activeClassName="active"
        renderOnZeroPageCount={null}
        pageCount={pageCount}
      />
    </div>
  );
}
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function CompletionProgress({ completiondata }) {
    return (
        <div className="flex flex-col items-center justify-center w-full gap-y-2 text-start">
            <p className="font-bold self-start">Completion Progess</p>
            {completiondata.length !== 0 ? (
                completiondata.map((val, index) => (
                    <div key={index} className="flex justify-between h-[10vh] w-full items-center">
                        <div>
                            <p className="font-bold">{val.title}</p>
                            <p>{val.sub}</p>
                        </div>
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={val.percent}
                            duration={1.4}
                            easingFunction={window.d3.easeQuadInOut}
                        >
                            {(value) => {
                                const roundedValue = Math.round(value);
                                return (
                                    <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}%`}
                                        styles={buildStyles({ pathTransition: "none" })}
                                    />
                                );
                            }}
                        </AnimatedProgressProvider>
                    </div>
                ))
            ) : (
                <div className="flex justify-start items-center h-[10vh] w-full">
                    <p>No stats added!</p>
                </div>
            )}
        </div>
    );
}

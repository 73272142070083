import { useState, useContext } from 'react';
import { toastError, toastSuccess } from '../GlobalFunctions';
import { supabase } from '../supabase';
import { AuthContext } from '../App';
import InstructorUploadCard from '../components/instructor/InstructorUploadCard'; // Import the new component
import '../assets/css/dailyreport.css';

export default function DailyReport() {
  const [report, setReport] = useState({
    dateOfLesson: '',
    whatHappened: '',
    whatWentWell: '',
    whatDidntGoWell: '',
    feedbackToAdmins: ''
  });

  const { user } = useContext(AuthContext);

  const handleReportSubmit = async (e) => {
    e.preventDefault();

    const newReport = { ...report, date: new Date().toISOString() };

    // Fetch the current reports
    const { data: instructorData, error: fetchError } = await supabase
      .from('instructors')
      .select('reports')
      .eq('email', user.email)
      .single();

    if (fetchError) {
      toastError('Failed to fetch current reports');
      console.error('Error fetching reports:', fetchError);
      return;
    }

    const updatedReports = instructorData.reports ? [...instructorData.reports, newReport] : [newReport];

    // Update the reports column
    const { error: updateError } = await supabase
      .from('instructors')
      .update({ reports: updatedReports })
      .eq('email', user.email);

    if (updateError) {
      toastError('Failed to submit report');
      console.error('Error submitting report:', updateError);
    } else {
      toastSuccess('Report submitted successfully');
      setReport({
        dateOfLesson: '',
        whatHappened: '',
        whatWentWell: '',
        whatDidntGoWell: '',
        feedbackFromStudents: ''
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReport((prevReport) => ({
      ...prevReport,
      [name]: value
    }));
  };

  return (
    <div className="daily-report-container">
      <h1>Submit Daily Report</h1>
      <form onSubmit={handleReportSubmit}>
        <input
          type="date"
          name="dateOfLesson"
          value={report.dateOfLesson}
          onChange={handleChange}
          placeholder="Date of Lesson"
          required
        />
        <textarea
          name="whatHappened"
          value={report.whatHappened}
          onChange={handleChange}
          placeholder="What happened during the lesson"
          required
        />
        <textarea
          name="whatWentWell"
          value={report.whatWentWell}
          onChange={handleChange}
          placeholder="What went well"
          required
        />
        <textarea
          name="whatDidntGoWell"
          value={report.whatDidntGoWell}
          onChange={handleChange}
          placeholder="What didn't go well"
          required
        />
        <textarea
          name="feedbackToAdmins"
          value={report.feedbackToAdmins}
          onChange={handleChange}
          placeholder="Feedback to administrators"
          required
        />
        <button type="submit">Submit Report</button>
      </form>
      <InstructorUploadCard /> 
    </div>
  );
}
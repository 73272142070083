import { ReactComponent as WelcomeIcon } from '../../icons/welcome.svg';
import timeline from '../../icons/timeline.png';
import { useEffect, useState, useContext } from 'react';
import ProductGoal from '../global/ProductGoal.jsx';
import Stopwatch from '../student/Stopwatch.jsx';
import '../../App.css';
import "../../assets/css/home.css";
import { supabase } from '../../supabase.js';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../App.js';
import StudentUploadCard from '../student/StudentUploadCard.jsx';
import { GlobalStyles } from "../../GlobalStyles.js";

export default function StudentHome({ email, name }) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const getStudentId = async (email) => {
    const { data, error } = await supabase
      .from('students')
      .select('id')
      .eq('email', email)
      .single();

    if (error) {
      console.error("Error fetching student ID:", error);
      return null;
    }

    return data.id;
  };

  const handlePortfolioClick = async () => {
    const studentId = await getStudentId(user.email);
    if (studentId) {
      console.log(`Navigating to student portfolio with ID: ${studentId}, Email: ${user.email}`);
      navigate(`/studentportfolio/${studentId}/${user.email}`);
    }
  };

  const [channel, setChannel] = useState(null);
  const [passedpic, setPassedPic] = useState(null);
  
  useEffect(() => {
    const channelA = supabase.channel(user.email);

    // Subscribe to the Channel
    channelA.on('broadcast', { event: 'test' }, (response) => {
      console.log(response);
      setPassedPic(response.payload.message)
    }).subscribe();

    // Set the channel in state for potential use elsewhere in the component
    setChannel(channelA);

    // Clean up the channel when the component unmounts
    return () => {
      if (channelA) {
        channelA.unsubscribe();
      }
    };
  }, [user.email]);

  return (
    <div id='studenthomediv' className="App flex flex-row">
      {/* Main Content Section */}
      {/* <div className='onesignal-customlink-container'></div> */}
      <div id='' className='flex w-[72vw] mt-[0.25rem] gap-[1.5vw] maincontent'>
        <div id='homeleft' className='w-[70%]'>  
          <div className="WelcomeMessage bg-[#EBF3FE] rounded-xl p-[2.19rem] text-star !relative">
            <div id="welcomesvg" className='absolute right-[2.94rem] top-[3.12rem]'>
              <WelcomeIcon />
            </div>
            <div className="Frame1000002766 relative z-[999]" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex'}}>
              <div className="WelcomeBackNataliaCheckDashboard text-start" style={{alignSelf: 'stretch', color: '#2A3547', fontSize: 21, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>
                Welcome back {user.name}!<br/>Check dashboard
              </div>
              <div className="YouWillSeeTheWorkYouShouldCompleteHere" style={{ color: '#7C8FAC', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>Your next steps that you should complete show up here</div>
              <div className="WeSuggestionExploringOurLearningModules!" style={{color: '#2A3547', fontSize: 21, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>We suggest exploring learning modules!</div>
              <Link to="/learn" className="Medium bg-[#5D87FF] py-2 px-4 rounded" style={{color: 'white', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '600'}}>
                Start
              </Link>
              <button onClick={handlePortfolioClick} className="Medium bg-[#5D87FF] py-2 px-4 rounded mt-4" style={{color: 'white', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '600'}}>
                View Portfolio
              </button>
            </div>
          </div>
          <img src={timeline} alt="Timeline" style={{ width: '120%' }} />
        </div>
        
        <div id='homeright' className='w-[30%]'>
          <ProductGoal />
        </div>
      </div>
      {passedpic && (
        <div id='messagereceiver'>
          <img src={passedpic} alt='passedpic'/>
        </div>
      )}
    </div>
  );
}
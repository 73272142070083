import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import { ReactComponent as Ecommerce } from "../../icons/ecommerce.svg";
import "../../assets/css/sidebar.css";
import { GlobalStyles } from "../../GlobalStyles";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "react-responsive";

export default function Sidebar({ sidetabvisible, setTabVisible, role }) {
    const { logout, curpath, setCurPath } = useContext(AuthContext);
    // const othertablist = ["Avatar", "Basic"]
    const location = useLocation();
    // console.log("curpath", curpath);
    const [currenttab, setCurrentTab] = useState(curpath);
    const isunder769 = useMediaQuery({ query: "(max-width: 769px)" });
    let dashtablist = [
        "Home",
        role === "Administrator" ? "Logins" : "Learn",
        role !== "Administrator" && "Connect",
        role === "Administrator" ? "Surveys" : "Opportunities",
        role === "Administrator" && "Modules",
        role === "Administrator" && "Matching",
        role === "Administrator" && "Mentor Matching",
        (role === "Administrator" || role === "Instructor") && "Create Module",
        role === "Administrator" && "View Daily Reports",
        role === "Instructor" && "Daily Report",
        role === "Instructor" && "Surveys",
        role === "Instructor" && "Modules",
        "Settings",
    ].filter(Boolean);
    let apptablist = [role === "Administrator" ? "Feedback" : "Chat", "Profile", "Analytics"];
    if (role === "Administrator") {
        apptablist.splice(1, 0, "Users");
    }
    const navigate = useNavigate();

    useEffect(() => {
        let path =
            location.pathname !== "/" && location.pathname !== "signin"
                ? location.pathname.substring(1)[0].toUpperCase() + location.pathname.substring(2)
                : "Home";
        // console.log("path:", path)
        setCurPath(path);
        setCurrentTab(path);
    }, []);

    useEffect(() => {
        if (currenttab !== "") {
            console.log("currenttab changed to:", currenttab);
        }
    }, [currenttab]);

    return (
        <Drawer
            sx={{
                width: isunder769 ? "40vw" : "25vw",
                flexShrink: 0,
                // for inside drawer styling
                "& .MuiDrawer-paper": {
                    width: isunder769 ? "40vw" : "25vw",
                    boxSizing: "border-box",
                },
                minHeight: "100vh",
                borderRight: sidetabvisible ? "2px solid rgb(234, 239, 244)" : "none",
            }}
            variant={isunder769 ? "temporary" : "persistent"}
            anchor="left"
            open={sidetabvisible}
            onClose={() => setTabVisible(false)}
        >
            <div className="w-full">
                <img src={require("../../icons/Acewise(1).jpeg")} alt="Acewise" className="z-[999] w-[33%]" />
                <div
                    className="Rectangle2"
                    style={{
                        marginBottom: "2vh",
                        width: "95%",
                        opacity: 0.6,
                        height: ".25vh",
                        background: "rgb(234, 239, 244)",
                    }}
                />
                <div
                    className="Frame20098"
                    style={{
                        width: "90%",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: 20,
                        display: "inline-flex",
                        paddingBottom: "20px",
                    }}
                >
                    {/* Dashboards */}
                    <div className="Frames w-full">
                        <div className="frametitle">DASHBOARDS</div>
                        <div className="framediv">
                            {dashtablist.map((val, index) => (
                                <button
                                    id={`${val.toLowerCase()}tab`}
                                    className="sidetab"
                                    style={{ backgroundColor: currenttab === val && GlobalStyles.blue }}
                                    key={index}
                                    onClick={() => {
                                        setCurrentTab(val);
                                        navigate(`/${val.split(" ").join("").toLowerCase()}`);
                                        if (isunder769) setTabVisible(false);
                                    }}
                                >
                                    <div
                                        className="Ecommerce"
                                        style={{
                                            color: currenttab === val ? "white" : "#5A6A85",
                                            fontWeight: currenttab === val ? "600" : "400",
                                        }}
                                    >
                                        {val}
                                    </div>
                                    {/* </div> */}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Sign Out Button */}
                <button
                    className="Medium bg-[#5D87FF] py-2 px-4 rounded"
                    style={{
                        color: "white",
                        fontSize: "14px",
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: "600",
                        wordWrap: "break-word",
                    }}
                    onClick={async () => await logout()}
                >
                    Sign Out
                </button>
            </div>
        </Drawer>
    );
}

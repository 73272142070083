import { useContext, useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";

export default function Modules() {
    const navigate = useNavigate();
    const [modules, setModules] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            const { data: modules, error: moduleError } = await supabase.from("modules").select("*");
            if (moduleError) {
                console.log("Error fetching modules:", moduleError);
                return;
            }
            // console.log("Fetched modules:", modules);

            const { data: groups, error: groupError } =
                user.role === "Administrator"
                    ? await supabase.from("studentgroups").select("uuid, modules").eq("admin", user.email)
                    : await supabase.from("studentgroups").select("uuid, modules").eq("instructor", user.id);

            if (groupError) {
                console.error("Error fetching student groups:", groupError);
                return;
            }
            // console.log("Fetched student groups:", groups);
            // console.log("modules before update:", [...modules, ...user.modules]);

            const updatedModules = [...modules].map((module) => {
                const isChecked = groups.some((group) => {
                    const groupModules = group.modules ? group.modules.map((id) => id.trim()) : []; // Ensure no extra spaces and handle null
                    // console.log(`Comparing module.id: ${module.id} with group.modules: ${groupModules}`);
                    return groupModules.includes(module.id.toString()); // Ensure same type comparison
                });
                // console.log(`Module ${module.id} is checked: ${isChecked}`);
                return { ...module, checked: isChecked, checked_off: isChecked };
            });

            // console.log("Updated Modules:", updatedModules);
            setModules(updatedModules);
        })();
    }, [user]);

    /**
     * Function to handle checkbox change from module list
     * @param {number} moduleId - The module ID
     * @param {boolean} checked - The checked status of the module
     * @returns {void}
     */
    const handleCheckboxChange = async (moduleId, checked) => {
        // console.log(`handleCheckboxChange called with moduleId: ${moduleId}, checked: ${checked}`);

        // get all modules and their uuids from each student group
        const { data: groups, error: groupError } = await supabase
            .from("studentgroups")
            .select("uuid, modules")
            .eq(
                user.role === "Administrator" ? "admin" : "instructor",
                user.role === "Administrator" ? user.email : user.id
            );

        if (groupError) {
            console.error("Error fetching student groups:", groupError);
            return;
        } else {
            // console.log("Fetched student group:", groups);

            const updatedGroups = groups.map((group) => {
                // if the module is checked,
                let updatedModules = checked
                    ? [...new Set([...(group.modules || []), moduleId])]
                    : (group.modules || []).filter((id) => id !== moduleId.toString());

                // console.log(`Updated modules for group ${group.uuid}:`, updatedModules);
                return {
                    ...group,
                    modules: updatedModules,
                    ...(user.role === "Administrator" && {
                        checked: updatedModules.includes(moduleId.toString()) ? true : false,
                    }),
                };
            });

            for (const group of updatedGroups) {
                if (group.uuid) {
                    // console.log(`Updating group with uuid: ${group.uuid}, modules: ${group.modules}`);
                    const { error: updateError } = await supabase
                        .from("studentgroups")
                        .update({ modules: group.modules })
                        .eq("uuid", group.uuid);
                    if (updateError) {
                        console.error(`Error updating group with uuid: ${group.uuid}`, updateError);
                    }
                } else {
                    console.error("Group UUID is undefined for group:", group);
                }
            }
        }

        // Update the local state to reflect the change
        setModules(modules.map((module) => (module.id === moduleId ? { ...module, checked_off: checked } : module)));
    };

    return (
        <div className="w-full gap-[2vh]">
            <div className="!flex-row !justify-between w-full">
                <p className="text-2xl font-semibold">Modules</p>
            </div>
            <div className="self-start !items-start gap-[3vh]">
                {modules.map((module) => (
                    <div key={module.id}>
                        <div className="gap-[1vh] !items-start !justify-start">
                            <h2 className="font-medium">{module.title}</h2>
                            <h3 className="font-normal">{module.description}</h3>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={module.checked_off}
                                    onChange={(e) => handleCheckboxChange(module.id, e.target.checked)}
                                />
                                Module Added
                            </label>
                            <button
                                style={{ backgroundColor: "#5D87FF", color: "white" }}
                                onClick={() => navigate(`/moduletable/${module.id}`)}
                            >
                                Check Results
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

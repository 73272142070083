import React, { createContext, useState } from "react";
import Routing from "./components/global/Routing";
import "./App.css";
import { supabase } from "./supabase";
import { Toaster } from "react-hot-toast";
import { baseUrl, getTableName, storePassword, toastError, toastSuccess } from "./GlobalFunctions";
import bcrypt from "bcryptjs";

export const AuthContext = createContext();

export default function App() {
    const [user, setUser] = useState(null);
    const [todo, setTodo] = useState([]);
    const [curpath, setCurPath] = useState("");
    const [admin, setAdmin] = useState("");

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                todo,
                setTodo,
                curpath,
                setCurPath,
                admin,
                setAdmin,
                login: async (email, password, route) => {
                    const { data, error } = await supabase.auth.signInWithPassword({
                        email,
                        password,
                    });
                    if (error) {
                        console.error("Error signing in:", error);
                        const db_data = await fetch(`${baseUrl}/getpassword`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ email, password }),
                        });
                        if (db_data.status === 200) {
                            const { message } = await db_data.json();
                            // check if the password put in supabase matches the password in the database
                            if (
                                error.name === "AuthApiError" &&
                                error.message === "Invalid login credentials" &&
                                message === "User found"
                            ) {
                                // if the error says invalid login credentials but the password matches,
                                // reset the user's password in auth
                                console.log("Password matched");
                                const salt = bcrypt.genSaltSync(10);
                                const hashedPassword = bcrypt.hashSync(password, salt);
                                const { error } = await supabase.rpc("change_student_password", {
                                    email,
                                    new_password: hashedPassword,
                                });
                                if (error) {
                                    console.error("error changing password:", error);
                                    toastError("Error, please try again later!");
                                    return error;
                                } else {
                                    console.log("Password changed successfully");
                                    // retry login
                                    const { data, error: sigin_error } = await supabase.auth.signInWithPassword({
                                        email,
                                        password,
                                    });
                                    if (sigin_error) {
                                        console.error("Error signing in:", sigin_error);
                                        toastError(sigin_error.message);
                                        return sigin_error;
                                    } else {
                                        console.log("data:", data);
                                        toastSuccess("Signed in!");
                                    }
                                }
                            } else {
                                console.error("Error signing in:", error);
                                toastError(error.message);
                                return error;
                            }
                        } else {
                            const ret_db_data = await db_data.json();
                            console.error("Error getting password:", db_data, ret_db_data);
                            toastError(ret_db_data.error);
                            return;
                        }
                    } else {
                        console.log("data:", data);
                        toastSuccess("Signed in!");
                    }
                },
                register: async (email, password, uuid, role) => {
                    console.log("email:", email, "\nuuid:", uuid, "\nrole:", role);
                    const { error } = await supabase.auth.signUp({
                        email,
                        password,
                        options: { data: { email, role } },
                    });
                    if (error) {
                        console.error(error);
                        toastError(error.message);
                        return false;
                    } else {
                        if (role === "Student" || role === "Administrator" || role === "Instructor") {
                            const { error: signuperror } = await supabase
                                .from(getTableName(role))
                                .upsert({ email, group: uuid }, { onConflict: "email" });
                            if (signuperror) {
                                console.error("Error adding user to database:", signuperror);
                                toastError("Error adding user to database");
                                return false;
                            } else {
                                // store email and password on mongodb
                                return await storePassword(email, password);
                            }
                        } else {
                            return await storePassword(email, password);
                        }
                    }
                },
                logout: async (isdeleting) => {
                    if (isdeleting) {
                        const { error } = await supabase.auth.signOut();
                        if (error) {
                            console.error("Error signing out:", error);
                            toastError(error.message);
                        } else {
                            const { error } = await supabase.rpc("delete_user", {
                                user_email: user.email,
                            });
                            if (error) {
                                console.error("Error deleting user:", error);
                                toastError(error.message);
                            } else {
                                toastSuccess("Account deleted.");
                                window.location.href = "/";
                            }
                        }
                    } else {
                        await supabase.auth.signOut().then(async (val) => {
                            if (val.error) {
                                toastError(val.error.message);
                            } else {
                                window.location.href = "/";
                                toastSuccess("Logged out.");
                            }
                        });
                    }
                },
            }}
        >
            <Routing />
            <Toaster position="top-center" containerClassName="!flex !flex-row !items-center !justify-center" />
        </AuthContext.Provider>
    );
}

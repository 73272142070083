import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import { toastPromise } from "../../GlobalFunctions";
import { AuthContext } from "../../App";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import { saveAs } from "file-saver";

const generateWordDoc = async (module, assignment, student) => {
  console.log("module.id:", module.id); // Log the module ID

  // Fetch the template from the module
  const { data: moduleData, error } = await supabase
    .from('modules')
    .select('template')
    .eq('id', module.id)
    .single();

  if (error) {
    console.error("Error fetching template:", error);
    return;
  }

  const template = moduleData.template;

  let filledTemplate;
  if (template) {
    // Convert the template to a string if it's an object
    filledTemplate = typeof template === 'string' ? template : JSON.stringify(template);
    // Replace placeholders with actual answers
    let answerIndex = 1;
    Object.entries(student.Results.answers).forEach(([section, answers]) => {
      answers.forEach(answer => {
        const placeholder = `{{answer${answerIndex}}}`;
        console.log(`Replacing ${placeholder} with ${answer}`);
        filledTemplate = filledTemplate.replace(new RegExp(placeholder, 'g'), answer);
        answerIndex++;
      });
    });
  } else {
    // Generate text without template
    filledTemplate = Object.entries(student.Results.answers)
      .map(([section, answers]) => {
        return `${section}:\n${answers.map((answer, index) => `${index + 1}. ${answer}`).join('\n')}`;
      })
      .join('\n\n');
  }

  // Ensure filledTemplate is a string
  if (typeof filledTemplate !== 'string') {
    console.error("filledTemplate is not a string");
    return;
  }

  // Remove JSON brackets and other unwanted characters
  filledTemplate = filledTemplate.replace(/^{.*?"template":"|"}$/g, '').replace(/\\"/g, '"');

  // Replace \n with actual line breaks
  filledTemplate = filledTemplate.replace(/\\n/g, '\n');
  filledTemplate = filledTemplate.replace(/<</g, ' ');

  // Split the filled template by line breaks to create paragraphs
  const paragraphs = filledTemplate.split('\n').map(line => {
    const isBold = line.startsWith('**') && line.endsWith('**');
    const isCentered = line.startsWith('>>') && line.endsWith('>>');
    const isBullet = line.startsWith('--') && line.endsWith('--');
    const isUnderlined = line.startsWith('__') && line.endsWith('__');
    const isItalic = line.startsWith('..') && line.endsWith('..');
    const fontSizeMatch = line.match(/{{size:(\d+)}}(.*?){{\/size}}/);
    const text = isBold ? line.slice(2, -2) :
                 isCentered ? line.slice(2, -2) :
                 isBullet ? line.slice(2, -2) :
                 isUnderlined ? line.slice(2, -2) :
                 isItalic ? line.slice(1, -1) :
                 fontSizeMatch ? fontSizeMatch[2] : line;
    const fontSize = fontSizeMatch ? parseInt(fontSizeMatch[1], 10) : 24;

    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: isBold,
          underline: isUnderlined ? {} : undefined,
          italics: isItalic,
          size: fontSize,
        }),
      ],
      alignment: isCentered ? AlignmentType.CENTER : AlignmentType.LEFT,
      bullet: isBullet ? { level: 0 } : undefined,
    });
  });

  // Create the Word document
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: paragraphs,
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${assignment.title}-${student.Name}.docx`);
  });
};

export default function ViewAssignment() {
  const location = useLocation();
  const { module, student, assignmentid } = location.state || {};

  const [assignment, setAssignment] = useState({});
  const [answers, setAnswers] = useState({});

  console.log("module:", module, "\n\nstudent:", student);
  console.log("assignmentid:", assignmentid);

  return (
    <div className="!self-start ml-[2vw]">
      <div className="!items-start gap-[2vh]">
        <h1 className="text-3xl font-bold" style={{ color: "#686868" }}>
          {module?.assignments?.[assignmentid]?.title}
        </h1>
        {module.assignments[assignmentid].sections.map((section, index) => (
          <div key={index} className=" !items-start gap-[1vh]">
            <p className=" font-bold">{section.title}</p>
            {section.questions.map((question, i) =>
              question.type === "mccheckbox" ? (
                question.options.map((option, i) => (
                  <div key={i} className=" !flex-row justify-center gap-[.375vw]">
                    <input
                      type="checkbox"
                      id={option.id}
                      name={option.text}
                      value={option.text}
                      readOnly
                      checked={answers[question.id].includes(option.text)}
                      disabled={!answers[question.id].includes(option.text) && Object.keys(answers).length > 0}
                    />
                    <label htmlFor={option.id}>{option.text}</label>
                  </div>
                ))
              ) : question.type === "multiplechoice" ? (
                question.options.map((option, i) => (
                  <div key={i} className="!flex-row justify-center gap-[.375vw]">
                    <input
                      type="radio"
                      id={option.id}
                      name={question.text}
                      value={option.text}
                      readOnly
                      checked={answers[question.id] === option.text}
                      disabled={!answers[question.id] && Object.keys(answers).length > 0}
                    />
                    <label htmlFor={option.id}>{option.text}</label>
                  </div>
                ))
              ) : question.type === "freeresponse" ? (
                <div className="!items-start gap-[1vh]" key={i}>
                  <p className="font-semibold text-start">{question.text}</p>
                  <p
                    className="w-full p-2 rounded-lg text-start"
                    style={{ border: "2px solid #686868", boxShadow: "0px 0px 4px #686868" }}
                  >
                    {student?.Results?.answers?.[section.title]?.[i] || "No answer provided"}
                  </p>
                </div>
              ) : question.type === "ratingscale" ? (
                <div className="!flex-row w-full gap-[1vw]" key={i}>
                  {Array.from({ length: 5 }, (_, i) => i + 1).map((rating, i) => {
                    return (
                      <div
                        style={{
                          border: `2px solid ${answers[question.id] !== rating ? "#686868" : "white"}`,
                          backgroundColor: answers[question.id] === rating ? "#138808" : "transparent",
                          color: answers[question.id] === rating ? "white" : "black",
                        }}
                        className="w-1/5 rounded-lg px-[1vw] py-[1vh] font-bold"
                        key={i}
                      >
                        {rating}
                      </div>
                    );
                  })}
                </div>
              ) : null
            )}
          </div>
        ))}
        <button
          className="Medium bg-[#5D87FF] py-2 px-4 rounded mt-4"
          style={{
            color: "white",
            fontSize: 14,
            fontFamily: "Plus Jakarta Sans",
            fontWeight: "600",
          }}
          onClick={() => generateWordDoc(module, module.assignments[assignmentid], student)}
        >
          Download Word Document
        </button>
      </div>
    </div>
  );
}
import { useMediaQuery } from "react-responsive";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { ReactComponent as SmallArrowIcon } from '../../assets/icons/smallarrow.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../App";
import { supabase } from "../../supabase";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import '../../assets/css/surveytable.css';

export default function SurveyTable() {
    const { surveyid } = useParams();
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [isStudentRotated, setStudentRotated] = useState(false);
    const [isMentorRotated, setMentorRotated] = useState(false);
    const [isWorksiteRotated, setWorksiteRotated] = useState(false);
    const [searchquery, setSearchQuery] = useState('');
    const { user } = useContext(AuthContext);
    const isover640 = useMediaQuery({ query: '(min-width: 641px)' });
    
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 8;
    const pageCount = Math.ceil(filteredStudents.length / itemsPerPage);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredStudents.slice(itemOffset, endOffset);

    const handleSortClick = (type) => {
        const isRotated = type === 'Name' ? isStudentRotated : type === 'Mentor' ? isMentorRotated : isWorksiteRotated;
        const setRotated = type === 'Name' ? setStudentRotated : type === 'Mentor' ? setMentorRotated : setWorksiteRotated;
    
        setRotated(prevState => !prevState);
        if (isRotated) {
            setFilteredStudents([...filteredStudents].sort((a, b) => {
                if (a[type] === "N/A" || !a[type]) return 1;
                if (b[type] === "N/A" || !b[type]) return -1;
                return a[type].localeCompare(b[type]);
            }));
        } else {
            setFilteredStudents([...filteredStudents].sort((a, b) => {
                if (a[type] === "N/A" || !a[type]) return 1;
                if (b[type] === "N/A" || !b[type]) return -1;
                return b[type].localeCompare(a[type]);
            }));
        }
    };
    
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredStudents.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    useEffect(() => {
        (async() => {
            console.log("email is:", user?.email);

            let studentdata;
            let studenterror;

            if (user.role === "Administrator") {
                // Fetch all students under the admin
                ({ data: studentdata, error: studenterror } = await supabase.from('students')
                    .select(`id, name, mentor: mentors(id, name), studentgroups(worksite: worksites (name)), linked_modules, email`)
                    .eq("studentgroups.admin", user?.email));
            } else if (user.role === "Instructor") {
                // Fetch students in the instructor's group
                const { data: studentGroups, error: errorStudentGroups } = await supabase.from('studentgroups').select('*')
                    .eq('instructor', user?.id);
                if (errorStudentGroups) {
                    console.error("Error fetching student groups:", errorStudentGroups);
                    return;
                }

                ({ data: studentdata, error: studenterror } = await supabase.from('students')
                    .select(`id, name, mentor: mentors(id, name), studentgroups(worksite: worksites (name)), linked_modules, email`)
                    .in('group', studentGroups.map(sg => sg.uuid)));
            }

            if (studenterror) {
                console.log("error", studenterror);
            } else {
                console.log("student data:", studentdata);

                const { data: surveydata, error: surveyerror } = await supabase.from('surveys')
                    .select("id, title, data, completed_by, responses")
                    .eq(user.role === "Administrator" ? "admin" : "instructor", user?.email)
                    .eq('id', surveyid)
                    .single();

                if (surveyerror) {
                    console.error("error", surveyerror);
                    if (surveyerror.details === "The result contains 0 rows" && user.role === "Instructor") {
                        // check to see if this survey's admin is the admin of the studentgroup of the instructor
                        const { data: studentgroupdata, error: studentgrouperror } = await supabase.from('studentgroups')
                            .select('admin')
                            .eq('instructor', user?.id);

                        if (studentgrouperror) {
                            console.error("error", studentgrouperror);
                        } else {
                            // if the survey's admin is the admin of the studentgroup of the instructor, then the instructor can view the survey
                            const { data: surveydata, error: surveyerror } = await supabase.from('surveys')
                                .select("id, title, data, completed_by, responses")
                                .in('admin', studentgroupdata.map(val => val.admin))
                                .eq('id', surveyid)
                                .single();

                            if (surveyerror) {
                                console.error("error", surveyerror);
                            } else {
                                setStudents([...studentdata]
                                    .filter(val1 => surveydata.completed_by.includes(val1.email))
                                    .map(val => ({
                                        "ID": val.id,
                                        "Name": val.name || "N/A",
                                        "Mentor": val.mentor ? val.mentor.name : "N/A",
                                        "Worksite": val.studentgroups?.worksite.name
                                    })));
                            }
                        }
                    }
                } else {
                    console.log("survey data:", surveydata);

                    setStudents([...studentdata]
                        .filter(val1 => surveydata.completed_by.includes(val1.email))
                        .map(val => ({
                            "ID": val.id,
                            "Name": val.name || "N/A",
                            "Mentor": val.mentor ? val.mentor.name : "N/A",
                            "Worksite": val.studentgroups?.worksite.name
                        })));
                }
            }
        })();
    }, [user, surveyid]);

    useEffect(() => {
        if (searchquery === "") {
            setFilteredStudents(students);
        } else {
            setFilteredStudents(
                students.filter((val) =>
                    val["Name"].toLowerCase().includes(searchquery.toLowerCase())
                )
            );
            setItemOffset(0); // Reset pagination offset when search query changes
        }
    }, [searchquery, students]);

    return (
        <div id='surveytablediv' className='w-full'>
            <div className='studentlisttitle !flex-row !justify-between items-center w-full flex under769:!flex-col under769:gap-[2vh]'>
                <p id='matchestitle'>Student List</p>
                <div id='matchsearch' className='flex !flex-row gap-2 items-center w-[20vw] under769:w-full'>
                    <SearchIcon />
                    <input type='text' placeholder='Quick Search...' className='w-full' onChange={(e) => setSearchQuery(e.target.value)} />
                </div>
            </div>
            <div className='w-full bg-[#D5D9DD] h-[1.5px] !my-5'></div>
            <div className='overflow-x-auto w-full'>
                <Table className='w-full'>
                    <Thead>
                        <Tr id='matchhead' className=''>
                            <Th>ID</Th>
                            <Th className=''>
                                <div className='flex justify-start'>
                                    <p>Name</p>
                                    {isover640 && (
                                        <button className='ml-4'>
                                            <SmallArrowIcon width={11} height={11} className={isStudentRotated ? 'rotate' : ''}
                                                onClick={() => handleSortClick('Name')}
                                            />
                                        </button>
                                    )}
                                </div>
                            </Th>
                            <Th className=''>
                                <div className='flex justify-start'>
                                    <p>Mentor</p>
                                    {isover640 && (
                                        <button className='ml-4'>
                                            <SmallArrowIcon width={11} height={11} className={isMentorRotated ? 'rotate' : ''} onClick={() => handleSortClick('Mentor')} />
                                        </button>
                                    )}
                                </div>
                            </Th>
                            <Th className=''>
                                <div className='flex justify-start'>
                                    <p>Worksite</p>
                                    {isover640 && (
                                        <button className='ml-4'>
                                            <SmallArrowIcon width={11} height={11} className={isWorksiteRotated ? 'rotate' : ''} onClick={() => handleSortClick('Worksite')} />
                                        </button>
                                    )}
                                </div>
                            </Th>
                            <Th>Results</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems &&
                            currentItems.length > 0 ? (
                                currentItems.map((val, idx) => {
                                    return (
                                        <Tr key={idx}>
                                            <Td>{val["ID"]}</Td>
                                            <Td>{val["Name"]}</Td>
                                            <Td>{val["Mentor"]}</Td>
                                            <Td>{val["Worksite"]}</Td>
                                            <Td>
                                                <Link to={`/viewsurvey/${surveyid}/${val["ID"]}`} className=" text-blue-700 underline viewresults">
                                                    View Results
                                                </Link>
                                            </Td>
                                        </Tr>
                                    );
                                })
                            ) : (
                                <Tr>
                                    <Td colSpan={8} className='text-center w-full text-xl font-semibold'>
                                        No students found
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel={
                    <button className='flex items-center justify-center'>
                        <IoIosArrowRoundForward color='black' size={26} />
                    </button>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                previousLabel={
                    <button className='flex items-center justify-center'>
                        <IoIosArrowRoundBack color='black' size={26} />
                    </button>
                }
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination !mt-5"
                activeClassName="active"
                renderOnZeroPageCount={null}
                pageCount={pageCount}
            />
        </div>
    )
}
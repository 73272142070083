import { useState, useEffect, useContext } from "react";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";
import "../../assets/css/mentormatching.css";
import StudentList from "../global/StudentList";

export default function MentorMatching({ sidetabvisible }) {
  const [studentswithmentors, setStudentsWithMentors] = useState([]);
  const [filteredstudents, setFilteredStudents] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function getStudents() {
      console.log("email is:", user?.email);

      // need to get all students who have a mentor, and get their id, name, worksite name, mentor id, mentor name
      const { data, error } = await supabase
        .from("students")
        .select("id, name, studentgroups(worksite: worksites (name)), mentor: mentors(id, name)")
        .neq("mentor", 0);
      // .eq("studentgroups.admin", email)

      if (error) console.error("error", error);
      else console.log("data for admin:", data);

      const students = data.map((val) => ({
        "Student ID": val.id,
        Student: val.name,
        Worksite: val.studentgroups ? val.studentgroups.worksite?.name : "N/A",
        "Mentor ID": val.mentor.id,
        Mentor: val.mentor.name,
      }));

      setStudentsWithMentors(students);
      setFilteredStudents(students);
    }
    getStudents();
  }, []);

  return (
    <div className="w-full px-[4vw]">
      <div className="mt-6 mb-24 !flex-row justify-center gap-4 w-full">
        <p className="font-semibold text-3xl self-start">Mentor Matching</p>
        <button className="matchingbuttons">Add Students</button>
        <button className="matchingbuttons">Shuffle Matches</button>
      </div>
      <StudentList
        title={"Matches"}
        students={studentswithmentors}
        filteredstudents={filteredstudents}
        setFilteredStudents={setFilteredStudents}
        page={"MentorMatching"}
        sidetabvisible={sidetabvisible}
      />
    </div>
  );
}

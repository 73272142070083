export const GlobalStyles = {
    // button blue
    blue: "#5D87FF",
    lightgray: "#7C8FAC",
    lightblue: "#AEBFFC",
    lightergray: "#F9F9F9",
    graytext: "#52565C",
    bluetext: "#3461FD",
    subtitle: "#808080",
    background: "#EAEAEA"
}
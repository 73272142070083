import "../../assets/css/admin.css";
import { forwardRef, useContext, useEffect, useState } from "react";
import { supabase } from "../../supabase";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as SmallArrowIcon } from "../../assets/icons/smallarrow.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import ReactPaginate from "react-paginate";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { LuSettings2 } from "react-icons/lu";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../App";
import Popup from "reactjs-popup";
import bcrypt from "bcryptjs";
import { toastError, toastPromise } from "../../GlobalFunctions";
import Checkbox from "@mui/material/Checkbox";
import {useNavigate } from "react-router-dom";

export default function StudentList({
  sidetabvisible,
  students,
  title,
  page,
  getStudents,
}) {
  console.log("sidebar visible:", sidetabvisible);
  const [searchquery, setSearchQuery] = useState("");
  const [filteredstudents, setFilteredStudents] = useState([]);
  const [isRoleRotated, setRoleRotated] = useState(false);
  const [isWorksiteRotated, setWorksiteRotated] = useState(false);
  const [isMentorRotated, setMentorRotated] = useState(false);
  const [isStudentRotated, setStudentRotated] = useState(false);
  const [selecteddate, setSelectedDate] = useState(new Date());
  const isover640 = useMediaQuery({ query: "(min-width: 641px)" });
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCheckInRotated, setCheckInRotated] = useState(false);
  const [isCheckOutRotated, setCheckOutRotated] = useState(false);
  const [isWorkHoursRotated, setWorkHoursRotated] = useState(false);
  const [isJoinedRotated, setJoinedRotated] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;
  const pageCount = Math.ceil(filteredstudents?.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredstudents?.slice(itemOffset, endOffset);
  // only for instructors
  const [currentStudent, setCurrentStudent] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [popupstate, setPopupState] = useState({ open: false, openedby: "" });
  const [confirmDelete, setConfirmDelete] = useState("");
  const navigate = useNavigate();
  const closeModal = () => setOpen(false);
  const closeModal2 = () =>
    setPopupState((prev) => ({ openedby: "", open: false }));

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  console.log("students are:", students);

  const handleClick = (type) => {
    if (type === "Student") {
      setStudentRotated((prevState) => !prevState);
    } else if (type === "Worksite") {
      setWorksiteRotated((prevState) => !prevState);
    } else if (type === "Mentor") {
      setMentorRotated((prevState) => !prevState);
    } else if (type === "Role") {
      setRoleRotated((prevState) => !prevState);
    } else if (type === "Check-in") {
      setCheckInRotated((prevState) => !prevState);
    } else if (type === "Check-out") {
      setCheckOutRotated((prevState) => !prevState);
    } else if (type === "Work Hours") {
      setWorkHoursRotated((prevState) => !prevState);
    } else if (type === "Joined") {
      setJoinedRotated((prevState) => !prevState);
    }
    setFilteredStudents((prev) =>
      prev.sort((a, b) => {
        // console.log("a is:", a, "b is:", b, "type is:", type)
        const typecheck =
          type === "Student"
            ? page === "MentorMatching"
              ? "Student"
              : "Name"
            : type;

        const isRotated =
          type === "Worksite"
            ? isWorksiteRotated
            : type === "Student"
            ? isStudentRotated
            : type === "Mentor"
            ? isMentorRotated
            : type === "Role"
            ? isRoleRotated
            : type === "Check-in"
            ? isCheckInRotated
            : type === "Check-out"
            ? isCheckOutRotated
            : type === "Work Hours"
            ? isWorkHoursRotated
            : type === "Joined"
            ? isJoinedRotated
            : false;

        if (type === "Joined") {
          const dateA = new Date(a[typecheck]);
          const dateB = new Date(b[typecheck]);
          return isRotated ? dateB - dateA : dateA - dateB;
        } else {
          return isRotated
            ? b[typecheck]?.localeCompare(a[typecheck])
            : a[typecheck]?.localeCompare(b[typecheck]);
        }
      })
    );
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredstudents.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [itemOffset]);

  const CustomStartInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      id="datepick"
      className="buttons text-black flex flex-row items-center justify-center p-3 rounded-lg customshadow gap-2"
      style={{ border: "1px solid #D5D9DD" }}
      onClick={onClick}
      ref={ref}
    >
      <CalendarIcon />
      {selecteddate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}
    </button>
  ));

  useEffect(() => {
    console.log("search query is:", searchquery);
    if (searchquery === "" && students?.length !== filteredstudents?.length) {
      setFilteredStudents(students);
    } else {
      setFilteredStudents(
        students?.filter((val) => {
          // console.log("val is:", val, searchquery.toLowerCase())
          return (val["Name"] || val["Student"])
            ?.toLowerCase()
            .includes(searchquery.toLowerCase());
        })
      );
      setItemOffset(0); // Reset pagination offset when search query changes
    }
  }, [searchquery, students]);

  const handlePortfolioClick = (studentId, studentEmail) => {
    console.log(`Navigating to student portfolio with ID: ${studentId}, Email: ${studentEmail}`);
    navigate(`/studentportfolio/${studentId}/${studentEmail}`);
  };
  
  return (
    <div
      id="admindiv"
      className="flex w-full mt-8 items-center justify-around"
      style={{ marginTop: "20px" }}
    >
      <div id="adminlistdiv" className="w-full">
        <div className="studentlisttitle !flex-row !justify-between items-center w-full flex under769:!flex-col under769:gap-[2vh]">
          <p id="matchestitle">{title}</p>
          {selectedStudents.length > 0 && page === "SuperAdminHome" && (
            <button
              id="deleteusers"
              className="bg-[#3354F4] buttons text-white flex flex-row items-center justify-center p-3 rounded-lg customshadow gap-2"
              onClick={() => setPopupState({ open: true, openedby: "Delete" })}
            >
              Delete Selected
            </button>
          )}
          {/* Search Bar */}
          <div
            id="matchsearch"
            className="flex !flex-row gap-2 items-center w-[20vw] under769:w-full"
          >
            <SearchIcon />
            <input
              type="text"
              placeholder="Quick Search..."
              className="w-full"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {page !== "MentorMatching" && (
            <ReactDatePicker
              className="w-10"
              selected={selecteddate}
              onChange={(date) => setSelectedDate(date)}
              customInput={<CustomStartInput />}
              popperPlacement="bottom"
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          )}
          {user?.role !== "Instructor" && (
            <button
              id="viewattendance"
              className="bg-[#3354F4] buttons text-white flex flex-row items-center justify-center p-3 rounded-lg customshadow gap-2"
            >
              <LuSettings2 />
              View Attendance
            </button>
          )}
        </div>
        <div className="w-full bg-[#D5D9DD] h-[1.5px] !my-5"></div>
        <div className="overflow-x-auto">
          <Table className="w-full">
            <Thead>
              <Tr id="matchhead" className="">
                {page === "SuperAdminHome" && <Th></Th>}
                {page === "MentorMatching" && <Th>Student ID</Th>}
                {page === "MentorMatching" && (
                  <Th className="">
                    <div className="flex justify-start !flex-row">
                      <p>Student</p>
                      {isover640 && (
                        <button className="ml-4">
                          <SmallArrowIcon
                            width={11}
                            height={11}
                            className={isStudentRotated ? "rotate" : ""}
                            onClick={() => handleClick("Student")}
                          />
                        </button>
                      )}
                    </div>
                  </Th>
                )}
                <Th>ID</Th>
                <Th className="">
                  <div className="flex justify-start">
                    <p>Name</p>
                    {isover640 && (
                      <button className="ml-4">
                        <SmallArrowIcon
                          width={11}
                          height={11}
                          className={isStudentRotated ? "rotate" : ""}
                          onClick={() => handleClick("Student")}
                        />
                      </button>
                    )}
                  </div>
                </Th>
                {page !== "AdminHome" && <Th>Email</Th>}
                <Th className="">
                  <div className="flex justify-start">
                    <p>Role</p>
                    {isover640 && (
                      <button className="ml-4">
                        <SmallArrowIcon
                          width={11}
                          height={11}
                          className={isRoleRotated ? "rotate" : ""}
                          onClick={() => handleClick("Role")}
                        />
                      </button>
                    )}
                  </div>
                </Th>
                {page !== "InstructorHome" && (
                  <Th className="">
                    <div className="flex justify-start">
                      <p>Mentor</p>
                      {isover640 && (
                        <button className="ml-4">
                          <SmallArrowIcon
                            width={11}
                            height={11}
                            className={isMentorRotated ? "rotate" : ""}
                            onClick={() => handleClick("Mentor")}
                          />
                        </button>
                      )}
                    </div>
                  </Th>
                )}
                <Th className="">
                  <div className="flex justify-start">
                    <p>Worksite</p>
                    {isover640 && (
                      <button className="ml-4">
                        <SmallArrowIcon
                          width={11}
                          height={11}
                          className={isWorksiteRotated ? "rotate" : ""}
                          onClick={() => handleClick("Worksite")}
                        />
                      </button>
                    )}
                  </div>
                </Th>
                {page === "MentorMatching" && <Th>Mentor ID</Th>}
                {page === "MentorMatching" && (
                  <Th className="">
                    <div className="flex justify-start !flex-row">
                      <p>Mentor</p>
                      {isover640 && (
                        <button className="ml-4">
                          <SmallArrowIcon
                            width={11}
                            height={11}
                            className={isMentorRotated ? "rotate" : ""}
                            onClick={() => handleClick("Mentor")}
                          />
                        </button>
                      )}
                    </div>
                  </Th>
                )}
                {page === "AdminHome" && (
                  <Th className="">
                    <div className="flex justify-start">
                      <p>Check-in</p>
                      {isover640 && (
                        <button className="ml-4">
                          <SmallArrowIcon
                            width={11}
                            height={11}
                            className={isCheckInRotated ? "rotate" : ""}
                            onClick={() => handleClick("Check-in")}
                          />
                        </button>
                      )}
                    </div>
                  </Th>
                )}
                {page === "AdminHome" && (
                  <Th className="">
                    <div className="flex justify-start">
                      <p>Check-out</p>
                      {isover640 && (
                        <button className="ml-4">
                          <SmallArrowIcon
                            width={11}
                            height={11}
                            className={isCheckOutRotated ? "rotate" : ""}
                            onClick={() => handleClick("Check-out")}
                          />
                        </button>
                      )}
                    </div>
                  </Th>
                )}
                {page === "AdminHome" && (
                  <Th className="">
                    <div className="flex justify-start">
                      <p>Work Hours</p>
                      {isover640 && (
                        <button className="ml-4">
                          <SmallArrowIcon
                            width={11}
                            height={11}
                            className={isWorkHoursRotated ? "rotate" : ""}
                            onClick={() => handleClick("Work Hours")}
                          />
                        </button>
                      )}
                    </div>
                  </Th>
                )}
                {(user?.role === "Instructor" || page === "SuperAdminHome") && (
                  <Th className="">
                    <div className="flex justify-start">
                      <p>% of Surveys Completed</p>
                    </div>
                  </Th>
                )}
                {(user?.role === "Instructor" || page === "SuperAdminHome") && (
                  <Th className="">
                    <div className="flex justify-start">
                      <p>% of Modules Completed</p>
                    </div>
                  </Th>
                )}
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems &&
                (currentItems.length > 0 ? (
                  currentItems.map((val, idx) => {
                    let time, formattedTime, time2, formattedTime2;
                    if (page === "AdminHome") {
                      time =
                        val["Hours"]?.[
                          selecteddate.toLocaleDateString("en-US", {
                            year: "2-digit",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        ]?.["check-in0"];
                      formattedTime = time?.length === 4 ? `0${time}` : time;
                      time2 =
                        val["Hours"]?.[
                          selecteddate.toLocaleDateString("en-US", {
                            year: "2-digit",
                            month: "2-digit",
                            day: "2-digit",
                          })
                        ]?.["check-out0"];
                      formattedTime2 =
                        time2?.length === 4 ? `0${time2}` : time2;
                    }

                    // Log student email
                    console.log("Student email:", val["email"]);

                    return (
                      <Tr key={idx}>
                        {page === "SuperAdminHome" && (
                          <Td>
                            <Checkbox
                              checked={selectedStudents.includes(val["email"])}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedStudents([
                                    ...selectedStudents,
                                    val["email"],
                                  ]);
                                } else {
                                  setSelectedStudents(
                                    selectedStudents.filter(
                                      (email) => email !== val["email"]
                                    )
                                  );
                                }
                              }}
                            />
                          </Td>
                        )}
                        {page === "MentorMatching" && (
                          <Td>{val["Student ID"]}</Td>
                        )}
                        {page === "MentorMatching" && <Td>{val["Student"]}</Td>}
                        {/* in SuperAdminHome  */}
                        {page !== "MentorMatching" && <Td>{val["ID"]}</Td>}
                        {/* in SuperAdminHome  */}
                        {page !== "MentorMatching" && <Td>{val["Name"]}</Td>}
                        {/* in SuperAdminHome  */}
                        {page !== "MentorMatching" && page !== "AdminHome" && (
                          <Td>{val["email"]}</Td>
                        )}
                        {/* in SuperAdminHome  */}
                        {page !== "MentorMatching" && <Td>{val["Role"]}</Td>}
                        {page === "MentorMatching" && (
                          <Td>{val["Mentor ID"]}</Td>
                        )}
                        {/* in SuperAdminHome  */}
                        {(page === "AdminHome" ||
                          page === "SuperAdminHome") && (
                          <Td>{val["Mentor"]}</Td>
                        )}
                        {/* in SuperAdminHome  */}
                        <Td>{val["Worksite"]}</Td>
                        {page === "AdminHome" && (
                          <Td>
                            {/* Check-in */}
                            {formattedTime &&
                              new Date(
                                `1970-01-01T${formattedTime}:00`
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                          </Td>
                        )}
                        {page === "AdminHome" && (
                          <Td>
                            {/* Check-out */}
                            {formattedTime2 &&
                              new Date(
                                `1970-01-01T${formattedTime2}:00`
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                          </Td>
                        )}
                        {page === "AdminHome" && (
                          <Td>
                            {val["Hours"] &&
                              val["Hours"]?.[
                                selecteddate.toLocaleDateString("en-US", {
                                  year: "2-digit",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              ] &&
                              `${Math.floor(
                                Object.keys(
                                  val["Hours"][
                                    selecteddate.toLocaleDateString("en-US", {
                                      year: "2-digit",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  ]
                                ).reduce((prev, cur, idx) => {
                                  // skip every other key
                                  if (idx % 2 === 0) {
                                    return (
                                      prev +
                                      (val["Hours"][
                                        selecteddate.toLocaleDateString(
                                          "en-US",
                                          {
                                            year: "2-digit",
                                            month: "2-digit",
                                            day: "2-digit",
                                          }
                                        )
                                      ][`check-out${idx}`]
                                        .split(":")
                                        .reduce(
                                          (prev, cur, idx) =>
                                            idx === 0
                                              ? prev + parseInt(cur) * 60 * 60
                                              : prev + parseInt(cur) * 60,
                                          0
                                        ) -
                                        val["Hours"][
                                          selecteddate.toLocaleDateString(
                                            "en-US",
                                            {
                                              year: "2-digit",
                                              month: "2-digit",
                                              day: "2-digit",
                                            }
                                          )
                                        ][`check-in${idx}`]
                                          .split(":")
                                          .reduce(
                                            (prev, cur, idx) =>
                                              idx === 0
                                                ? prev + parseInt(cur) * 60 * 60
                                                : prev + parseInt(cur) * 60,
                                            0
                                          ))
                                    );
                                  }
                                  return prev;
                                }, 0) / 3600
                              )}h ${
                                Math.floor(
                                  Object.keys(
                                    val["Hours"][
                                      selecteddate.toLocaleDateString("en-US", {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })
                                    ]
                                  ).reduce((prev, cur, idx) => {
                                    // skip every other key
                                    if (idx % 2 === 0) {
                                      return (
                                        prev +
                                        (val["Hours"][
                                          selecteddate.toLocaleDateString(
                                            "en-US",
                                            {
                                              year: "2-digit",
                                              month: "2-digit",
                                              day: "2-digit",
                                            }
                                          )
                                        ][`check-out${idx}`]
                                          .split(":")
                                          .reduce(
                                            (prev, cur, idx) =>
                                              idx === 0
                                                ? prev + parseInt(cur) * 60 * 60
                                                : prev + parseInt(cur) * 60,
                                            0
                                          ) -
                                          val["Hours"][
                                            selecteddate.toLocaleDateString(
                                              "en-US",
                                              {
                                                year: "2-digit",
                                                month: "2-digit",
                                                day: "2-digit",
                                              }
                                            )
                                          ][`check-in${idx}`]
                                            .split(":")
                                            .reduce(
                                              (prev, cur, idx) =>
                                                idx === 0
                                                  ? prev +
                                                    parseInt(cur) * 60 * 60
                                                  : prev + parseInt(cur) * 60,
                                              0
                                            ))
                                      );
                                    }
                                    return prev;
                                  }, 0)
                                ) %
                                (3600 / 60)
                              }m`}
                          </Td>
                        )}
                        {/* cell for percentage of surveys completed */}
                        {(user?.role === "Instructor" ||
                          page === "SuperAdminHome") && (
                          <Td>{val["% of Surveys Completed"]}%</Td>
                        )}
                        {/* cell for percentage of modules completed */}
                        {(user?.role === "Instructor" ||
                          page === "SuperAdminHome") && (
                          <Td>
                            {val["% of Modules Completed"] === "N/A"
                              ? "0%"
                              : val["% of Modules Completed"] * 100 + "%"}
                          </Td>
                        )}
                        {(user?.role === "Instructor" ||
                          page === "SuperAdminHome") && (
                          <Td>
                            <button
                              className="bg-[#3354F4] buttons text-white flex flex-row items-center justify-center p-[1vh] rounded-lg
                                                            customshadow gap-2"
                              onClick={() => {
                                
                                console.log("val is:", val["Name"], val["Email"]);
                                setCurrentStudent({
                                  name: val["Name"],
                                  email: val["Email"],
                                });
                                setOpen(true);
                              }}
                            >
                              Change Password
                            </button>
                          </Td>
                        )}
                        
                        <Td>
                          <button
                            className="bg-[#3354F4] buttons text-white flex flex-row items-center justify-center p-[1vh] rounded-lg customshadow gap-2"
                            onClick={() => handlePortfolioClick(val["ID"], val["Email"])}
                          >
                            View Portfolio
                          </button>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={8}
                      className="text-center w-full text-xl font-semibold"
                    >
                      No students found
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <button className="flex items-center justify-center">
              <IoIosArrowRoundForward color="black" size={26} />
            </button>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          previousLabel={
            <button className="flex items-center justify-center">
              <IoIosArrowRoundBack color="black" size={26} />
            </button>
          }
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination !mt-5"
          activeClassName="active"
          renderOnZeroPageCount={null}
          pageCount={pageCount}
        />
        <Popup
          open={open}
          closeOnDocumentClick
          onClose={closeModal}
          contentStyle={{ gap: "1vh" }}
          overlayStyle={{
            background: "rgba(0,0,0,0.5)",
            marginLeft: sidetabvisible ? "25vw" : 0,
            width: sidetabvisible ? "75vw" : "100vw",
          }}
        >
          <p className="mb-2 text-lg">
            Change Password for {currentStudent?.name}
          </p>
          <input
            className="w-10/12"
            placeholder="New Password"
            id="newpassword"
            style={{ border: "1px solid #D5D9DD" }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="w-10/12"
            placeholder="Confirm Password"
            id="confirmpassword"
            style={{ border: "1px solid #D5D9DD" }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            className="regbuttons my-[1.5vh] w-1/2"
            onClick={async () => {
              // console.log("email of current student:", currentStudent?.email)
              toastPromise(
                new Promise(async (resolve, reject) => {
                  if (password !== confirmPassword) {
                    reject("Passwords do not match");
                  }
                  const salt = bcrypt.genSaltSync(10);
                  const hashedPassword = bcrypt.hashSync(password, salt);
                  console.log("hashed password:", hashedPassword);
                  const { error } = await supabase.rpc(
                    "change_student_password",
                    {
                      email: currentStudent.email,
                      new_password: hashedPassword,
                    }
                  );
                  if (error) {
                    console.error("error changing password:", error);
                    reject();
                  }
                  setOpen(false);
                  resolve();
                }),
                "Updating"
              );
            }}
          >
            Update
          </button>
        </Popup>
        {page === "SuperAdminHome" && (
          <Popup
            open={popupstate.open}
            closeOnDocumentClick
            onClose={closeModal2}
            contentStyle={{ gap: "1vh" }}
            overlayStyle={{
              background: "rgba(0,0,0,0.5)",
              marginLeft: sidetabvisible ? "25vw" : 0,
              width: sidetabvisible ? "75vw" : "100vw",
            }}
          >
            {popupstate.openedby === "Change" ? (
              <div>
                <p className="mb-2 text-lg">
                  Change Password for {currentStudent?.name}
                </p>
                <input
                  className="w-10/12"
                  placeholder="New Password"
                  id="newpassword"
                  style={{ border: "1px solid #D5D9DD" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  className="w-10/12"
                  placeholder="Confirm Password"
                  id="confirmpassword"
                  style={{ border: "1px solid #D5D9DD" }}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  className="regbuttons my-[1.5vh] w-1/2"
                  onClick={async () => {
                    // console.log("email of current student:", currentStudent?.email)
                    toastPromise(
                      new Promise(async (resolve, reject) => {
                        if (password !== confirmPassword) {
                          reject("Passwords do not match");
                        }
                        const salt = bcrypt.genSaltSync(10);
                        const hashedPassword = bcrypt.hashSync(password, salt);
                        // console.log("hashed password:", hashedPassword)
                        const { error } = await supabase.rpc(
                          "change_student_password",
                          {
                            email: currentStudent.email,
                            new_password: hashedPassword,
                          }
                        );
                        if (error) {
                          console.error("error changing password:", error);
                          reject();
                        }
                        setPopupState({ open: false, openedby: "" });
                        resolve();
                      }),
                      "Updating"
                    );
                  }}
                >
                  Update
                </button>
              </div>
            ) : (
              <div>
                <p className="mb-2 text-lg">
                  Are you sure you'd like to delete?
                </p>
                {selectedStudents.map((val, index) => (
                  <p key={index}>{val}</p>
                ))}
                <p className="mb-2 text-sm">
                  Type<span className=" font-bold text-md">DELETE</span>to
                  confirm
                </p>
                <input
                  type="text"
                  className="border-2 border-gray-300 rounded-lg p-1"
                  onChange={(e) => setConfirmDelete(e.target.value)}
                />
                <button
                  className="regbuttons my-[1.5vh] w-1/2"
                  onClick={async () => {
                    if (confirmDelete === "DELETE") {
                      toastPromise(
                        new Promise(async (resolve, reject) => {
                          const { error } = await supabase.rpc("delete_users", {
                            user_emails: selectedStudents,
                          });
                          if (error) {
                            console.error("error deleting users:", error);
                            reject();
                          }
                          setSelectedStudents([]);
                          await getStudents();
                          setPopupState({ open: false, openedby: "" });
                          resolve();
                        }),
                        "Deleting"
                      );
                    } else {
                      toastError("Please type DELETE to confirm");
                    }
                  }}
                >
                  Update
                </button>
              </div>
            )}
          </Popup>
        )}
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../App";
import { toastError, toastPromise, toastSuccess } from "../../GlobalFunctions";
import { GlobalStyles } from "../../GlobalStyles";
import { supabase } from "../../supabase";
import { FaTrash } from "react-icons/fa6";
import Popup from "reactjs-popup";
import '../../assets/css/editsurvey.css'

export default function EditSurvey() {
    const navigate = useNavigate();
    const { surveyid } = useParams();
    const { user } = useContext(AuthContext);
    const [survey, setSurvey] = useState({});
    const [deletingquestion, setDeletingQuestion] = useState(false);
    const [deletingquestionid, setDeletingQuestionId] = useState(null);

    useEffect(() => {
        (async() => {
            const { data, error } = await supabase.from('surveys')
                .select('*').eq('id', surveyid)
                .single()

            if (error) {
                console.error(error.message)
            } else {
                // if data.admin !== email, toast "You don't have access to this survey!", then redirect to /surveys
                if ((data.admin !== user?.email) && (data.instructor !== user?.email)) {
                    toastError("You don't have access to this survey!")
                    navigate('/surveys')
                } else {
                    console.log("survey data", data)
                    setSurvey(data)
                }
            }
        })()
    }, [])

    return (
        <div id="editsurveydiv" className='w-full px-[4vw] !justify-start !items-start gap-[2vh] text-ellipsis'>
            <p className='text-2xl font-semibold self-start'>Edit Survey</p>
            <input style={{ fontSize: 'calc(24px + 0.390625vw)', color: '#686868' }}
                type="text" 
                value={survey.title || ""}
                onChange={(e) => setSurvey(prevSurvey => ({ ...prevSurvey, title: e.target.value }))}
                placeholder="Survey Title" className='mt-[1vh] font-bold'
                id='newsurveytitle'
            />
            {(survey.data && survey.data.length > 0) && (
                <ul className='w-full flex flex-col gap-[3vh]'>
                    {survey.data.map((question, index) => (
                        <li key={question.id} className='flex flex-col gap-[1vh]'>
                            <div className='!flex-row'>
                                {index + 1}.&nbsp;
                                <input value={question.text || ""} type="text" placeholder="Question text goes here." className='questiontext w-full' style={{ outline: 'none' }} onChange={(e) => {
                                    setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.map((q, i) => i === index ? { ...q, text: e.target.value } : q) }))
                                }} />
                                <FaTrash color='red' className='cursor-pointer' onClick={() => {
                                    setDeletingQuestion(true);
                                    setDeletingQuestionId(question.id);
                                }} />
                            </div>
                            <input value={question.explainer || ""} type="text" style={{ color: "#9ca3af" }} placeholder="Optional explainer text" className='w-full outline-none' onChange={(e) => {
                                setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.map((q, i) => i === index ? { ...q, explainer: e.target.value } : q) }))
                            }} />
                            {/* let user choose between "Add multiple choice answer", "Add free response", and Add "1-5 rating scale" */}
                            <div className='!items-start gap-[0.5vh] surveyselect' onChange={(e) => {
                                setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.map((q, i) => i === index ? { ...q, type: e.target.value } : q) }))
                            }}>
                                <label>
                                    <input type="radio" value="multiplechoice" name={`questionType${index}`}
                                        defaultChecked={question.type === "multiplechoice" || question.type === "mccheckbox"}
                                    />
                                    Add Multiple Choice
                                </label>
                                {/* WHOLE MULTIPLE CHOICE INNER SECTION */}
                                {(question.type === "multiplechoice" || question.type === "mccheckbox") && (
                                    <div className='ml-[1vw] !items-start gap-[0.5vh]'>
                                        {/* Add a checkbox for "Add checkbox for multiple choice" */}
                                        <label>
                                            <input className='mr-[1vw]' type="checkbox" value="mccheckbox" name={`multipleChoice${index}`}
                                                defaultChecked={question.type === "mccheckbox"}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.map((q, i) => i === index ?
                                                        {...q, type: e.target.checked ? "mccheckbox" : "multiplechoice" } : q)
                                                    }))
                                                }}
                                            />
                                            Add Checkbox for Multiple Choice
                                        </label>
                                        {/* Display an input for each multiple choice option, with the number of the option being to the left of each input */}
                                        {question.options && question.options.map((val, optionindex) => (
                                            <div className='!flex-row' key={val.id}>
                                                {optionindex + 1}.&nbsp;
                                                <input type="text" placeholder="Option text goes here." className='questiontext w-full' value={val.text || ""}
                                                    style={{ outline: 'none' }} onChange={(e) => {
                                                    e.stopPropagation();
                                                    setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.map((q, i) => i === index ?
                                                        { ...q, options: q.options.map((o) => o.id === val.id ? { ...o, text: e.target.value } : o) } : q)
                                                    }))
                                                }} />
                                                <FaTrash color='red' className='cursor-pointer ml-[1vw]' onClick={() => {
                                                    setDeletingQuestion(true);
                                                    setDeletingQuestionId(val.id);
                                                }} />
                                            </div>
                                        ))}
                                        {(question.type === 'multiplechoice' || question.type === 'mccheckbox') && (
                                            <button className=' !text-sm' onClick={() => {
                                                setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.map(q => q.id === question.id ?
                                                    { ...q, options: [...(q.options || []), { id: Date.now(), text: '' }] } : q)
                                                }))
                                            }} style={{ backgroundColor: GlobalStyles.blue }}>
                                            Add Multiple Choice Option
                                            </button>
                                        )}
                                    </div>
                                )}
                                <label>
                                    <input type="radio" value="freeresponse" name={`questionType${index}`}
                                        defaultChecked={question.type === "freeresponse"}
                                    />
                                    Add Free Response
                                </label>
                                <label>
                                    <input type="radio" value="ratingscale" name={`questionType${index}`} 
                                        defaultChecked={question.type === "ratingscale"}
                                    />
                                    Add Rating Scale
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            <button style={{ backgroundColor: GlobalStyles.blue }} onClick={() => {
                setSurvey(prevSurvey => ({ ...prevSurvey, data: [...(prevSurvey.data || []), { id: Date.now() }] }))
            }}>
                Add Question
            </button>
            <button className='mb-[2vh]' onClick={async() => {
                toastPromise(new Promise(async (resolve, reject) => {
                    const { error } = await supabase.from('surveys').update({ title: survey.title.trim(), data: survey.data }).eq('id', surveyid);
                    if (error) {
                        console.error("Error creating survey:", error);
                        reject(error);
                    } else {
                        navigate('/surveys')
                        resolve()
                    }
                }), "Updating survey...", "Survey updated!")
            }} style={{ backgroundColor: "green" }}>
                Update Survey
            </button>
            <Popup open={deletingquestion} closeOnDocumentClick onClose={() => setDeletingQuestion(false)} className='flex flex-col items-center justify-center'>
                <p className='mb-2 text-lg'>Are you sure you want to delete this question?</p>
                <button onClick={async() => {
                    setSurvey(prevSurvey => ({ ...prevSurvey, data: prevSurvey.data.filter((q) => q.id !== deletingquestionid) }))
                    setDeletingQuestion(false);
                    toastSuccess("Question deleted!")
                }} className='bg-red-600 my-2 text-[1rem] rounded-lg shadow-2xl shadow-cyan-500/50 !px-[1vw] !py-1 text-white'>
                    Delete
                </button>
            </Popup>
        </div>
    )
}
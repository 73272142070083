import { useLocation } from "react-router-dom";
import { GlobalStyles } from "../../GlobalStyles";
import { supabase } from "../../supabase";
import { useContext, useEffect, useState } from "react";
import { toastError, toastPromise } from "../../GlobalFunctions";
import { AuthContext } from "../../App";
import PuffLoader from "react-spinners/PuffLoader";
import { useMediaQuery } from "react-responsive";

export default function Assignment() {
    const location = useLocation();
    const module = location.state?.module;
    const [answers, setAnswers] = useState({});
    const { user, admin, setAdmin } = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [questions, setQuestions] = useState([]);
    const under567 = useMediaQuery({ query: '(max-width: 567px)' });

    useEffect(() => {
        (async () => {
            const { data, error } = await supabase.from('modules')
                .select('*').eq('id', module.id)
                .single();
            
            if (error) {
                console.error(error.message);
            } else {
                console.log("module data", data);
                let tempadmin = admin;
                if (admin === "") {
                    const { data: studentdata, error: studenterror } = await supabase.from('students')
                        .select(`studentgroups(admin)`)
                        .eq('email', user?.email)
                        .single();
                    if (studenterror) {
                        toastError("Error getting student data");
                        console.error(studenterror.message);
                    } else {
                        console.log("studentdata", studentdata);
                        tempadmin = studentdata.studentgroups.admin;
                        setAdmin(studentdata.studentgroups.admin);
                    }
                }
                console.log("admin:", tempadmin);
                console.log("assignments:", data.assignments);

                if (Array.isArray(data.assignments)) {
                    const assignments = data.assignments.find(assignment => assignment.admin === tempadmin);
                    console.log("found assignment:", assignments);
                    if (assignments) {
                        setQuestions(assignments);
                        setAnswers(Object.fromEntries(assignments.sections.map(section =>
                            [section.title, Array(section.questions.length).fill("")]
                        )));
                    } else {
                        console.log("No assignments found for the admin:", tempadmin);
                    }
                } else {
                    console.log("data.assignments is not an array or is undefined");
                }
            }
        })();
    }, []);

    useEffect(() => {
        console.log("answers", answers);
    }, [answers]);

    if (submitting) {
        return (
            <PuffLoader color={'#5D87FF'} loading={true} size={150} id="loader" />
        );
    }

    return (
        <div className="w-full gap-[2vw] !items-start"
            style={{ flexDirection: under567 ? 'column' : 'row' }}
        >
            <div id="moduletitlediv" style={{ backgroundColor: GlobalStyles.lightblue, width: under567 ? '100%' : '60%' }}
                className="p-[2vh] atleast769:p-2 rounded-2xl gap-y-4"
            >
                <p className="text-4xl font-semibold">
                    {module.title}
                </p>
                <iframe title="moduledisplay" src={module.link} width="100%" allowFullScreen={true} mozallowfullscreen="true"
                    webkitallowfullscreen="true" style={{ height: '73vh' }}
                ></iframe>                
            </div>
            <div className="leftstudentsurvey !items-start gap-[2vh]"
                style={{
                    width: under567 ? '100%' : '40%',
                    overflowY: under567 ? 'visible' : 'scroll',
                    height: under567 ? 'auto' : '83vh'
                }}
            >
                <h1 className=" text-3xl font-bold" style={{ color: '#686868'}}>{questions?.title}</h1>
                {questions?.sections?.map((section, index) => {
                    return (
                        <div key={index} className=" !items-start gap-[1vh] w-full">
                            <h1 className="text-2xl font-semibold underline text-start mb-[1vh]" style={{ color: "#686868"}}>{section.title}</h1>
                            {section.questions && section.questions.map((question, index) => {
                                if (typeof question === 'string' || question.type === 'freeresponse') {
                                    return (
                                        <div className="!items-start gap-[1vh] w-full" key={index}>
                                            <p className="font-bold text-start">{index + 1}. {typeof question === 'string' ? question : question.text}</p>
                                            <textarea className="w-full p-2 rounded-lg" style={{ border: "2px solid #686868", boxShadow: '0px 0px 4px #686868' }}
                                                id={index} name={question.text} onChange={(e) => {
                                                    const newAnswers = [...answers[section.title]];
                                                    newAnswers[index] = e.target.value;
                                                    setAnswers({ ...answers, [section.title]: newAnswers });
                                                }}
                                                value={answers[section.title][index] || ""}
                                            />
                                        </div>
                                    );
                                }
                                return <></>;
                            })}
                        </div>
                    );
                })}
                <button className="bg-[green] text-white font-semibold p-2 rounded-lg mb-[2vh]" onClick={async () => {
                    toastPromise(new Promise(async (resolve, reject) => {
                        setSubmitting(true);
                        if (Object.values(answers).some(section => section.some(answer => answer === ""))) {
                            setSubmitting(false);
                            reject("Please answer all questions");
                            return;
                        }
                        const { data, error } = await supabase.from('modules')
                            .update({ assignments: module.assignments.map(assignment => {
                                if (assignment.admin === admin && assignment.title === questions.title) {
                                    return { ...assignment, answers: { ...(assignment.answers || []), [user?.email]: { date: new Date(), answers } } };
                                }
                                return assignment;
                            }) })
                            .eq('id', module.id);

                        if (error) {
                            console.error(error.message);
                            setSubmitting(false);
                            reject();
                        } else {
                            setSubmitting(false);
                            resolve();
                        }
                    }), "Submitting Survey");
                }}
                disabled={submitting}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}
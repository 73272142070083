import { ReactComponent as BlueDot } from "../../assets/icons/bluedot.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow-right.svg";
import { GlobalStyles } from "../../GlobalStyles";
import { useNavigate } from "react-router-dom";

export default function ModuleCard({ module }) {
    const navigate = useNavigate();

    // Handle click only if link is not NULL
    const handleClick = () => navigate("/module", { state: { module } });

    return (
        <div
            className="!justify-between rounded-2xl !flex-row gap-x-[2rem] px-[1.5rem] py-[1.5rem] w-full cursor-pointer"
            style={{ backgroundColor: GlobalStyles.lightergray }}
            onClick={handleClick}
        >
            <BlueDot />
            <div>
                <p className="font-medium text-xl self-start">{module.title}</p>
                {/* Optionally, show description only for modules with a valid link */}
                {module.description && <p className="font-medium text-xs self-start">{module.description}</p>}
            </div>
            <RightArrow />
        </div>
    );
}

import { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import '../../assets/css/viewmodule.css'

export default function ViewModule() {
    const { moduleid, studentid } = useParams();
    const [docs, setDocs] = useState([])

    // https://f005.backblazeb2.com/file/acewisefiles/completed_assignments/nathanuel_dixon_nathan0322@hotmail.com/1_intro_to_ace

    useEffect(() => {
        (async() => {
            const { data, error } = await supabase.from('modules')
                .select('*').eq('id', moduleid)
                .single()
            
            if (error) console.error(error.message)
            else {
                const { data: studentdata, error: studenterror } = await supabase.from('students')
                    .select('email, name').eq('id', studentid)
                    .single()

                if (studenterror) console.error(studenterror.message)
                else {
                    const student = studentdata.name.toLowerCase().replace(/\s+/g, '_') + "_" + studentdata.email;
                    const assignment = moduleid + "_" + data.title.toLowerCase().replace(/\s+/g, '_');
                    console.log("module data:", data)
                    setDocs([{
                        uri: `https://f005.backblazeb2.com/file/acewisefiles/completed_assignments/${student}/${assignment}`
                    }])
                }
            }
        })()
    }, [])

    return (
        <div className="w-full h-[83vh]">
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
        </div>
    )
}
import { ReactComponent as BlueDot } from "../../assets/icons/bluedot.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow-right.svg";
import { GlobalStyles } from "../../GlobalStyles";
import { uploadFileToBackblaze } from '../../GlobalFunctions';
import { blue, blueGrey } from "@mui/material/colors";

export default function DownloadCard({ module, moduleId }) {
    const moduleName = module.toLowerCase().replace(/\s+/g, '_');
    const assignment = moduleId + "_" + moduleName;

    const handleDownload = () => {
        const fileName = `${assignment}`;
        const fileBaseUrl = "https://f005.backblazeb2.com/file/acewisefiles/assignments/";
        const fileExtension = ".docx"
        const fileUrl = `${fileBaseUrl}${fileName}${fileExtension}`;

        // Directly downloading the file using the constructed URL
        window.location.href = fileUrl;
        console.log(`Downloading file from ${fileUrl}...`);
    };

    return (
        <div className="rounded-2xl flex-row gap-x-[2rem] w-1/2"
            // px-[1.5rem] py-[1.5rem]
            // Use GlobalStyles.lightblue for the background
            style={{backgroundColor: GlobalStyles.lightblue}}
        > 
            {/* Placeholder for a left-side design element, similar to BlueDot in your upload card */}
            <div className="left-side-design-element"></div>

            {/* Download button */}
            <button className="rounded-2xl py-2 px-4"
                style={{
                    // backgroundColor: GlobalStyles.lightergray, // Match the Upload button color
                    backgroundColor: GlobalStyles.lightblue,
                    color: 'black', // Adjust text color for visibility
                    // padding: '10px 50px', // Adjust padding for width
                    // borderRadius: '5px', // Rounded corners
                    border: 'none', // Remove default border
                    cursor: 'pointer', // Cursor indicates a clickable button
                }}
                onClick={handleDownload}
            >
                Download Assignment
            </button>

            <div className="right-side-design-element"></div>
        </div>
    );
}

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import { toastPromise } from "../../GlobalFunctions";
import { AuthContext } from "../../App";

export default function ViewSurvey() {
    const { surveyid, studentid } = useParams();
    const [survey, setSurvey] = useState({});
    const [answers, setAnswers] = useState({});

    useEffect(() => {
        (async() => {
            const { data, error } = await supabase.from('surveys')
                .select('*').eq('id', surveyid)
                .single()
            
            if (error) console.error(error.message)
            else {
                console.log("survey data:", data)
                // setSurvey(data)

                const { data: studentdata, error: studenterror } = await supabase.from('students')
                    .select('email').eq('id', studentid)
                    .single()

                if (studenterror) console.error(studenterror.message)
                else {
                    console.log("student data:", data.responses.find(response => {
                        console.log("response", response, "\nstudentemail", studentdata.email)
                        return response.studentemail === studentdata.email
                    }))
                    setSurvey(data)
                    // answers for student are in data.responses, each object has "studentemail" and "answers"
                    setAnswers(data.responses.find(response => response.studentemail === studentdata.email).answers)
                }
            }
        })()
    }, [])

    useEffect(() => {
        console.log("answers", answers)
    }, [answers])

    return (
        <div className="!self-start ml-[2vw]">
            <div className="!items-start gap-[2vh]">
                <p className="font-semibold" style={{ color: '#CBCBCB' }}>{new Date(survey.created_at).toLocaleDateString('en-US', 
                    { year: 'numeric', month: 'long', day: 'numeric' })}
                </p>
                <h1 className=" text-3xl font-bold" style={{ color: '#686868'}}>{survey.title}</h1>
                <div style={{ backgroundColor: "#F1F1F1" }} className="p-4 rounded-xl !items-start">
                    <p className=" font-semibold">This survey will help customize your experience</p>
                    <p>Please complete this document to help us see your progress.</p>
                </div>
                {/* iterate through each question in data.data array */}
                {/* [
                    {
                        "id": 1711601122050,
                        "text": "What career industry are you interested in",
                        "type": "mccheckbox",
                        "options": [
                            {
                                "id": 1711601157817,
                                "text": "Business"
                            },
                            {
                                "id": 1711601163615,
                                "text": "Tech"
                            }
                        ],
                        "explainer": "What is the "
                    }
                ] */}
                {/* multiplechoice, mccheckbox, freeresponse, ratingscale */}
                {survey.data && survey.data.map((question, index) => {
                    return (
                        <div key={index} className=" !items-start gap-[1vh]">
                            <p className=" font-bold">{index + 1}. {question.text}</p>
                            {question.explainer && (
                                <p className=" italic" style={{ color: "#686868"}}>Details: {question.explainer}</p>
                            )}
                            {question.type === 'mccheckbox' ? (
                                question.options.map((option, i) => {
                                    return (
                                        <div key={i} className=" !flex-row justify-center gap-[.375vw]">
                                            <input type="checkbox" id={option.id} name={option.text} value={option.text}
                                                readOnly
                                                checked={answers[question.id].includes(option.text)}
                                                disabled={!answers[question.id].includes(option.text) && Object.keys(answers).length > 0}
                                            />
                                            <label htmlFor={option.id}>{option.text}</label>
                                        </div>
                                    )
                                })
                            ) : question.type === 'multiplechoice' ? (
                                question.options.map((option, i) => {
                                    return (
                                        <div key={i} className="!flex-row justify-center gap-[.375vw]">
                                            <input type="radio" id={option.id} name={question.text} value={option.text}
                                                readOnly
                                                checked={answers[question.id] === option.text}
                                                disabled={!answers[question.id] && Object.keys(answers).length > 0}
                                            />
                                            <label htmlFor={option.id}>{option.text}</label>
                                        </div>
                                    )
                                })
                            ) : question.type === 'freeresponse' ? (
                                <textarea className="w-full p-2 rounded-lg" style={{ border: "2px solid #686868", boxShadow: '0px 0px 4px #686868' }}
                                    id={question.id} name={question.text}
                                    value={answers[question.id] || 'No answer provided'}
                                    disabled={answers[question.id] && Object.keys(answers).length > 0}
                                />
                            ) : question.type === 'ratingscale' ? (
                                <div className="!flex-row w-full gap-[1vw]">
                                    {Array.from({ length: 5 }, (_, i) => i + 1).map((rating, i) => {
                                        return (
                                            <div style={{ border: `2px solid ${answers[question.id] !== rating ? "#686868" : "white"}`,
                                                backgroundColor: answers[question.id] === rating ? '#138808' : 'transparent',
                                                color: answers[question.id] === rating ? 'white' : 'black'
                                            }}
                                                className="w-1/5 rounded-lg px-[1vw] py-[1vh] font-bold" key={i}
                                            >
                                                {rating}
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ControlledPopup from "../components/admin/ControlledPopup";
import { supabase } from "../supabase";
import { toastError } from "../GlobalFunctions";
import readXlsxFile from "read-excel-file";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Papa from "papaparse";
import { AsyncPaginate } from "react-select-async-paginate";
import "../assets/css/creategroup.css";
import "reactjs-popup/dist/index.css";
import { loadOptions } from "../components/global/loadOptions";
import PuffLoader from "react-spinners/PuffLoader";
import { AuthContext } from "../App";

export default function CreateGroup() {
    const [enteredemail, setEnteredEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [orignialemaillist, setOriginalEmailList] = useState([]);
    const [open, setOpen] = useState(false);
    const [adminimg, setAdminImg] = useState("");
    const [fileuploaded, setFileUploaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [showgroupmatching, setShowGroupMatching] = useState(false);
    const [groups, setGroups] = useState([]);
    const [columns, setColumns] = useState([]);
    const [studentsindb, setStudentsInDb] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deletedgroups, setDeletedGroups] = useState([]);
    // const [availableworksites, setAvailableWorksites] = useState([]);
    const [availableinstructors, setAvailableInstructors] = useState([]);
    const [originalgroups, setOriginalGroups] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        (async function getStats() {
            // get all students and store in studentsindb for later usage
            const { data: studentdata, error: studenterror } = await supabase.from("students").select("email");
            if (studenterror) {
                toastError("Error fetching students, please try again!");
            } else {
                setStudentsInDb(studentdata.map((val) => val.email));
                // get matching row, save admin id, and use it to find matching rows in studentgroups
                const { data: groupdata, error: grouperror } = await supabase
                    .from("studentgroups")
                    .select("uuid, name, students(email), instructor(name)")
                    // worksite(name)"
                    .eq("admin", user?.email);
                if (grouperror) {
                    // no groups found for current admin
                    console.error("Error finding matching groups:", grouperror);
                } else {
                    // groupdata holds all studentgroups matching admin, populate each row with students affiliated with them
                    console.log("groupdata:", groupdata);
                    if (groupdata.length > 0) {
                        // iterate through groupdata and create groups
                        const grouparr = groupdata.map((val) => ({
                            name: val.name,
                            emails: val.students.map((val2) => val2.email),
                            uuid: val.uuid,
                            instructor: val.instructor?.name,
                        }));
                        setGroups(grouparr);
                        setOriginalGroups(grouparr);
                    }
                    const { data: instructordata, error: instructorerror } = await supabase
                        .from("instructors")
                        .select("id, name");
                    if (instructorerror) {
                        console.error("Error getting instructors:", instructorerror);
                    } else {
                        console.log("instructordata:", instructordata);
                        setAvailableInstructors(
                            instructordata.map((val) => ({ value: val.name, label: val.name, id: val.id }))
                        );
                    }
                }
            }
            setIsLoading(false);
        })();
    }, []);

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => {
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",
            padding: grid * 2,
            margin: `0 0 ${grid}px 0`,
            borderRadius: "3px",
            // change background colour if dragging
            background: isDragging ? "#F9F9F9" : "#F9F9F9",

            // styles we need to apply on draggables
            ...draggableStyle,
        };
    };

    const getListStyle = (isDraggingOver, name) => ({
        background: isDraggingOver ? "#AEBFFC" : "#AEBFFC",
        padding: grid,
        width: "45%",
        minHeight: "72px",
        display: "flex",
        flexDirection: "column",
        marginBlock: "0.5rem",
        borderRadius: "3px",
        opacity: deletedgroups.find((val) => val.name === name) ? 0.5 : 1,
    });

    const onDragEnd = useCallback(
        (result) => {
            // the only one that is required
            const { destination, source } = result;
            if (!destination) {
                return;
            }
            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            let tempdestindex = parseInt(destination.droppableId.split("-")[1]);
            const updatedGroups = [...groups];

            if (source.droppableId === "droppable-1") {
                // Moving from the original list to a group
                setOriginalEmailList((prev) => {
                    const movedEmail = prev.splice(source.index, 1)[0];
                    updatedGroups[tempdestindex - 2].emails.push(movedEmail);
                    console.log("ug:", updatedGroups);
                    setGroups(updatedGroups);
                    return prev;
                });
            } else {
                const [movedEmail] = updatedGroups[source.droppableId.split("-")[1] - 2].emails.splice(source.index, 1);
                updatedGroups[tempdestindex - 2].emails.splice(destination.index, 0, movedEmail);
                setGroups(updatedGroups);
            }
        },
        [groups]
    );

    const totalarr = groups.reduce((total, group) => {
        if (Array.isArray(group.emails)) {
            return [...total, ...group.emails];
        } else {
            return total;
        }
    }, []);

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
        Table: (props) => <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        // find row in rows with all strings, as that will be the header row
        return (
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align={"left"}
                        style={{ width: column.width }}
                        sx={{ backgroundColor: "background.paper" }}
                    >
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => {
                    return (
                        <TableCell key={column.dataKey} align={"left"}>
                            {row[column.dataKey]}
                        </TableCell>
                    );
                })}
            </React.Fragment>
        );
    }

    function uploadSheet(file) {
        console.log("file:", file);
        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            readXlsxFile(file).then((rows1) => {
                // `rows` is an array of rows
                // each row being an array of cells.
                console.log("rows1:", rows1);
                setColumns(
                    rows1[0].filter((row) => row).map((val, index) => ({ width: 100, label: val, dataKey: index }))
                );
                setRows(rows1.slice(2));
                // setGroups(groupdata.map((val) => ({ groupName: val.name, emails: val.students.map((val2) => val2.email),
                //     worksite: { id: val.worksite}, id: val.id
                // })))
                // setGroups()
                setFileUploaded(true);
            });
        } else if (file.type === "text/csv") {
            console.log("csv file");
            Papa.parse(file, {
                header: true,
                complete: function (results) {
                    console.log("results:", results.data);
                    setColumns(Object.keys(results.data[0]).map((val) => ({ width: 100, label: val, dataKey: val })));
                    // filter rows with all empty strings
                    setRows(results.data.filter((row) => Object.values(row).some((val) => val !== "")));
                    // setGroups
                    setFileUploaded(true);
                },
            });
        }
    }

    useEffect(() => {
        console.log("groups changed to:", groups);
    }, [groups]);

    if (isLoading) {
        return (
            <PuffLoader
                color={"#5D87FF"}
                loading={true}
                size={150}
                id="loader"
                style={{ display: "inherit", width: "150px", height: "150px", top: "50vh" }}
            />
        );
    }

    return (
        <div className="w-full">
            {showgroupmatching || groups.length > 0 ? (
                <div className="w-full">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div>
                            <div id="createdrag" className="mt-[10vh] justify-center gap-y-2.5 rounded-xl p-6 w-[30vw]">
                                <input
                                    className="!text-base w-full bg-gray-100 border border-gray-300"
                                    style={{ borderWidth: "0.5px" }}
                                    placeholder="Enter student's email"
                                    value={enteredemail}
                                    onChange={(e) => setEnteredEmail(e.target.value)}
                                />
                                <button
                                    className="actionbtn !mt-2"
                                    onClick={() => {
                                        if (totalarr.includes(enteredemail)) {
                                            toastError("Oops! You've already added that email!");
                                        } else if (studentsindb.includes(enteredemail)) {
                                            toastError("This student already has an account!");
                                        } else {
                                            setOriginalEmailList((prev) => [...prev, enteredemail]);
                                            setSubmitted(true);
                                        }
                                    }}
                                >
                                    Submit
                                </button>
                                <Droppable droppableId="droppable-1">
                                    {(provided1, snapshot1) => (
                                        <div
                                            {...provided1.droppableProps}
                                            ref={provided1.innerRef}
                                            style={getListStyle(snapshot1.isDraggingOver)}
                                        >
                                            {orignialemaillist.map((item, index) => (
                                                <Draggable
                                                    draggableId={`draggable-original-${index}`}
                                                    index={index}
                                                    key={index}
                                                >
                                                    {(provided2, snapshot2) => (
                                                        <div
                                                            ref={provided2.innerRef}
                                                            {...provided2.draggableProps}
                                                            {...provided2.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot2.isDragging,
                                                                provided2.draggableProps.style
                                                            )}
                                                        >
                                                            <h4 className="truncate transition-all duration-300 text-center">
                                                                {item}
                                                            </h4>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided1.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                            <button
                                className="Medium bg-[#5D87FF] py-2 px-4 rounded"
                                style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontFamily: "Plus Jakarta Sans",
                                    fontWeight: "600",
                                    wordWrap: "break-word",
                                    margin: "10px",
                                }}
                                onClick={() =>
                                    setGroups((prev) => [
                                        ...prev,
                                        { name: `Group ${prev.length + 1}`, emails: [], id: null },
                                    ])
                                }
                            >
                                Add Group
                            </button>
                        </div>
                        <div id="groupdropdiv" className="flex flex-row flex-wrap gap-x-[2vw] w-full justify-center">
                            {[...groups, ...deletedgroups].map((group, i) => (
                                <Droppable droppableId={`droppable-${i + 2}`} key={i}>
                                    {(provided1, snapshot1) => (
                                        <div
                                            {...provided1.droppableProps}
                                            ref={provided1.innerRef}
                                            style={getListStyle(snapshot1.isDraggingOver, group.name)}
                                        >
                                            <input
                                                className="text-center bg-transparent"
                                                value={group.name}
                                                onChange={(e) => {
                                                    const updatedGroups = [...groups];
                                                    updatedGroups[i].name = e.target.value;
                                                    setGroups(updatedGroups);
                                                }}
                                            />
                                            {/* between group name change and student emails, add a Select to choose from available instructors */}
                                            <AsyncPaginate
                                                // value={group.worksite}
                                                // value={group.worksite ? { value: group.worksite, label: group.worksite,
                                                value={
                                                    group.instructor
                                                        ? {
                                                              value: group.instructor,
                                                              label: group.instructor,
                                                              // id will be id that matches instructor within loadOptions
                                                              // id: availableworksites.find((val) => val.label === group.worksite).id
                                                              id: availableinstructors.find(
                                                                  (val) => val.label === group.instructor
                                                              ).id,
                                                          }
                                                        : null
                                                }
                                                loadOptions={loadOptions}
                                                onChange={(e) => {
                                                    console.log("e:", e);
                                                    const updatedGroups = [...groups];
                                                    // updatedGroups[i].worksite = e.label;
                                                    updatedGroups[i].instructor = e.label;
                                                    setGroups(updatedGroups);
                                                }}
                                            />
                                            {group.emails.map((item, index) => (
                                                <Draggable
                                                    draggableId={`draggable-group-${i}-${index}`}
                                                    index={index}
                                                    key={index}
                                                >
                                                    {(provided2, snapshot2) => (
                                                        <div
                                                            ref={provided2.innerRef}
                                                            {...provided2.draggableProps}
                                                            {...provided2.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot2.isDragging,
                                                                provided2.draggableProps.style
                                                            )}
                                                        >
                                                            <h4>{item}</h4>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided1.placeholder}
                                            {/* if current group is not in "deletedgroups", show this button  */}
                                            {deletedgroups.find((val) => val.name === group.name) ? (
                                                <button
                                                    className="regbuttons undodeletebuttons my-[1.5vh]"
                                                    onClick={() => {
                                                        // add group to groups before removing from deletedgroups
                                                        // get group using group.name === deletedgroups.name
                                                        setGroups((prev) => [
                                                            ...prev,
                                                            deletedgroups.find((val) => val.name === group.name),
                                                        ]);
                                                        setDeletedGroups((prev) =>
                                                            [...prev].filter((val) => val.name !== group.name)
                                                        );
                                                    }}
                                                >
                                                    Undo Delete
                                                </button>
                                            ) : (
                                                <button
                                                    className="Medium bg-[#5D87FF] py-2 px-4 rounded"
                                                    style={{
                                                        color: "white",
                                                        fontSize: "14px",
                                                        fontFamily: "Plus Jakarta Sans",
                                                        fontWeight: "600",
                                                        wordWrap: "break-word",
                                                    }}
                                                    onClick={() => {
                                                        // add group to deletedgroups before removing from groups
                                                        // get group using group.name === deletedgroups.name
                                                        setDeletedGroups((prev) => [...prev, group]);
                                                        setGroups((prev) =>
                                                            [...prev].filter((val) => val.name !== group.name)
                                                        );
                                                    }}
                                                >
                                                    Delete Group
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            ))}
                        </div>
                    </DragDropContext>
                    <ControlledPopup
                        open={open}
                        setOpen={setOpen}
                        groups={groups}
                        adminName={user?.name}
                        adminEmail={user?.email}
                        adminImg={adminimg}
                        admindata={user}
                        originalgroups={originalgroups}
                        // availableworksites={availableworksites}
                        availableinstructors={availableinstructors}
                    />
                    <button
                        className="Medium bg-[#5D87FF] py-2 px-4 rounded"
                        style={{
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "600",
                            wordWrap: "break-word",
                        }}
                        onClick={async () => {
                            setOpen((o) => !o);
                        }}
                    >
                        Finalize
                    </button>
                    <p style={{ margin: "3px" }}></p>
                    <button
                        className="Medium bg-[#5D87FF] py-2 px-4 rounded"
                        style={{
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: "600",
                            wordWrap: "break-word",
                        }}
                        onClick={async () => {
                            setShowGroupMatching(false);
                        }}
                    >
                        Upload File
                    </button>
                </div>
            ) : fileuploaded ? (
                <div>
                    <Paper style={{ height: "65vh", width: "67vw", marginTop: "12vh" }}>
                        <TableVirtuoso
                            data={rows}
                            components={VirtuosoTableComponents}
                            fixedHeaderContent={fixedHeaderContent}
                            itemContent={rowContent}
                        />
                    </Paper>
                    <div className="flex flex-col items-center justify-center gap-y-2.5 mt-[1.5vh]">
                        <button
                            className="regbuttons my-[1.5vh]"
                            onClick={async () => {
                                setOpen((o) => !o);
                            }}
                        >
                            Finalize
                        </button>
                        <p className="text-center mt-[1.5vh] underline">Upload new file</p>
                        <input
                            type="file"
                            accept=".xlsx,.csv"
                            onChange={async (e) => {
                                uploadSheet(e.target.files[0]);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div id="groupbuttondiv" className="text-center mt-[35vh]">
                    <input
                        type="file"
                        className="w-full"
                        accept=".xlsx,.csv"
                        onChange={async (e) => {
                            uploadSheet(e.target.files[0]);
                        }}
                    />
                    or <br />
                    <button className="regbuttons my-[1.5vh]" onClick={() => setShowGroupMatching(true)}>
                        Create your own
                    </button>
                </div>
            )}
        </div>
    );
}

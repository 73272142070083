import "../../assets/css/admin.css";
import { useContext, useEffect, useState } from "react";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";
import StudentList from "../global/StudentList";

export default function SuperAdminHome({ sidetabvisible }) {
  const [students, setStudents] = useState([]);
  const { user } = useContext(AuthContext);

  async function getStudents() {
    console.log("email is:", user?.email);
    const { data: studentdata, error: studenterror } = await supabase.from(
      "students"
    ).select(`id, name, mentor: mentors(id, name), email,
                studentgroups(worksite: worksites (name), instructor(email)), hours, linked_modules, created_at
            `);

    if (studenterror) console.error("error", studenterror);
    else console.log("student data for SuperAdmin:", studentdata);

    // also need to get all mentors
    const { data: mentordata, error: mentorerror } = await supabase
      .from("mentors")
      .select(
        `id, name, students(studentgroups(worksite: worksites (name))), hours, email, created_at`
      )
      .in(
        "id",
        studentdata
          .map((student) => student.mentor?.id)
          .filter((val) => typeof val === "number")
      );

    if (mentorerror) console.error("error", mentorerror);
    else console.log("mentor data for SuperAdmin:", mentordata);

    const { data: surveydata, error: surveyerror } = await supabase
      .from("surveys")
      .select("title, completed_by");

    if (surveyerror) console.error("error", surveyerror);
    else console.log("survey data for SuperAdmin:", surveydata);

    setStudents(
      [...studentdata, ...mentordata].map((val) => {
        const completedsurveys = surveydata.filter((survey) =>
          survey.completed_by.includes(val.email)
        );
        const allsurveys = surveydata;
        const surveypercent = Math.round(
          (completedsurveys.length / allsurveys.length) * 100
        );
        // console.log("completed surveys:", completedsurveys, "all surveys:", allsurveys, "survey percent:", surveypercent)

        return {
          ID: val.id,
          Name: val.name || "N/A",
          Role: Object.keys(val).includes("mentor") ? "Student" : "Mentor",
          Mentor: Object.keys(val).includes("mentor")
            ? val.mentor
              ? val.mentor.name
              : "N/A"
            : "N/A",
          Worksite: Object.keys(val).includes("mentor")
            ? val.studentgroups?.worksite.name
            : val.students[0].studentgroups?.worksite.name,
          Hours: val.hours,
          Joined: val.created_at,
          "% of Surveys Completed": surveypercent,
          "% of Modules Completed": Object.keys(val).includes("mentor")
            ? val.linked_modules
              ? val.linked_modules.filter((module) => module.completed).length /
                val.linked_modules.length
              : "0"
            : "N/A",
          email: val.email,
        };
      })
    );
  }

  useEffect(() => {
    getStudents();
  }, []);

  return (
    <StudentList
      title={"Student List"}
      page={"SuperAdminHome"}
      students={students}
      getStudents={getStudents}
      sidetabvisible={sidetabvisible}
    />
  );
}

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { toastError } from "../../GlobalFunctions";
import { supabase } from "../../supabase";

export default function InstructorModules() {
    const { user } = useContext(AuthContext);
    const [modules, setModules] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data, error } = await supabase.from("instructors").select("modules").eq("id", user.id).single();
                if (error) {
                    console.error("Error getting instructor:", error);
                    toastError("Error loading modules, please try again later");
                }
                setModules(data.modules);
            } catch (error) {
                console.error("Error listing instructor modules:", error);
                toastError("Error loading modules, please try again later");
            }
        })();
    }, []);

    return (
        <div className="w-full !items-start gap-[2vh]">
            <h1 className=" text-2xl font-bold">Your Modules</h1>
            <div>
                {modules.map((module, index) => (
                    <div key={index}>
                        <h2>{module.title}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
}

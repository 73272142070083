import { supabase } from "./supabase";
import toast from "react-hot-toast";
import { IoMdInformationCircle } from "react-icons/io";

export const baseUrl = "/api";

export async function checkMetadata(setSession, setUser) {
    // returns false user doesn't have role (never onboarding), and their role if they do have one
    const {
        data: { user },
        error,
    } = await supabase.auth.getUser();
    // console.log("user in checkMetadata:", user, "\n\nerror in checkMetadata:", error);
    if (user) {
        // console.log("email:", user.user_metadata.email);
        return {
            role: Object.keys(user.user_metadata).length !== 0 ? user.user_metadata.role : false,
            email: user.user_metadata.email,
            uid: user.id,
        };
    } else {
        if (error) {
            const { data, error } = await supabase.auth.refreshSession();
            const { session, user } = data;
            setSession(session);
            setUser(user);
            // console.log("error data in checkMetadata:", data, "\n\nerror error in checkMetadata:", error,
            //   "\n\nsession in checkMetadata:", session, "\n\nuser in checkMetadata:", user
            // );
            return { error };
        } else {
            return {};
        }
    }
}

export function getTableName(role) {
    if (role !== "SuperAdmin") {
        switch (role) {
            case "Administrator":
                return "administrators";
            case "Instructor":
                return "instructors";
            case "Mentor":
                return "mentors";
            case "Worksite":
                return "worksites";
            case "Business":
                return "businesses";
            case "Student":
                return "students";
            default:
                throw new Error(`Invalid role: ${role}`);
        }
    }
}

export function distance(lat1, lon1, lat2, lon2) {
    // distance between current location of student to the worksite
    const earthRadius = 6371000; // Earth's radius in meters (approximately 6371 km)

    // Convert latitude and longitude from degrees to radians
    const radLat1 = lat1 * (Math.PI / 180);
    const radLon1 = lon1 * (Math.PI / 180);
    const radLat2 = lat2 * (Math.PI / 180);
    const radLon2 = lon2 * (Math.PI / 180);

    // Haversine formula
    const dLat = radLat2 - radLat1;
    const dLon = radLon2 - radLon1;
    const a = Math.sin(dLat / 2) ** 2 + Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in meters

    console.log("distance: " + distance);
    return distance <= 100;
}

/**
 * Backblaze uploader.
 * @param  {Any} file  File to upload.
 * @param  {String} fileName   Directory path to upload to.
 * @param  {String} folderType Type of folder we upload to.
 * @return {Any}
 */
export async function uploadFileToBackblaze(file, fileName, folderType) {
    try {
        let metadata = await checkMetadata();
        // console.log("file is:", file, "\n\nfileName is:", fileName, "\n\nfolderType is:", folderType);
        const sha1Checksum = await calculateSHA1(await readFileContent(file));

        const detailsResponse = await fetch(`${baseUrl}/uploaddetails`);
        if (!detailsResponse.ok) {
            console.error("Failed to get upload details:", detailsResponse.status);
            throw new Error("Failed to get upload details");
        }

        const responseText = await detailsResponse.text();
        // console.log("detailsResponse text:", responseText);

        if (!responseText) {
            console.error("Response body is empty");
            throw new Error("Response body is empty");
        }
        let details = {};
        try {
            details = JSON.parse(responseText);
            // console.log("Parsed details:", details);
            // Use the details as needed
        } catch (error) {
            console.error("Failed to parse JSON:", error);
            throw new Error("Failed to parse JSON");
        }

        const listData = await fetch(`${baseUrl}/listfilenames?fileName=${encodeURIComponent(fileName)}`);
        // console.log("listData:", listData);
        if (!listData.ok) console.log("Failed to get file id:", listData.status);
        else {
            let listDataJson = await listData.json();
            // console.log("listDataJson:", listDataJson);
            if (!listDataJson || !listDataJson.files) {
                console.error("listDataJson is null or does not contain files");
            }

            // Skip file deletion if listDataJson.files is empty
            if (listDataJson.files.length === 0) {
                console.log("No files to delete for fileName:", fileName);
            } else {
                // iterate through listDataJson.files, and delete the file that matches the fileName
                for (let i = 0; i < listDataJson.files.length; i++) {
                    if (listDataJson.files[i].fileName === fileName) {
                        // delete file
                        console.log("fileName:", fileName, "\n\nfileId:", listDataJson.files[0].fileId);
                        const deleteResponse = await fetch(`${baseUrl}/deletefile`, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                fileName: fileName,
                                fileId: listDataJson.files[i].fileId,
                            }),
                        });

                        if (!deleteResponse.ok) {
                            console.error("Failed to delete file:", deleteResponse);
                            throw new Error("Failed to delete file");
                        }
                    }
                }
            }
        }

        // console.log("details before uploadresponse:", details);
        const uploadResponse = await fetch(details.uploadUrl, {
            method: "POST",
            headers: {
                Authorization: details.uploadAuthorizationToken,
                "X-Bz-File-Name": fileName,
                "Content-Type": file.type,
                "Content-Length": file.size.toString(),
                "X-Bz-Content-Sha1": sha1Checksum,
            },
            body: file,
        });

        if (uploadResponse.ok) {
            const data = await uploadResponse.json();
            // console.log("data from response:", data);
            const filelink = `https://f005.backblazeb2.com/file/acewisefiles/${data.fileName}`;
            // console.log("filelink:", filelink);

            if (folderType === "profileimages") {
                // update user as well as AuthContext
                await supabase.auth.updateUser({ data: { imgurl: filelink } });
                const { error } = await supabase
                    .from(getTableName(metadata.role))
                    .update({ imgurl: filelink })
                    .match({ email: metadata.email });
                if (error) {
                    console.error("Error inserting file into database:", error);
                    throw new Error("Error inserting file into database");
                }
            }
            return { link: filelink };
        } else {
            console.error("File upload failed:", uploadResponse.status);
            // Handle the error here
            throw new Error("File upload failed");
        }
    } catch (error) {
        console.error("Error during file upload:", error);
        // Return false on error
        return false;
    }
}

/**
 * Checks if a directory exists in Backblaze B2.
 * @param {String} directoryPath The path of the directory to check.
 * @return {Promise<Boolean>} True if the directory exists, false otherwise.
 */
export async function directoryExistsInBackblaze(directoryPath) {
    try {
        const listDataResponse = await fetch(
            `${baseUrl}/listFileNames?prefix=${encodeURIComponent(directoryPath)}&delimiter=/`
        );
        if (!listDataResponse.ok) {
            console.error("Failed to list files:", listDataResponse.statusText);
            return false; // Could not determine if directory exists, so conservatively returning false
        }
        return true;
    } catch (error) {
        console.error("Error checking directory existence:", error);
        return false; // Error occurred, conservatively returning false
    }
}

async function calculateSHA1(fileContent) {
    return new Promise((resolve, reject) => {
        // Convert the ArrayBuffer to a Uint8Array
        const data = new Uint8Array(fileContent);

        // Calculate the SHA-1 hash
        const crypto = window.crypto.subtle || window.crypto.webkitSubtle;
        crypto
            .digest("SHA-1", data)
            .then((hashBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
                resolve(hashHex);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function readFileContent(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (event) => {
            reject(event.target.error);
        };

        reader.readAsArrayBuffer(file);
    });
}

export function toastSuccess(message, options) {
    toast.success(message, {
        position: "top-center",
        style: {
            background: "#61d345",
            color: "#fff",
        },
        iconTheme: {
            primary: "#fff",
            secondary: "#61d345",
        },
        ...options,
    });
}

export function toastError(message) {
    toast.error(message, {
        position: "top-center",
        style: {
            background: "#ff4b4b",
            color: "#fff",
        },
        iconTheme: {
            primary: "#fff",
            secondary: "#ff4b4b",
        },
    });
}

export async function toastPromise(promisefunc, loading, success) {
    try {
        toast.loading(loading, { duration: Infinity });
        await promisefunc;
        toast.dismiss();
        toast.success(success || "Done!", {
            position: "top-center",
            style: {
                background: "#61d345",
                color: "#fff",
            },
            iconTheme: {
                primary: "#fff",
                secondary: "#61d345",
            },
        });
    } catch (error) {
        toast.dismiss();
        toast.error(error || `Something went wrong`, {
            position: "top-center",
            style: {
                background: "red",
                color: "#fff",
            },
            iconTheme: {
                primary: "#fff",
                secondary: "red",
            },
        });
    }
}

export async function toastInfo(message) {
    toast(message, {
        position: "top-center",
        style: {
            background: "#5D87FF",
            color: "#fff",
        },
        iconTheme: {
            primary: "#fff",
            secondary: "#5D87FF",
        },
        icon: <IoMdInformationCircle size={30} />,
        duration: 7500,
    });
}

export async function checkOnboarding(role, email) {
    if (email !== "nosd509@gmail.com") {
        console.log("IN ONBOARDING\nrole:", role, "\nemail:", email);

        // Check if the student exists in the table
        const { data: existingStudent, error: checkError } = await supabase
            .from(getTableName(role))
            .select("email")
            .eq("email", email)
            .single();

        if (checkError && checkError.code === "PGRST116") {
            // Insert the student if they do not exist
            const { error: insertError } = await supabase.from(getTableName(role)).insert({ email });

            if (insertError) {
                toastError("Error inserting student email: " + insertError.message);
                console.error("Error inserting student email:", insertError.message);
                return false;
            }
        } else if (checkError) {
            toastError("Error checking student email: " + checkError.message);
            console.error("Error checking student email:", checkError.message);
            return false;
        }

        // Check onboarding answers
        const { data, error } = await supabase.from(getTableName(role)).select("onboardinganswers").eq("email", email);

        if (error) {
            toastError("Error getting onboarding answers: " + error.message);
            console.error("Error getting onboarding answers:", error.message);
            return false;
        } else {
            if (data[0].onboardinganswers === null) {
                // no onboarding answers
                return false;
            } else {
                // onboarding answers
                return true;
            }
        }
    } else {
        return true;
    }
}

/**
 *
 * @param {*} email
 * @param {*} password
 * @returns
 */
export async function storePassword(email, password) {
    const db_data = await fetch(`${baseUrl}/storepassword`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
    });
    if (db_data.status === 200) {
        console.log("Password stored successfully");
        return true;
    } else {
        console.error("Error storing password:", db_data);
        return false;
    }
}

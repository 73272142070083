import { supabase } from "../../supabase";
import { toastError } from "../../GlobalFunctions";

let options = [];

// async function getWorksites() {
//     const { data, error } = await supabase.from("worksites").select("id, name")
//     if (error) {
//         console.error(error)
//         toastError("Error getting Worksites")
//     } else {
//         // console.log("worksite data:", data)
//         options = data.map((val) => ({ value: val.name, label: val.name, id: val.id }))
//     }
// }
// await getWorksites();
(async function getInstructors() {
  const { data, error } = await supabase.from("instructors").select("id, name");
  if (error) {
    console.error(error);
    toastError("Error getting Instructors");
  } else {
    // console.log("instructor data:", data)
    options = data.map((val) => ({
      value: val.name,
      label: val.name,
      id: val.id,
    }));
  }
})();

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

export const loadOptions = async (search, prevOptions) => {
  await sleep(Math.min(options.length * 10, 1000));

  let filteredOptions = [];
  if (!search) {
    filteredOptions = options;
  } else {
    filteredOptions = options.filter(({ label }) =>
      label.toLowerCase().includes(search.toLowerCase())
    );
  }

  return {
    options: filteredOptions.slice(prevOptions.length, prevOptions.length + 10),
    hasMore: filteredOptions.length > prevOptions.length + 10,
  };
};

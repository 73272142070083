import React, { useContext, useState } from "react";
import "../assets/css/signupscreen.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../App.js";
import { toastError } from "../GlobalFunctions.js";

export default function SignupScreen() {
  const { uuid } = useParams();
  const { search } = useLocation();
  const userEmail = new URLSearchParams(search).get("email");
  const navigate = useNavigate();
  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const { register } = useContext(AuthContext);

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      toastError("Passwords do not match!");
      return;
    }
    if (
      ["learn45", "4mmZ6aDlEA", "6NqDhMziAt", "mentor45"].includes(inviteCode)
    ) {
      const success = await register(
        email,
        password,
        uuid,
        inviteCode === "learn45"
          ? "Student"
          : inviteCode === "4mmZ6aDlEA"
          ? "Administrator"
          : inviteCode === "mentor45"
          ? "Mentor"
          : inviteCode === "6NqDhMziAt"
          ? "Instructor"
          : "Mentor" // Default to Instructor if none of the above match
      );
      if (success) {
        navigate("/onboarding", {
          state: {
            givenrole:
              inviteCode === "learn45"
                ? "Student"
                : inviteCode === "4mmZ6aDlEA"
                ? "Administrator"
                : inviteCode === "mentor45"
                ? "Mentor"
                : inviteCode === "6NqDhMziAt"
                ? "Instructor"
                : "Mentor", // Default to Instructor if none of the above match
          },
        });
      }
    } else {
      toastError("Invalid invite code!");
      return;
    }
  };

  return (
    <div className="justify-center authdiv authbox mx-auto my-0">
      <img
        src={require("../icons/acewise.png")}
        alt="Acewise"
        width={150}
        height={150}
      />
      <p id="createacc">Create an Account</p>
      <form className="authform" onSubmit={(e) => e.preventDefault()}>
        <input
          id="emailinput"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="email"
          autoCapitalize="none"
          autoCorrect="off"
          disabled={!!userEmail}
        />
        <input
          id="passwordinput"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <input
          id="confirmpasswordinput"
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <input
          id="invitecodeinput"
          type="text"
          placeholder="Invite Code"
          onChange={(e) => setInviteCode(e.target.value)}
        />
        <input
          type="button"
          className="actionbtn"
          onClick={handleSignUp}
          value="Sign Up"
        />
      </form>

      <p className="mt-4 font-small flex">Already have an account?</p>
      <button
        id="signinbutton"
        className="actionbtn"
        onClick={() => navigate("/signin")}
      >
        Log In
      </button>
      <p className="mt-4 font-small flex">
        {" "}
        This platform is for invited users only. Go to acewise.app/contact to
        request access to our platform for yourself or your organization.
      </p>
    </div>
  );
}

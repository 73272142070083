import { formatDistanceToNow } from "date-fns"
import { useContext, useEffect, useState } from "react"
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoEyeOutline } from "react-icons/io5";
import { GoComment } from "react-icons/go";
import { FaRegThumbsUp } from "react-icons/fa6";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";
import { GlobalStyles } from "../../GlobalStyles";
import '../../assets/css/forum.css'

export default function Forum() {
    const { user } = useContext(AuthContext)
    const [filter, setFilter] = useState("New")
    const [cards, setCards] = useState([])

    useEffect(() => {
        const fetchCards = async () => {
            // const { data: studentdata, error: studenterror } = await supabase
            //     .from("students")
            //     .select("*")
            //     .eq("email", user?.email)
            //     .single()
            // if (studenterror) {
            //     console.error(studenterror)
            // } else {
                // console.log("forum studentdata: ", studentdata)
                const { data, error } = await supabase.from("forum").select("*")
                    .eq("studentgroup", user?.group)
                if (error) {
                    console.error(error)
                } else {
                    console.log("forum data: ", data)
                    setCards(data)
                }
            // }
        };
    
        fetchCards();
    
        supabase.channel('room1')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'forum' }, async payload => {
                console.log('Change received!', payload)
                await fetchCards();
            })
            .subscribe()
    }, [])

    return (
        <div id="forumdiv" className="">
            <div className="!flex-row justify-between !items-center gap-[1vw] my-[8vh]">
                {["New", "Top", "Hot", "Closed"].map((tab, index) => (
                    <button key={index} className="px-3 rounded-full" onClick={() => setFilter(tab)} style={{
                        backgroundColor: filter === tab ? "#1682FD" : "#EAEAEA", color: filter === tab ? "white" : "#808080"
                    }}>
                        {tab}
                    </button>
                ))}
            </div>
            <div className="gap-[5vh]">
                {cards
                    // if filter is New, sort by date in descending order (supabase returns "created_at" as timestamptz)
                    // if filter is Top, sort by likes in descending order

                    .sort((a, b) =>
                        filter === "New" ?
                            new Date(b.created_at) - new Date(a.created_at) :
                            filter === "Top" ?
                                b.likes - a.likes :
                                filter === "Hot" &&
                                    b.views - a.views
                    )
                    .map((card, index) => (
                        <div key={index} className="flex flex-col gap-[1.5vh] customshadow p-6 !items-start rounded-md w-full">
                            <div className="!flex-row justify-between w-full">
                                <div className="gap-[1vw] !flex-row items-center forumicontext">
                                    <img src={card.user_pfp} className="h-[8vh]" alt="Profile"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src="https://cdn-icons-png.flaticon.com/512/64/64572.png"
                                        }} 
                                    />
                                    <div className="flex flex-col !items-start">
                                        <p>{card.user_name}</p>
                                        <p className="text-xs" style={{ color: GlobalStyles.subtitle }}>{formatDistanceToNow(new Date(card.created_at), { addSuffix: true })}</p>
                                    </div>
                                </div>
                                <button>
                                    <BsThreeDotsVertical size={20} color={GlobalStyles.subtitle} />
                                </button>
                            </div>
                            <p className="font-bold text-left">{card.title}</p>
                            <p className="text-left">{card.body}</p>
                            <div className="!flex-row justify-between w-full carddiv !items-start">
                                <div className="!items-start gap-[1vw]">
                                    {card.tags.map((tag, index) => (
                                        <div style={{ color: GlobalStyles.subtitle, backgroundColor: GlobalStyles.background }}
                                            className="rounded-md px-3 py-1 text-sm" key={index}
                                        >{tag}</div>
                                    ))}
                                </div>
                                <div className="!flex-row gap-[1vw] forumicons">
                                    <div>
                                        <IoEyeOutline /> 
                                        <p>{card.views}</p>
                                    </div>
                                    <div>
                                        <GoComment />
                                        <p>{card.comments.length}</p>
                                    </div>
                                    <div>
                                        <FaRegThumbsUp />
                                        <p>{card.likes}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
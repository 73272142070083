import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import '../../assets/css/popup.css'
import emailjs from '@emailjs/browser';
import { toastError, toastPromise } from '../../GlobalFunctions';
import { supabase } from '../../supabase';

export default function ControlledPopup({ adminName, initmessage, open, setOpen, groups, admindata, originalgroups,
  // availableworksites
  availableinstructors
}) {
  console.log("originalgroups:", originalgroups)
  const [text, setText] = useState(initmessage);

  const closeModal = () => setOpen(false);

  return (
    <div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <label htmlFor='message'>Message</label>
        <textarea className='w-10/12' placeholder='Message' id='message' value={text} onChange={e => setText(e.target.value)}></textarea>
        <button className='regbuttons my-[1.5vh] w-1/2' onClick={async() => {
          toastPromise(new Promise(async (resolve, reject) => {
            // get all emails currently in students table
            const { data:emails, error:emailerror } = await supabase.from("students").select("email")
            if (emailerror) {
              console.error("Error getting emails:", emailerror)
              reject(emailerror)
            }
            for (const group of groups) {
              // update group if id isn't null, insert if it is
              console.log("upserting uuid:", group.uuid, "admin:", admindata.email, "name:", group.name, "worksite:", group.worksite, 
                "found in availableworksites:", 
                // availableworksites.find(ws => ws.label === group.worksite).id
                availableinstructors.find(ws => ws.label === group.worksite).id
              )
              const { data:groupdata, error:grouperror } = await supabase.from('studentgroups')
              // worksite will be id of worksite name in availableworksites
                .upsert({ uuid: group.uuid, admin: admindata.email, name: group.name,
                  worksite:
                  // availableworksites.find(ws => ws.label === group.worksite).id
                  availableinstructors.find(ws => ws.label === group.worksite).id
                }, { onConflict: "uuid" })
                .select("uuid")

              if (grouperror) {
                console.error("Error creating group:", grouperror)
                reject(grouperror)
              } else {
                console.log("groupdata:", groupdata)
                // ADD SUPABASE CODE HERE (instantiate studentgroup and link to admin in admin table)

                for (const email of group.emails) {
                  // only want to send emails to new emails and emails whose group has changed
                  // so use originalgroups to compare
                  // if the current email is in the same group as it was before, don't send an email
                  // also, if the current email is not in the originalgroups, send an email
                  // if current email is in emails, don't send an email
                  if (emails.find(e => e.email === email)) {
                    console.log("email already in students:", email)
                    continue
                  }
                  console.log("sending email to:", email)
                  // to get here, the email is either not in the originalgroups or it's in a different group
                  const emailresponse = await emailjs.send(process.env.REACT_APP_SERVICEID, process.env.REACT_APP_TEMPLATEID, {
                    subject: `Invitation to ${group.name} from ${adminName}`, to_email: email, invitedByName: adminName,
                    invitedByEmail: admindata.email, groupName: group.name, body: text,
                    inviteLink: `https://platform.acewise.app/join/studentgroup/${groupdata[0].uuid}?email=${email}`,
                    userImage: admindata.imgurl
                  }, process.env.REACT_APP_EMAILJS_PUBLICKEY);
                  // add row to students with "email" and "group"
                  const { error } = await supabase.from("students")
                    .upsert({ email: email, group: groupdata[0].uuid }, { onConflict: "email" })
                    .select("email")
                  if (error) {
                    console.error("Error adding student:", error)
                    reject(error)
                  }
                  // send emails AFTER groups are created so we can obtain access to groups' unique links
                  // console.log(emailresponse);
                  if (emailresponse.status !== 200) {
                    toastError("Email not sent to", email)
                    reject("Email not sent to", email)
                  }
                  // wait 1 second before going to next iteration, per emailjs documentation
                  await new Promise(r => setTimeout(r, 1000));
                }
                resolve(true)
                setOpen(false)
              }
            }}),
            "Sending"
          )
        }}>
          Send
        </button>
      </Popup>
    </div>
  );
};
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import Popup from "reactjs-popup";
import { supabase } from "../supabase";
import { getTableName, toastError, toastSuccess } from "../GlobalFunctions";

export default function Settings({ sidetabvisible, setSession }) {
  const { user, setUser, logout } = useContext(AuthContext);
  const [deletingaccount, setDeletingAccount] = useState(false);
  const [confirmdelete, setConfirmDelete] = useState("");
  const [groupName, setGroupName] = useState("");

  const handleGroupChange = async () => {
    const { data: groupData, error: groupError } = await supabase
      .from('studentgroups')
      .select('uuid')
      .eq('name', groupName)
      .single();

    if (groupError) {
      toastError("Error fetching group: " + groupError.message);
      console.error("Error fetching group:", groupError);
      return;
    }

    const groupUuid = groupData.uuid;

    const { error: updateError } = await supabase
      .from('students')
      .update({ group: groupUuid })
      .eq('email', user?.email);

    if (updateError) {
      toastError("Error updating group: " + updateError.message);
      console.error("Error updating group:", updateError);
    } else {
      toastSuccess("Group updated successfully!");
      setUser((prev) => ({ ...prev, group: groupUuid }));
    }
  };

  return (
    <div className=" self-start gap-[2vh] !items-start">
      <h1 className=" text-2xl font-bold">Settings</h1>
      <div className="flex !flex-row gap-[1vw] items-center">
        <input
          className="border-2 border-gray-300 rounded-lg p-1"
          type="text"
          placeholder="Username"
          value={user?.username}
          onChange={(e) => setUser((prev) => ({ ...prev, username: e.target.value }))}
        />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
          onClick={async () => {
            const { error } = await supabase
              .from(getTableName(user?.role))
              .update({ username: user?.username })
              .eq("email", user?.email);
            if (error) {
              toastError("Error setting username: " + error.message);
              console.error("Error setting username:", error);
            } else {
              toastSuccess("Username set successfully!");
            }
          }}
        >
          Set Username
        </button>
      </div>
      <div className="flex !flex-row gap-[1vw] items-center">
        <input
          className="border-2 border-gray-300 rounded-lg p-1"
          type="text"
          placeholder="Group Name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
          onClick={handleGroupChange}
        >
          Set Group
        </button>
      </div>
      <button
        className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
        onClick={() => setDeletingAccount(true)}
      >
        Delete Account
      </button>
      <Popup
        open={deletingaccount}
        closeOnDocumentClick
        onClose={() => setDeletingAccount(false)}
        className="flex flex-col items-center justify-center"
        overlayStyle={{
          background: "rgba(0,0,0,0.5)",
          marginLeft: sidetabvisible ? "25vw" : 0,
          width: sidetabvisible ? "75vw" : "100vw",
        }}
      >
        <p className="mb-2 text-lg">Are you sure you want to delete your account?</p>
        <p className="mb-2 text-sm">
          Type<span className=" font-bold text-md">Delete Account</span>to confirm
        </p>
        <label htmlFor="confirmDelete" className="sr-only">
          Confirm Delete
        </label>
        <input
          alt="ConfirmDelete"
          id="confirmDelete"
          type="text"
          className="border-2 border-gray-300 rounded-lg p-1"
          onChange={(e) => setConfirmDelete(e.target.value)}
        />
        <button
          id="deleteAccount"
          onClick={async () => {
            if (confirmdelete === "Delete Account") {
              await logout(true);
            }
          }}
          className="bg-red-600 my-2 text-[1rem] rounded-lg shadow-2xl shadow-cyan-500/50 !px-[1vw] !py-1 text-white"
        >
          Delete
        </button>
      </Popup>
    </div>
  );
}
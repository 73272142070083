import { ReactComponent as CommentIcon } from '../../icons/comment.svg'
import { ReactComponent as CommentArrow } from '../../icons/commentarrow.svg'

export default function ProductGoal({ num, percent }) {
    return (
        <div id='productgoal' className='p-[2vh] bg-[#5D87FF] flex flex-col rounded-xl text-white justify-between items-center w-full'>
            <div className='flex !flex-row items-center justify-between w-full'>
                <div id='leftgoal' className='flex flex-col items-start'>
                    <div id="productnum">{num ? num : "Complete Tasks"}</div>
                    <div>to reach attendance goal</div>
                </div>
                <button className='p-1 h-[34px] rounded' style={{ backgroundColor: "rgba(255, 255, 255, 0.10)"}}>
                    <CommentIcon />
                </button>
            </div>
            {percent && (
                <div className="Group145" style={{width: "100%", height: 34, position: 'relative'}}>
                    <div className="Frame131 w-full" style={{height: 6, left: 0, top: 28, position: 'absolute', background: 'rgba(255, 255, 255, 0.20)', borderRadius: 100,
                        flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'
                    }}>
                        <div className="Rectangle126" style={{width: `${percent}%`, height: 6, background: 'white', borderRadius: 100}} />
                    </div>
                    <div className="Frame1000002770" style={{ height: 24, left: `${0.738461538 * (percent ? percent : 0)}%`, top: 0, position: 'absolute', flexDirection: 'column',
                        justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'
                    }}>
                        <div className="Frame1000002769" style={{height: 20, paddingLeft: 8, paddingRight: 8, background: 'white', borderRadius: 4, justifyContent: 'center',
                            alignItems: 'center', display: 'inline-flex'
                        }}>
                            <div style={{color: '#5D87FF', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', lineHeight: 16.80, wordWrap: 'break-word'}}>{percent}%</div>
                        </div>
                        <CommentArrow />
                    </div>
                </div>
            )}
        </div>
    )
}
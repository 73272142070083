import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import { toastPromise } from "../../GlobalFunctions";
import { AuthContext } from "../../App";
import '../../assets/css/studentsurvey.css'
import StudentUploadCard from '../../components/student/StudentUploadCard'; // Import the StudentUploadCard component

export default function StudentSurvey() {
    const { surveyid } = useParams();
    const [survey, setSurvey] = useState({});
    const [answers, setAnswers] = useState({});
    const { user, todo, setTodo } = useContext(AuthContext)
    const [submitting, setSubmitting] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        (async() => {
            const { data, error } = await supabase.from('surveys')
                .select('*').eq('id', surveyid)
                .single()
            
            if (error) {
                console.error(error.message)
            } else {
                console.log("survey data", data)
                setSurvey(data)
            }
        })()
    }, [])

    useEffect(() => {
        console.log("answers", answers)
    }, [answers])

    return (
        <div className="!self-start ml-[2vw] mb-[5vh]">
            <div className="leftstudentsurvey !items-start gap-[2vh]">
                <p className="font-semibold" style={{ color: '#CBCBCB' }}>{new Date(survey.created_at).toLocaleDateString('en-US', 
                    { year: 'numeric', month: 'long', day: 'numeric' })}
                </p>
                <h1 className=" text-3xl font-bold" style={{ color: '#686868'}}>{survey.title}</h1>
                <div style={{ backgroundColor: "#F1F1F1" }} className="p-4 rounded-xl !items-start">
                    <p className=" font-semibold">This survey will help customize your experience</p>
                    <p>Please complete this document to help us see your progress.</p>
                </div>
                {/* iterate through each question in data.data array */}
                {/* [
                    {
                        "id": 1711601122050,
                        "text": "What career industry are you interested in",
                        "type": "mccheckbox",
                        "options": [
                            {
                                "id": 1711601157817,
                                "text": "Business"
                            },
                            {
                                "id": 1711601163615,
                                "text": "Tech"
                            }
                        ],
                        "explainer": "What is the "
                    }
                ] */}
                {/* multiplechoice, mccheckbox, freeresponse, ratingscale */}
                {survey.data && survey.data.map((question, index) => {
                    return (
                        <div key={index} className=" !items-start gap-[1vh]">
                            <p className=" font-bold">{index + 1}. {question.text}</p>
                            {question.explainer && (
                                <p className=" italic" style={{ color: "#686868"}}>Details: {question.explainer}</p>
                            )}
                            {question.type === 'mccheckbox' ? (
                                question.options.map((option, i) => {
                                    return (
                                        <div key={i} className=" !flex-row justify-center gap-[.375vw]">
                                            <input type="checkbox" id={option.id} name={option.text} value={option.text}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setAnswers({ ...answers, [question.id]: [...(answers[question.id] || []), option.text] })
                                                    } else {
                                                        setAnswers({ ...answers, [question.id]: (answers[question.id] || [])
                                                            .filter(answer => answer !== option.text) })
                                                    }
                                                }}
                                            />
                                            <label htmlFor={option.id}>{option.text}</label>
                                        </div>
                                    )
                                })
                            ) : question.type === 'multiplechoice' ? (
                                question.options.map((option, i) => {
                                    return (
                                        <div key={i} className="!flex-row justify-center gap-[.375vw]"
                                            onClick={() => setAnswers({ ...answers, [question.id]: option.text })}
                                        >
                                            <input type="radio" id={option.id} name={question.text} value={option.text} />
                                            <label htmlFor={option.id}>{option.text}</label>
                                        </div>
                                    )
                                })
                            ) : question.type === 'freeresponse' ? (
                                <textarea className="w-full p-2 rounded-lg" style={{ border: "2px solid #686868", boxShadow: '0px 0px 4px #686868' }}
                                    id={question.id} name={question.text} onChange={(e) => setAnswers({ ...answers, [question.id]: e.target.value })}
                                    value={answers[question.id] || ""}
                                />
                            ) : question.type === 'ratingscale' ? (
                                <div className="!flex-row w-full gap-[1vw]">
                                    {Array.from({ length: 5 }, (_, i) => i + 1).map((rating, i) => {
                                        return (
                                            <div style={{ border: `2px solid ${answers[question.id] !== rating ? "#686868" : "white"}`,
                                                backgroundColor: answers[question.id] === rating ? '#138808' : 'transparent',
                                                color: answers[question.id] === rating ? 'white' : 'black'
                                            }}
                                                className="scalechoices w-1/5 rounded-lg px-[1vw] py-[1vh] font-bold" key={i} onClick={() => {
                                                    setAnswers({ ...answers, [question.id]: rating })
                                                }}
                                            >
                                                {rating}
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}
                        </div>
                    )
                })}
                <button className="bg-[green] text-white font-semibold p-2 rounded-lg mb-[2vh]" onClick={async() => {
                    toastPromise(new Promise(async(resolve, reject) => {
                        setSubmitting(true)
                        // check if all questions are answered
                        if (survey.data.length !== Object.keys(answers).length) {
                            // also check if any free response questions are empty
                            setSubmitting(false)
                            console.log("length of: ", Object.keys(answers).length, survey.data.length)
                            reject("Please answer all questions")
                            return
                        }
                        // get surveys.responses and surveys.completed_by again
                        const { data, error } = await supabase.from('surveys')
                            .select('responses, completed_by').eq('id', surveyid)
                            .single()
                        const { error: updateerror } = await supabase.from('surveys')
                            .update({ completed_by: [...(data.completed_by || []), user?.email],
                                responses: [...(data.responses || []), { studentemail: user?.email, answers } ]
                            })
                            .eq('id', surveyid)

                        if (updateerror) {
                            console.error(updateerror.message)
                            setSubmitting(false)
                            reject()
                        } else {
                            setSubmitting(false)
                            // remove current survey from todo
                            console.log("surveyid", surveyid)
                            setTodo(previoustodo => previoustodo.filter(survey => survey.id !== parseInt(surveyid)))
                            navigate('/')
                            resolve()
                        }
                    }), "Submitting Survey")}}
                    disabled={submitting}
                >
                    Submit
                </button>
            </div>
            {/* Add the StudentUploadCard component at the bottom */}
            <StudentUploadCard />
        </div>
    )
}
import { forwardRef, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import ReactDatePicker from "react-datepicker";
import { supabase } from "../../supabase";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

export default function ViewDailyReports() {
    const { user } = useContext(AuthContext);
    const [instructors, setInstructors] = useState([]);
    const [filteredInstructors, setFilteredInstructors] = useState(
        JSON.parse(sessionStorage.getItem("instructors_with_reports")) || []
    );
    const [searchquery, setSearchQuery] = useState("");
    const [selecteddate, setSelectedDate] = useState(new Date());
    const [allreports, setAllReports] = useState({});
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 8;

    useEffect(() => {
        (async () => {
            const storedDate = sessionStorage.getItem("selecteddate");
            if (storedDate) {
                console.log("stored date:", new Date(storedDate));
                setSelectedDate(new Date(storedDate));
            }
            const { data: instructordata, error: instructorerror } = await supabase
                .from("instructors")
                .select(
                    `id, name, studentgroups!studentgroups_instructor_fkey(worksite: worksites (name), admin: administrators(*)),
                    email, admin, reports`
                )
                // UNCOMMENT BEFORE COMMITTING
                .eq("admin", user?.email);

            if (instructorerror) console.error("error", instructorerror);
            else {
                console.log("instructor data for admin:", instructordata);
                setInstructors(instructordata);

                for (const instructor of instructordata) {
                    const reports = instructor.reports || [];
                    if (Array.isArray(reports) && reports.length > 0) {
                        for (const report of reports) {
                            const date_as_string = new Date(report.date).toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                            });
                            setAllReports((prev) => ({
                                ...prev,
                                [date_as_string]: {
                                    ...(prev[date_as_string] || {}),
                                    [instructor.email]: report,
                                },
                            }));
                        }
                    }
                }
            }
        })();
    }, []);

    // useEffect(() => {
    //     console.log("all reports:", allreports);
    // }, [allreports]);

    useEffect(() => {
        const filtered = instructors.filter((instructor) => {
            const reports =
                allreports[
                    selecteddate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })
                ] || {};
            return reports[instructor.email];
        });
        setFilteredInstructors(filtered);
    }, [allreports, selecteddate, instructors]);

    const pageCount = Math.ceil(filteredInstructors.length / itemsPerPage);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredInstructors.slice(itemOffset, endOffset);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredInstructors.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    const CustomStartInput = forwardRef(({ value, onClick }, ref) => (
        <button
            type="button"
            id="datepick"
            className="buttons text-black flex flex-row items-center justify-center p-3 rounded-lg customshadow gap-2"
            style={{ border: "1px solid #D5D9DD" }}
            onClick={onClick}
            ref={ref}
        >
            <CalendarIcon />
            {selecteddate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })}
        </button>
    ));

    return (
        <div id="moduletablediv" className="w-full">
            <div className="studentlisttitle !flex-row !justify-between items-center w-full flex under769:!flex-col under769:gap-[2vh]">
                <p id="matchestitle">Daily Reports</p>
                {/* Search Bar */}
                <div id="matchsearch" className="flex !flex-row gap-2 items-center w-[20vw] under769:w-full">
                    <SearchIcon />
                    <input
                        type="text"
                        placeholder="Quick Search..."
                        className="w-full"
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <ReactDatePicker
                    className="w-10"
                    selected={selecteddate}
                    onChange={(date) => {
                        setSelectedDate(date);
                        sessionStorage.setItem("selecteddate", date);
                    }}
                    customInput={<CustomStartInput />}
                    popperPlacement="bottom"
                    maxDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
            <div className="w-full bg-[#D5D9DD] h-[1.5px] !my-5"></div>
            <div className="overflow-x-auto w-full gap-3">
                <Table className="w-full">
                    <Thead>
                        <Tr id="matchhead" className="">
                            <Th>Name</Th>
                            <Th>Report</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems &&
                            (currentItems.filter(
                                (val) =>
                                    searchquery === "" || val["Name"].toLowerCase().includes(searchquery.toLowerCase())
                            ).length > 0 ? (
                                currentItems
                                    .filter(
                                        (val) =>
                                            searchquery === "" ||
                                            val["Name"].toLowerCase().includes(searchquery.toLowerCase())
                                    )
                                    .map((val, idx) => {
                                        // console.log("val:", val);
                                        return (
                                            <Tr key={idx}>
                                                <Td>{val.name}</Td>
                                                <Td>
                                                    <Link
                                                        to={`/dailyreports/${selecteddate
                                                            .toLocaleDateString("en-US", {
                                                                month: "2-digit",
                                                                day: "2-digit",
                                                                year: "2-digit",
                                                            })
                                                            .replace(/\//g, "")}/${val.id}`}
                                                        state={{
                                                            report: allreports[
                                                                selecteddate.toLocaleDateString("en-US", {
                                                                    month: "long",
                                                                    day: "numeric",
                                                                    year: "numeric",
                                                                })
                                                            ][val.email],
                                                            name: val.name,
                                                        }}
                                                        className=" text-blue-700 underline viewresults"
                                                    >
                                                        View Report
                                                    </Link>
                                                </Td>
                                            </Tr>
                                        );
                                    })
                            ) : (
                                <Tr>
                                    <Td colSpan={8} className="text-center w-full text-xl font-semibold pt-[4vh]">
                                        No reports found for{" "}
                                        {selecteddate.toLocaleDateString("en-US", {
                                            month: "long",
                                            day: "numeric",
                                            year: "numeric",
                                        })}
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </div>
            <ReactPaginate
                className="react_paginate flex items-center gap-[1vw] mt-[2vh]"
                breakLabel="..."
                nextLabel={
                    <button className="flex items-center justify-center !p-0">
                        <IoIosArrowRoundForward color="black" size={26} />
                    </button>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                previousLabel={
                    <button className="flex items-center justify-center !p-0">
                        <IoIosArrowRoundBack color="black" size={26} />
                    </button>
                }
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination !mt-5"
                activeClassName="active"
                renderOnZeroPageCount={null}
                pageCount={pageCount}
            />
        </div>
    );
}

import { XAxis, YAxis, Label, Tooltip, AreaChart, Area } from "recharts";
import Infographic from "../../icons/infog.png";
import "../../assets/css/admin.css";
import { forwardRef, useEffect, useState } from "react";
import GradientSVG from "../admin/GradientSVG";
import ProductGoal from "../global/ProductGoal";
import CompletionProgress from "../admin/CompletionProgress";
import { supabase } from "../../supabase";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as SmallArrowIcon } from "../../assets/icons/smallarrow.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import ReactPaginate from "react-paginate";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { LuSettings2 } from "react-icons/lu";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "react-responsive";
import StudentList from "../global/StudentList";

export default function AdminHome({ email, sidetabvisible }) {
    const [students, setStudents] = useState([]);
    const [channel, setChannel] = useState(null);
    const [passedpic, setPassedPic] = useState(null);
    const [isDeptRotated, setDeptRotated] = useState(false);
    const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.75 - 2);

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 8;
    const pageCount = Math.ceil(students.length / itemsPerPage);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = students.slice(itemOffset, endOffset);

    const data = [
        // {week: null, points: null},
        { week: "Week 1", points: 2.75 },
        { week: "Week 2", points: 2 },
        { week: "Week 3", points: 3.6 },
        { week: "Week 4", points: 2 },
    ];

    useEffect(() => {
        const handleResize = () => {
            // Calculate the desired width
            let desiredWidth = window.innerWidth * 0.7485 - 2; // 75% of window width minus 2px
            desiredWidth = desiredWidth * 0.6; // 66% of the calculated width

            // console.log("desiredWidth", desiredWidth);
            // Set the state
            setChartWidth(desiredWidth);
        };

        window.addEventListener("resize", handleResize);

        // Call the handler right away so that the state gets updated with initial window size
        handleResize();

        async function getStudents() {
            console.log("email is:", email);
            // get worksite name from student.group (uuid) === studentgroups.uuid && studentgroups.worksite === worksites.id
            // worksites is referenced by studentgroups.worksite
            // studentgroups is referenced by students.group
            // admin is referenced by studentgroups.admin

            // need to get all students
            const { data: studentdata, error: studenterror } = await supabase
                .from("students")
                .select(
                    `email, id, name, mentor: mentors(id, name), studentgroups(worksite: worksites (name)), hours, linked_modules, created_at`
                )
                .eq("studentgroups.admin", email);

            if (studenterror) console.log("error", studenterror);
            else console.log("student data for admin:", studentdata);

            // also need to get all mentors
            const { data: mentordata, error: mentorerror } = await supabase
                .from("mentors")
                .select(`id, name, students(studentgroups(worksite: worksites (name))), hours, created_at`)
                .in(
                    "id",
                    studentdata.map((student) => student.mentor?.id).filter((val) => typeof val === "number")
                );

            if (mentorerror) console.log("error", mentorerror);
            else console.log("mentor data for admin:", mentordata);

            // first get all the surveys, just title and completed_by columns, and with survey.admin === email
            // then for each student we will do the amount of "completed_by" presences / amount of surveys of admin
            const { data: surveydata, error: surveyerror } = await supabase
                .from("surveys")
                .select("title, completed_by")
                .eq("admin", email);

            if (surveyerror) console.log("error", surveyerror);
            else console.log("survey data for admin:", surveydata);

            setStudents(
                [...studentdata, ...mentordata].map((val) => {
                    // for each student we will do the amount of "completed_by" presences / amount of surveys of admin
                    // console.log("val", val);
                    // console.log("keys of val", Object.keys(val))

                    // get all the surveys that the student has completed
                    const completedsurveys = surveydata.filter((survey) => survey.completed_by.includes(val.id));
                    // console.log("completedsurveys", completedsurveys)

                    // get all the surveys that the admin has
                    const allsurveys = surveydata;
                    // console.log("allsurveys", allsurveys)

                    // get the percentage of surveys completed
                    const surveypercent = completedsurveys.length / allsurveys.length;
                    // console.log("surveypercent", surveypercent)

                    // console.log("Joined:", val.created_at);
                    return {
                        ID: val.id,
                        Name: val.name || "N/A",
                        Role: Object.keys(val).includes("mentor") ? "Student" : "Mentor",
                        Mentor: Object.keys(val).includes("mentor") ? (val.mentor ? val.mentor.name : "N/A") : "N/A",
                        Email: val.email,
                        Worksite: Object.keys(val).includes("mentor")
                            ? val.studentgroups?.worksite?.name
                            : val.students[0].studentgroups?.worksite?.name,
                        Hours: val.hours,
                        "% of Surveys Completed": surveypercent,
                        "% of Modules Completed": Object.keys(val).includes("mentor")
                            ? val.linked_modules
                                ? val.linked_modules.filter((module) => module.completed).length /
                                  val.linked_modules.length
                                : "0"
                            : "N/A",
                        Joined: val.created_at,
                    };
                })
            );
        }
        getStudents();

        const channelA = supabase.channel(email);

        // Subscribe to the Channel
        channelA
            .on("broadcast", { event: "test" }, (response) => {
                console.log(response);
                setPassedPic(response.payload.message);
            })
            .subscribe();

        // Set the channel in state for potential use elsewhere in the component
        setChannel(channelA);

        if (data.length === 0) {
            let elements = document.querySelectorAll(".CircularProgressbar");
            elements.forEach((svg) => {
                svg.setAttribute("viewBox", "0 0 105 105");
            });

            const grapharea = document.getElementsByClassName("recharts-area")[0].firstChild;
            const textelement = document.createElementNS("http://www.w3.org/2000/svg", "text");
            textelement.setAttribute("font-family", "'Plus Jakarta Sans', sans-serif");
            textelement.setAttribute("font-size", "16");
            textelement.setAttribute("x", "50%");
            textelement.setAttribute("y", "50%");
            textelement.textContent = "No data present!";
            grapharea.appendChild(textelement);
        }

        // Clean up the channel when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
            if (channelA) {
                channelA.unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, [itemOffset]);

    const completiondata = [
        { title: "Attendance", sub: "Online", percent: 100 },
        { title: "Task Performance", sub: "Surveys", percent: 100 },
        { title: "Confidence", sub: "Surveys", percent: 100 },
        { title: "Career Advocacy", sub: "Surveys", percent: 100 },
    ];

    return (
        <div
            id="admindiv"
            className="flex w-full mt-8 items-center justify-around"
            style={{ marginTop: "20px", paddingInline: "4vw" }}
        >
            <div className="flex flex-row justify-between items-end gap-[3vw] under769:flex-col under769:">
                <img
                    src={Infographic}
                    alt="Infographic"
                    className="under769:w-full atleast769:max-w-[71%] atleast769:mb-[2vh]"
                    style={{ alignSelf: "center" }}
                />
                <div className="flex justify-between flex-col w-1/3 gap-y-4 under769:w-full">
                    <ProductGoal />
                    <CompletionProgress completiondata={completiondata} />
                </div>
            </div>
            <StudentList
                title={"Student List"}
                page={"AdminHome"}
                students={students}
                sidetabvisible={sidetabvisible}
            />
            {passedpic && (
                <div id="messagereceiver">
                    <img src={passedpic} alt="passedpic" />
                </div>
            )}
        </div>
    );
}
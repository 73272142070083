import { useContext, useEffect, useState } from 'react';
import '../assets/css/surveys.css';
import React from 'react';
import { supabase } from '../supabase';
import { toastError, toastPromise, toastSuccess } from '../GlobalFunctions';
import { GlobalStyles } from '../GlobalStyles';
import { FaTrash } from "react-icons/fa6";
import { AuthContext } from '../App';
import Popup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';

const Surveys = ({ sidetabvisible }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [surveys, setSurveys] = useState([]);
  const [newSurveyTitle, setNewSurveyTitle] = useState('');
  const [creatingSurvey, setCreatingSurvey] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [deletingSurvey, setDeletingSurvey] = useState(false);
  const [surveyId, setSurveyId] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  const handleAddQuestion = () => {
    setQuestions([...questions, { id: Date.now() }]);
  };

  // Fetch surveys from the database
  const fetchSurveys = async () => {
    const { data, error } = await supabase.from('surveys').select('*')
      .eq(user.role === "Administrator" ? 'admin' : 'instructor', user?.email);

    if (error) {
      console.log('Error fetching surveys:', error);
    } else {
      if (user.role === "Instructor") {
        // Fetch student groups for the instructor

        const { data: studentGroups, error: errorStudentGroups } = await supabase.from('studentgroups').select('*')
          .eq('instructor', user?.id);
        if (errorStudentGroups) {
          console.error("Error fetching student groups:", errorStudentGroups);
        } else {
          // Fetch surveys created by the admins of those student groups
          const { data: adminSurveys, error: errorAdminSurveys } = await supabase.from('surveys').select('*')
            .in('admin', studentGroups.map(sg => sg.admin));
          if (errorAdminSurveys) {
            console.error("Error fetching admin surveys:", errorAdminSurveys);
          } else {
            // Create a Set of existing survey IDs to ensure uniqueness
            const existingIds = new Set(data.map(survey => survey.id));
            // Filter out any adminSurveys that have already been added
            const uniqueAdminSurveys = adminSurveys.filter(survey => !existingIds.has(survey.id));

            // Fetch students in the instructor's group
            const { data: students, error: studentError } = await supabase.from('students').select('email')
              .in('group', studentGroups.map(sg => sg.uuid));
            if (studentError) {
              console.error("Error fetching students:", studentError);
            } else {
              // Filter surveys to include only those completed by students in the instructor's group
              const filteredSurveys = [...data, ...uniqueAdminSurveys].map(survey => {
                survey.completed_by = survey.completed_by.filter(email => students.some(student => student.email === email));
                return survey;
              });

              setSurveys(filteredSurveys);
            }
          }
        }
      } else {
        setSurveys(data);
      }
    }
  };

  // Load surveys on component mount
  useEffect(() => {
    fetchSurveys();
  }, []);

  const handleAddMultipleChoiceOption = (question) => () => {
    setQuestions(prevQuestions => prevQuestions.map(q => q.id === question.id ?
      { ...q, options: [...(q.options || []), { id: Date.now(), text: '' }] }
      : q
    ));
  }

  return (
    creatingSurvey ? (
      <div className='w-full px-[4vw] !justify-start !items-start gap-[2vh]'>
        <h1 className='text-2xl font-semibold self-start'>Survey Creator</h1>
        <input style={{ fontSize: 'calc(24px + 0.390625vw)', color: '#686868' }}
          type="text" 
          value={newSurveyTitle}
          onChange={(e) => setNewSurveyTitle(e.target.value)} 
          placeholder="Survey Title" className='mt-[1vh] font-bold'
          id='newsurveytitle'
        />
        {questions.length > 0 && (
          <ul className='w-full flex flex-col gap-[3vh]'>
            {questions.map((question, index) => (
              <li key={question.id} className='flex flex-col gap-[1vh]'>
                <div className='!flex-row'>
                  {index + 1}.&nbsp;

                  <input type="text" placeholder="Question text goes here." className='questiontext w-full' style={{ outline: 'none' }} onChange={(e) => {
                    setQuestions(prevQuestions => prevQuestions.map((q, i) => i === index ? { ...q, text: e.target.value } : q));
                  }} />
                  <FaTrash color='red' className='cursor-pointer' onClick={() => {
                    setQuestions(prevQuestions => prevQuestions.filter((q, i) => q.id !== question.id));
                  }} />
                </div>
                <input type="text" style={{ color: "#9ca3af" }} placeholder="Optional explainer text" className='w-full outline-none' onChange={(e) => {
                  setQuestions(prevQuestions => prevQuestions.map((q, i) => i === index ? { ...q, explainer: e.target.value } : q));
                }} />

                <div className='!items-start gap-[0.5vh] surveyselect' onChange={(e) => {
                  console.log("event:", e.target.value);
                  setQuestions(prevQuestions => prevQuestions.map((q, i) => i === index ? { ...q, type: e.target.value } : q));
                }}>
                  <label>
                    <input type="radio" value="multiplechoice" name={`questionType${index}`} />
                    Add Multiple Choice
                  </label>
                  {(question.type === "multiplechoice" || question.type === "mccheckbox") && (
                    <div className='ml-[1vw] !items-start gap-[0.5vh]'>

                      <label>
                        <input className='mr-[1vw]' type="checkbox" value="mccheckbox" name={`multipleChoice${index}`} />
                        Add Checkbox for Multiple Choice
                      </label>

                      {question.options && question.options.map((val, optionindex) => (
                        <div className='!flex-row' key={val.id}>
                          {optionindex + 1}.&nbsp;
                          <input type="text" placeholder="Option text goes here." className='questiontext w-full'
                            style={{ outline: 'none' }} onChange={(e) => {
                              e.stopPropagation();

                              setQuestions(prevQuestions => prevQuestions.map((q, i) => i === index ?
                                { ...q, options: q.options.map((o) => o.id === val.id ? { ...o, text: e.target.value } : o) } : q));
                            }}
                          />
                          <FaTrash color='red' className='cursor-pointer ml-[1vw]' onClick={() => {


                            setQuestions(prevQuestions => prevQuestions.map(q => q.id === question.id ?
                              { ...q, options: q.options.filter(o => o.id !== val.id) } : q))
                          }}
                          />

                        </div>
                      ))}
                      {(question.type === 'multiplechoice' || question.type === 'mccheckbox') && (
                        <button className=' !text-sm' onClick={handleAddMultipleChoiceOption(question)} style={{ backgroundColor: GlobalStyles.blue }}>
                          Add Multiple Choice Option
                        </button>
                      )}
                    </div>
                  )}
                  <label>
                    <input type="radio" value="freeresponse" name={`questionType${index}`} />
                    Add Free Response
                  </label>
                  <label>
                    <input type="radio" value="ratingscale" name={`questionType${index}`} />
                    Add Rating Scale
                  </label>
                </div>
              </li>
            ))}
          </ul>
        )}
        <button onClick={handleAddQuestion} style={{ backgroundColor: GlobalStyles.blue }}>Add Question</button>
        <button className='mb-[2vh]' onClick={async() => {
          toastPromise(new Promise(async (resolve, reject) => {
            const roleField = user.role === "Administrator" ? "admin" : "instructor";

            const { error } = await supabase.from('surveys')
              .insert({
                title: newSurveyTitle.trim(),
                data: questions,
                [roleField]: user?.email
              });

            if (error) {
              console.error("Error creating survey:", error);
              reject(error);
            } else {
              setCreatingSurvey(false);
              // Reload the surveys
              await fetchSurveys();
              resolve();
            }
          }), "Uploading survey...", "Survey created!")
        }}
          style={{ backgroundColor: "green" }}
        >
          Create Survey
        </button>
      </div>
    ) : (
      <div className='w-full gap-[2vh]'>
        <div className='!flex-row !justify-between w-full'>
          <p className='text-2xl font-semibold'>Surveys</p>
          <button className='self-end' style={{ backgroundColor: GlobalStyles.blue }}
            onClick={() => setCreatingSurvey(true)}
          >
            Create Survey
          </button>
        </div>

        <div className='self-start !items-start gap-[1vh]'>
          {surveys.map((survey) => (
            <div key={survey.id}>
              <div className='!flex-row gap-[1vw]'>
                <h2>{survey.title}</h2>
                {((user.role === "Administrator") || (user.role === "Instructor" && (survey.instructor === user.email))) && (
                  <button style={{ backgroundColor: '#7C8FAC' }}
                    onClick={() => navigate(`/editsurvey/${survey.id}`)}
                  >
                    Edit
                  </button>
                )}
                <button style={{ backgroundColor: '#5D87FF', color: 'white' }} onClick={() => navigate(`/surveytable/${survey.id}`)}>
                  Check Results  
                </button>
                {((user.role === "Administrator") || (user.role === "Instructor" && (survey.instructor === user.email))) && (
                  <button className='bg-red-600' onClick={() => {setDeletingSurvey(true); setSurveyId(survey.id)}}>Delete</button>
                )}
              </div>
            </div>
          ))}
        </div>
        <Popup open={deletingSurvey} closeOnDocumentClick onClose={() => setDeletingSurvey(false)}
          className='flex flex-col items-center justify-center'
          overlayStyle={{
            background: "rgba(0,0,0,0.5)",
            marginLeft: sidetabvisible ? "25vw" : 0,
            width: sidetabvisible ? "75vw" : "100vw",
          }}
        >
          <p className='mb-2 text-lg'>Are you sure you want to delete this survey?</p>
          <button onClick={async() => {
            toastPromise(new Promise(async(resolve, reject) => {
              const { error } = await supabase.from('surveys').delete().eq('id', surveyId);
              if (error) {
                reject(error);
              } else {
                setDeletingSurvey(false);
                setSurveyId(null);
                await fetchSurveys();
                resolve();
              }
            }), "Deleting survey...", "Survey deleted!")
          }} className='bg-red-600 my-2 text-[1rem] rounded-lg shadow-2xl shadow-cyan-500/50 !px-[1vw] !py-1 text-white'>
            Delete
          </button>
        </Popup>
      </div>
    )
  );
};

export default Surveys;
import { useContext, useState } from "react";
import { supabase } from "../../supabase";
import { baseUrl, getTableName, storePassword, toastError, toastSuccess } from "../../GlobalFunctions";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";

export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [buttonpressed, setButtonPressed] = useState(false);
    const navigate = useNavigate();
    const { user, setCurPath } = useContext(AuthContext);

    return (
        <div id="resetpassworddiv" className="">
            <h1 className="text-3xl font-bold">Reset Password</h1>
            <input
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                type="password"
                className="!w-auto"
            />
            <input
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                type="password"
                className="!w-auto"
            />
            <button
                className="actionbtn"
                onClick={async () => {
                    setButtonPressed(true);
                    if (newPassword === confirmPassword) {
                        const { error } = await supabase.auth.updateUser({ password: newPassword });
                        if (error) {
                            console.error("Error updating password:", error);
                            toastError(error.message);
                        } else {
                            // from here, we just need to update the "password" column of whatever table the user is in
                            const store_success = await storePassword(user.email, newPassword);
                            if (store_success) {
                                console.log("Password stored successfully");
                                toastSuccess("Password updated successfully!");
                                setCurPath("");
                                navigate("/");
                            } else {
                                console.error("Error storing password");
                                toastError("Error resetting password. Please try again later!");
                            }
                        }
                    } else {
                        toastError("Passwords do not match. Try again.");
                    }
                    setButtonPressed(false);
                }}
                disabled={buttonpressed}
            >
                Reset Password
            </button>
        </div>
    );
}

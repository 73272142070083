import { useLocation } from "react-router-dom";

export default function InsDailyReport() {
    const { state: { report, name } = {} } = useLocation() || {};

    console.log("report:", report, "\n\nname:", name);

    return (
        <div className="daily-report-container">
            <h1 className="text-left text-2xl self-start font-semibold">{name}'s Daily Report</h1>
            <h1 className="self-start text-lg font-medium">
                Date Submitted:{" "}
                {new Date(report.date).toLocaleTimeString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                })}
            </h1>
            <h1 className="self-start text-lg font-medium">
                Lesson Date:{" "}
                {new Date(report.dateOfLesson).toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                })}
            </h1>
            <form>
                <p>
                    {report.whatHappened && report.whatHappened !== ""
                        ? report.whatHappened
                        : "No response for what happened"}
                </p>
                <p>
                    {report.whatWentWell && report.whatWentWell !== ""
                        ? report.whatWentWell
                        : "No response for what went well"}
                </p>
                <p>
                    {report.whatDidntGoWell && report.whatDidntGoWell !== ""
                        ? report.whatDidntGoWell
                        : "No response for what did not go well"}
                </p>
                <p>
                    {report.feedbackToAdmins && report.feedbackToAdmins !== ""
                        ? report.feedbackToAdmins
                        : "No feedback to admins"}
                </p>
            </form>
        </div>
    );
}

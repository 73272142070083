import { useContext, useEffect } from "react";
import { AuthContext } from "../../App";

export default function Logout() {
    const { logout } = useContext(AuthContext);
    console.log("on logout page");
    useEffect(() => {
        (async function logout_user() {
            console.log("logging out");
            await logout();
        })();
    }, []);
}

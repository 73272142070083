import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../App.js';
import { uploadFileToBackblaze, directoryExistsInBackblaze } from '../../GlobalFunctions.js';
import { GlobalStyles } from "../../GlobalStyles.js";

export default function StudentUploadCard() {
    const { user } = useContext(AuthContext);

    const studentName = user?.name.toLowerCase().replace(/\s+/g, '_');
    const student = studentName + "_" + user?.email;
    const folderType = "student_uploads"; 
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const getCurrentTimestamp = () => {
        const now = new Date();
        return now.toISOString().replace(/[:.]/g, '-'); // Format the timestamp
    };

    useEffect(() => {
        const checkDirectoryAndSetStatus = async () => {
            const timestamp = getCurrentTimestamp();
            const folderPath = `${folderType}/${student}/${timestamp}/`;
            try {
                const exists = await directoryExistsInBackblaze(folderPath);
                if (exists) {
                    console.log('Directory exists:', folderPath);
                }
            } catch (error) {
                console.error('Error checking directory existence:', error);
            }
        };

        checkDirectoryAndSetStatus();
    }, [user, folderType, student, selectedFile]);

    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadStatus('No file selected.');
            return;
        }
        const timestamp = getCurrentTimestamp();
        const fileName = folderType + "/" + student + "/" + timestamp;
        console.log(fileName);
        try {
            const uploadResult = await uploadFileToBackblaze(selectedFile, fileName, folderType);
            if (uploadResult) {
                console.log('File uploaded successfully:', uploadResult);
                setUploadStatus('File uploaded successfully.');
            } else {
                setUploadStatus('Upload failed.');
            }
        } catch (error) {
            setUploadStatus('Upload failed: ' + error.message);
        }
    };

    return (
        <div className="rounded-2xl p-[2vh] w-full flex flex-col justify-center items-center" style={{ backgroundColor: GlobalStyles.lightblue }}>
            <div style={{ display: "flex", gap: "20px" }}>
                <input type="file" className='w-full' onChange={(e) => setSelectedFile(e.target.files[0])} />
                <button
                    style={{
                        backgroundColor: GlobalStyles.lightergray,
                        color: 'black',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={handleUpload}
                >
                    Upload File
                </button>
            </div>
            {uploadStatus && (
                <div style={{
                    marginTop: '10px',
                    padding: '10px',
                    borderRadius: '5px',
                    backgroundColor: uploadStatus.startsWith('File uploaded') ? '#4CAF50' : '#F44336',
                    color: 'white',
                    width: '100%',
                    textAlign: 'center',
                }}>
                    {uploadStatus}
                </div>
            )}
        </div>
    );
}

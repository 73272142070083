import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App.js";
import { useNavigate, useLocation } from "react-router-dom";
import "../assets/css/signupscreen.css";
import { supabase } from "../supabase.js";
import { toastError, toastSuccess } from "../GlobalFunctions.js";

export default function LoginScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const { login } = useContext(AuthContext);
  const { from } = location.state || { from: { pathname: "/" } };

  return (
    <div
      id=""
      className="authdiv flex flex-col items-center justify-center authbox logindiv"
    >
      <img
        src={require("../icons/acewise.png")}
        alt="Acewise"
        width={150}
        height={150}
      />
      <p id="createacc">Login</p>
      <div className="authform">
        <input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="email-address"
          autoCapitalize="none"
          autoCorrect="false"
          autoComplete="false"
        />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="actionbtn"
          onClick={async () => await login(email, password)}
        >
          Log In
        </button>
      </div>
      <button
        className="actionbtn"
        onClick={async () => {
          if (!email) {
            toastError("Please enter your email.", { theme: "colored" });
            return;
          }
          if (email.includes("@")) {
            const { data, error } = await supabase.auth.resetPasswordForEmail(
              email,
              {
                redirectTo:
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000/resetpassword"
                    : "https://platform.acewise.app/resetpassword",
              }
            );
            if (data) {
              toastSuccess(
                "Your password reset has been sent to your email. Make sure to check your spam folder.",
                {
                  theme: "colored",
                }
              );
            } else {
              console.error("Error sending password reset:", error);
              toastError(error.message, { theme: "colored" });
            }
          } else {
            toastError("Please enter a valid email.", { theme: "colored" });
          }
        }}
      >
        Forgot Password?
      </button>
      <p className="mt-4 font-small flex text-center">Don't have an account?</p>
      <button
        onClick={() => navigate("/signup")}
        id="signinbutton"
        className="actionbtn"
      >
        Sign Up
      </button>
    </div>
  );
}
